import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// eslint-disable-next-line react/display-name
const Container = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { className, children, asElement: Component, ...rest } = props

  return (
    <Component
      ref={ref}
      className={classNames('container mx-auto', className)}
      {...rest}
    >
      {children}
    </Component>
  )
})

Container.defaultProps = {
  asElement: 'div'
}

Container.propTypes = {
  asElement: PropTypes.string
}

export default Container
