export const entityAdapter = (data) => {
  return data.map((el) => ({
    value: el.id,
    label: el.name,
    key: el.key,
    type: el.type.key,
    portfolios: el.portfolio.map((element) => ({
      value: element.id,
      label: element.name,
      NIT: element.NIT,
      LEI: element.LEI
    }))
  }))
}
