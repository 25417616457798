import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  onAddCollateral,
  onAddExistingCollateral,
  onFinishSendRequest,
  onGetCollateralTypes,
  onRemoveCollateral,
  onResetCollaterals,
  onSendRequest
} from 'app/features/collateral.slice'
import { formatToCurrency } from 'helpers/formatToCurrency'
import { parseCurrency } from 'helpers/parseCurrency'
import { percentToNumber } from 'helpers/percentToNumber'
import { isinAdapter } from 'adapters'
import { custodianAssignGuarantees } from 'services/custodian.service'
import { createNewISIN } from 'services/globals.service'

export const useAssignCollateral = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organization, organizationKey } = user
  const { assign } = useSelector((state) => state.collateral)
  const { selectedEntity, selectedPortfolio, selectedCollateral, amount, haircut, collaterals } = assign
  const dispatch = useDispatch()

  const [isinCardOpen, setIsinCardOpen] = useState(false)

  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')
  const [assignLoading, setAssignLoading] = useState(false)

  const addNewISIN = async (obj) => {
    setAssignLoading(true)
    try {
      const dataToSend = {
        key: obj.isin,
        divisible: obj.currency.divisible
      }
      const { data, status } = await createNewISIN(dataToSend)
      if (status === 200) {
        const formattedISIN = isinAdapter(data.response)
        openDialog(data.message)
        dispatch(onGetCollateralTypes(formattedISIN))
        setAssignLoading(false)
      }
    } catch (err) {
      openDialog(err.response.data.message)
      setAssignLoading(false)
    }
  }

  const addNewCollateral = () => {
    const existingCollateral = collaterals.find((el) => el.guarante_type_key === selectedCollateral.key)
    if (!existingCollateral) {
      const newAmount = amount.replaceAll(',', '')
      const collateral = (Number(newAmount) * haircut) / 100
      const formattedAmount = formatToCurrency(newAmount, 'es-CO', selectedCollateral === 'USD' ? 'USD' : 'COP')
      const formattedCollateral = formatToCurrency(collateral, 'es-CO', selectedCollateral === 'USD' ? 'USD' : 'COP')
      const collateralToSave = {
        guarante_type_key: selectedCollateral.key,
        guarante_type_value: selectedCollateral.key,
        guarante_type_label: selectedCollateral.label,
        amount: formattedAmount,
        haircut: `${haircut} %`,
        collateral: formattedCollateral,
        transactionType: 'Cash In',
        valuationAmount: amount,
        divisibility_label: selectedCollateral.label,
        divisibility_value: selectedCollateral.divisible ? 'true' : 'false'
      }
      dispatch(onAddCollateral(collateralToSave))
      openDialog('ISIN asignado correctamente.')
    } else {
      const newAmount = amount.replaceAll(',', '')
      const totalAmount = parseCurrency(existingCollateral.amount) + Number(newAmount)
      const totalCollateral = (totalAmount * haircut) / 100
      const formattedTotalAmount = formatToCurrency(totalAmount, 'es-CO', selectedCollateral.key === 'USD' ? 'USD' : 'COP')
      const formattedTotalCollateral = formatToCurrency(totalCollateral, 'es-CO', selectedCollateral.key === 'USD' ? 'USD' : 'COP')
      const collateralToUpdate = {
        guarante_type_key: selectedCollateral.key,
        guarante_type_value: selectedCollateral.key,
        guarante_type_label: selectedCollateral.label,
        amount: formattedTotalAmount,
        haircut: `${haircut} %`,
        collateral: formattedTotalCollateral,
        transactionType: 'Cash In',
        valuationAmount: amount,
        divisibility_label: selectedCollateral.label,
        divisibility_value: selectedCollateral.divisible ? 'true' : 'false'
      }
      dispatch(onAddExistingCollateral({ key: selectedCollateral.key, data: collateralToUpdate }))
      openDialog('ISIN asignado correctamente.')
    }
  }

  const onDropSelectedCollaterals = (e, selectedFlatRows) => {
    const currentCollaterals = [...collaterals]
    for (let index = selectedFlatRows.length - 1; index >= 0; index--) {
      currentCollaterals.splice(selectedFlatRows[index].index, 1)
    }
    dispatch(onRemoveCollateral(currentCollaterals))
  }

  const assignCollaterals = async (e) => {
    dispatch(onSendRequest())
    const collateralsToSend = collaterals.map((item) => {
      const formattedHaircut = percentToNumber(item.haircut)
      const formattedAmount = parseCurrency(item.amount)
      const collateral = (Number(formattedAmount) * Number(formattedHaircut)) / 100
      return {
        guaranteeType: item.guarante_type_key,
        guaranteeName: item.guarante_type_label,
        amount: formattedAmount,
        haircut: formattedHaircut,
        collateral,
        transactionType: 'Cash In',
        valuationAmount: formattedAmount,
        divisible: item.divisibility_value
      }
    })
    const dataToSend = {
      custodianId: organizationKey,
      custodianName: organization,
      entityId: selectedEntity.key,
      entityName: selectedEntity.label,
      entityType: selectedEntity.type,
      portfolioNIT: selectedPortfolio.NIT,
      portfolioLEI: selectedPortfolio.LEI,
      portfolioName: selectedPortfolio.label,
      guarantees: collateralsToSend,
      faceValue: collateralsToSend.reduce((acc, el) => acc + Number(el.amount), 0),
      collateralReceived: collateralsToSend.reduce((acc, el) => acc + Number(el.collateral), 0),
      date: new Date()
    }
    const { status, data: response } = await custodianAssignGuarantees(dataToSend, selectedPortfolio.NIT)
    if (status === 200) {
      openDialog(response.message)
      dispatch(onResetCollaterals())
    } else {
      openDialog('Error al realizar la operación')
    }
    dispatch(onFinishSendRequest())
  }

  const openDialog = (message) => {
    setDialogMessage(message)
    setDialogIsOpen(true)
  }
  const onDialogOk = () => {
    setDialogIsOpen(false)
    setIsinCardOpen(!isinCardOpen)
  }

  return {
    assignLoading,
    setIsinCardOpen,
    isinCardOpen,
    addNewISIN,
    addNewCollateral,
    onDropSelectedCollaterals,
    assignCollaterals,
    dialogMessage,
    openDialog,
    onDialogOk,
    dialogIsOpen
  }
}
