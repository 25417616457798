import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Input, Select } from 'components/ui'
import { onOpenModal } from 'app/features/ui.slice'
import {
  onChangeUserEmail,
  onChangeUserName,
  onChangeUserRole,
  onChangeUserStatus,
  onGetUsers,
  onResetSelectedUser
} from 'app/features/organizationUsers.slice'
import { updateUserService } from 'services/organization-service'
import { updateAuthUserService } from 'services/colibri.service'

export const SelectedUserContainer = () => {
  const { users, organization } = useSelector((state) => state.organizationUsers)
  const { modulePermissions } = useSelector((state) => state.permission)
  const { roles, status, selected } = users
  const { selectedOrganization } = organization
  const dispatch = useDispatch()

  const handleUpdateUser = async () => {
    try {
      const authCredentials = { name: selected.name, email: selected.email, provider_id: 1, copy_email: selected.user.email }
      const { status } = await updateAuthUserService(authCredentials)
      if (status === 200) {
        const dataToUpdate = {
          organizationKey: selectedOrganization.key,
          id: selected.user.id,
          name: selected.name,
          email: selected.email,
          statusId: selected.status.value,
          roleId: selected.role.value
        }
        const { data } = await updateUserService(dataToUpdate)
        dispatch(onOpenModal({ message: data.message ?? 'Usuario actualizado correctamente', type: 'SUCCESS' }))
        dispatch(onGetUsers(data.response.user))
        dispatch(onResetSelectedUser())
      }
    } catch (err) {
      console.log(err)
      dispatch(onOpenModal({ message: err?.response.data.message, type: 'ERROR' }))
    }
  }

  return (
    <Card className="mt-8">
      <div className="mb-4">
        <h5 className="mb-2">Correo electrónico</h5>
        <Input
          placeholder="LEI"
          type="text"
          disabled={!modulePermissions.hasPutPermission}
          value={selected.email}
          onChange={({ target }) => dispatch(onChangeUserEmail(target.value))}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Nombre</h5>
        <Input
          placeholder="nombre completo"
          type="text"
          disabled={!modulePermissions.hasPutPermission}
          value={selected.name}
          onChange={({ target }) => dispatch(onChangeUserName(target.value))}
        />
      </div>

      <div className="mb-4">
        <h5 className="mb-2">Rol</h5>
        <Select
          placeholder="Seleccione rol de usuario"
          options={roles}
          isDisabled={!modulePermissions.hasPutPermission}
          value={selected.role}
          onChange={(value) => dispatch(onChangeUserRole(value.key))}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Estado</h5>
        <Select
          placeholder="Seleccione estado de usuario"
          options={status}
          isDisabled={!modulePermissions.hasPutPermission}
          value={selected.status}
          onChange={(value) => dispatch(onChangeUserStatus(value.key))}
        />
      </div>
      <Button
        variant="solid"
        onClick={() => handleUpdateUser()}
        disabled={!modulePermissions.hasPutPermission}
      >
        Actualizar usuario
      </Button>
    </Card>
  )
}
