import { useDispatch, useSelector } from 'react-redux'
import {
  onFinishSendRequest,
  onGetGuaranteeEntitiesData,
  onSelectGuaranteeEntity,
  onSelectGuaranteePortfolio,
  onSendRequest
} from 'app/features/custodianSlice'
import { parseCurrency } from 'helpers/parseCurrency'
import { custodianWithdrawnGuarantees } from 'services/custodian.service'
import { formatGuaranteedEntities } from 'adapters/guaranteeEntities'
import { useState } from 'react'

export const useWithdrawnGuarantee = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationKey, organizationName } = user
  const { guarantees } = useSelector((state) => state.custodian)
  const { selectEntity, selectPortfolio, contracts } = guarantees
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  const dispatch = useDispatch()

  const startWithdrawnGuarantees = async () => {
    const guaranteesByGuaranteeParentId = selectPortfolio.guarantees.reduce((acc, el) => {
      const guaranteeParentId = el.guaranteeParentId
      if (!acc[guaranteeParentId]) {
        acc[guaranteeParentId] = []
      }
      acc[guaranteeParentId].push(el)
      return acc
    }, {})
    const guarantees = Object.values(guaranteesByGuaranteeParentId)
    guarantees.forEach(async (element, i) => {
      const elementArr = element.map((el) => {
        const haircut = parseCurrency(el.haircut)
        const amount = parseCurrency(el.amount)
        let withdrawCollateral = 0
        if (el.withdrawCollateral === '') {
          withdrawCollateral = 0
        } else {
          withdrawCollateral = el.withdrawCollateral.replaceAll(',', '')
        }
        const newAmount = amount - Number(withdrawCollateral)
        const newCollateral = newAmount * (Number(haircut) / 100)
        const formattedGuaranteeData = {
          guaranteeType: el.guaranteeType,
          guaranteeTypeId: el.key,
          guaranteeName: el.guaranteeName,
          amount: String(parseCurrency(newAmount.toFixed(0))),
          haircut: String(haircut),
          collateral: String(parseCurrency(newCollateral.toFixed(0))),
          initDate: el.initDate,
          transactionType: Number(withdrawCollateral) === 0 ? 'Stable' : 'Cash Out',
          valuationAmount: `-${String(el.withdrawCollateral)}`,
          updateDate: new Date(),
          guaranteeParentId: el.guaranteeParentId,
          collateralReceived: el.collateralReceived,
          collateralDelivered: el.collateralDelivered
        }
        return formattedGuaranteeData
      })
      const totalFaceValue = elementArr.reduce((acc, el) => {
        return acc + Number(el.amount)
      }, 0)
      const totalCollateralReceived = elementArr.reduce((acc, el) => {
        return acc + Number(el.collateral)
      }, 0)
      const dataToSave = {
        guaranteeId: elementArr[0].guaranteeParentId,
        custodianId: organizationKey,
        custodianName: organizationName,
        entityId: selectEntity.key,
        entityName: selectEntity.label,
        entityType: selectEntity.type,
        portfolioNIT: selectPortfolio.NIT,
        portfolioLEI: selectPortfolio.LEI,
        portfolioName: selectPortfolio.label,
        guarantees: [...elementArr],
        faceValue: String(totalFaceValue),
        collateralReceived: String(totalCollateralReceived),
        date: new Date()
      }
      dispatch(onSendRequest())
      const { data } = await custodianWithdrawnGuarantees(dataToSave)
      dispatch(onFinishSendRequest())
      const formattedEntities = formatGuaranteedEntities(data.response, contracts)
      dispatch(onGetGuaranteeEntitiesData(formattedEntities))
      dispatch(onSelectGuaranteeEntity(selectEntity.key))
      dispatch(onSelectGuaranteePortfolio(selectPortfolio.NIT))
      openDialog(data.message)
    })
  }
  const openDialog = (message) => {
    setDialogMessage(message)
    setDialogIsOpen(true)
  }
  const onDialogOk = () => {
    setDialogIsOpen(false)
  }
  return { startWithdrawnGuarantees, dialogMessage, openDialog, onDialogOk, dialogIsOpen }
}
