import { createAction, createSlice, current } from '@reduxjs/toolkit'
import { formatToCurrency } from 'helpers/formatToCurrency'
import { parseCurrency } from 'helpers/parseCurrency'

const initialState = {
  assignGuarantees: {
    loading: true,
    entities: [],
    portfolios: [],
    guaranteeTypes: [],
    selectedEntity: null,
    selectedPortfolio: null,
    selectedGuarantee: null,
    collateralValue: null,
    haircut: 100,
    amount: null,
    guarantees: [],
    totalFacialValue: null,
    totalCollateralConstituted: null
  },
  guarantees: {
    loading: true,
    entities: [],
    portfolios: [],
    collaterals: [],
    contracts: [],
    selectEntity: null,
    selectPortfolio: null,
    totalCollateralReturned: 0,
    totalCollateralWithdrawn: 0,
    loadingRequest: false
  }
}

export const revertAll = createAction('REVERT_ALL')

export const custodianSlice = createSlice({
  name: 'custodian',
  initialState,
  reducers: {
    // <---- AssignGuarantee View ---->

    onStartLoadAssignGuarantees: (state) => {
      state.assignGuarantees.loading = true
    },
    onGetAssignGuarantees: (state, { payload }) => {
      state.assignGuarantees.entities = payload.entities
      state.assignGuarantees.guaranteeTypes = payload.guaranteeTypes
    },
    onFinishLoadAssignGuarantees: (state) => {
      state.assignGuarantees.loading = false
    },
    onResetAssignGuarantees: (state) => {
      state.assignGuarantees.guarantees = initialState.assignGuarantees.guarantees
      state.assignGuarantees.totalFacialValue = initialState.assignGuarantees.totalFacialValue
      state.assignGuarantees.totalCollateralConstituted = initialState.assignGuarantees.totalCollateralConstituted
    },
    onSelectAssignedGuaranteeEntity: (state, { payload }) => {
      const entities = [...current(state.assignGuarantees.entities)]
      const entity = entities.find((el) => el.key === payload)
      state.assignGuarantees.selectedEntity = entity
    },
    onGetAssignedGuaranteePortfolios: (state, { payload }) => {
      state.assignGuarantees.portfolios = payload
    },
    onSelectAssignedGuaranteePortfolio: (state, { payload }) => {
      const portfolios = [...current(state.assignGuarantees.portfolios)]
      const portfolio = portfolios.find((el) => el.NIT === payload)
      state.assignGuarantees.selectedPortfolio = portfolio
    },
    onResetAssignedGuaranteePortfolio: (state) => {
      state.assignGuarantees.selectedPortfolio = null
    },
    onSelectGuarantee: (state, { payload }) => {
      const guarantees = [...current(state.assignGuarantees.guaranteeTypes)]
      const guarantee = guarantees.find((el) => el.key === payload)
      state.assignGuarantees.selectedGuarantee = guarantee
    },
    onChangeCollateral: (state, { payload }) => {
      state.assignGuarantees.collateralValue = payload
    },
    onChangeHaircut: (state, { payload }) => {
      state.assignGuarantees.haircut = Number(payload)
    },
    onChangeAmount: (state, { payload }) => {
      state.assignGuarantees.amount = Number(payload)
    },
    onAddGuarantee: (state, { payload }) => {
      state.assignGuarantees.guarantees.push(payload)
    },
    onRemoveGuaranteeFromList: (state, { payload }) => {
      state.assignGuarantees.guarantees = payload
      state.assignGuarantees.totalCollateralConstituted = null
      state.assignGuarantees.totalFacialValue = null
    },

    onCalculateTotalAssignedCollateral: (state) => {
      const guarantees = [...current(state.assignGuarantees.guarantees)]
      const facialValue = guarantees.reduce((acc, el) => acc + parseCurrency(el.amount), 0)
      const collateralConstituted = guarantees.reduce((acc, el) => acc + parseCurrency(el.collateral), 0)
      state.assignGuarantees.totalFacialValue = formatToCurrency(facialValue, 'es-CO', 'COP')
      state.assignGuarantees.totalCollateralConstituted = formatToCurrency(collateralConstituted, 'es-CO', 'COP')
    },

    onSendRequest: (state) => {
      state.guarantees.loadingRequest = true
    },
    onFinishSendRequest: (state) => {
      state.guarantees.loadingRequest = false
    },

    // <---- withdrawnGuarantees View ---->

    onStartGuaranteesLoading: (state) => {
      state.guarantees.loading = true
    },
    onFinishGuaranteesLoading: (state) => {
      state.guarantees.loading = false
    },
    onGetGuaranteeEntitiesData: (state, { payload }) => {
      state.guarantees.entities = payload
    },
    onSelectGuaranteeEntity: (state, { payload }) => {
      const entities = [...current(state.guarantees.entities)]
      const entity = entities.find((el) => el.key === payload)
      state.guarantees.selectEntity = entity
      state.guarantees.selectPortfolio = initialState.guarantees.selectPortfolio
    },
    onSelectGuaranteePortfolio: (state, { payload }) => {
      const entityPortfolios = [...current(state.guarantees.selectEntity.portfolios)]
      const portfolio = entityPortfolios.find((el) => el.NIT === payload)
      state.guarantees.selectPortfolio = portfolio
      state.guarantees.collaterals = portfolio.guarantees
    },

    onSetGuarantees: (state, { payload }) => {
      state.guarantees.collaterals = payload
    },

    onChangeWithdrawnCollateral: (state, { payload }) => {
      const guarantees = [...current(state.guarantees.selectPortfolio.guarantees)]
      const currentGuarantee = guarantees.find((el) => el.key === payload.key)
      const newCurrentGuarantee = { ...currentGuarantee, withdrawCollateral: payload.withdrawCollateral }
      state.guarantees.selectPortfolio.guarantees[payload.row] = newCurrentGuarantee
    },

    onCalculateTotalCollateral: (state) => {
      const guarantees = [...current(state.guarantees.selectPortfolio.guarantees)]
      const collateralReturned = guarantees.reduce((acc, el) => acc + parseCurrency(el.amount), 0)
      const collateralWithdrawn = guarantees.reduce((acc, el) => acc + parseCurrency(el.collateral), 0)
      state.guarantees.totalCollateralReturned = formatToCurrency(collateralReturned, 'es-CO', 'COP')
      state.guarantees.totalCollateralWithdrawn = formatToCurrency(collateralWithdrawn, 'es-CO', 'COP')
    },

    onGetContracts: (state, { payload }) => {
      state.guarantees.contracts = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onStartLoadAssignGuarantees,
  onGetAssignGuarantees,
  onFinishLoadAssignGuarantees,
  onResetAssignGuarantees,
  onSelectAssignedGuaranteeEntity,
  onGetAssignedGuaranteePortfolios,
  onSelectAssignedGuaranteePortfolio,
  onSelectGuarantee,
  onChangeCollateral,
  onChangeAmount,
  onChangeHaircut,
  onAddGuarantee,
  onResetAssignedGuaranteePortfolio,
  onRemoveGuaranteeFromList,
  onCalculateTotalAssignedCollateral,
  onSendRequest,
  onFinishSendRequest,
  onSetGuarantees,

  onStartGuaranteesLoading,
  onFinishGuaranteesLoading,
  onGetGuaranteeEntitiesData,
  onSelectGuaranteeEntity,
  onSelectGuaranteePortfolio,
  onChangeWithdrawnCollateral,
  onCalculateTotalCollateral,
  onGetContracts
} = custodianSlice.actions
