import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { InputFile } from 'components/v2'
import { Card, Select } from 'components/ui'
import { sendExcelContractValuations, sendExcelGuaranteeValuations } from 'services/valuator.service'
import { getAllValuators } from 'services/colibri.service'
import { useAssignValuator } from 'features/colibri/hook/useAssignValuator'
import { valuatorAdapter } from 'adapters'
import { PrivateLayout } from 'layout/private'

const LoadExcelFilesView = () => {
  const { setContractValuatorList, setGuaranteeValuatorList, setSelectedContractValuator, setSelectedGuaranteeValuator } = useAssignValuator()
  const valuator = useSelector((state) => state.valuator)
  const { guarantee, contract } = valuator
  const { user } = useSelector((state) => state.authorization)
  const { organizationType } = user

  useEffect(() => {
    const getValuators = async () => {
      const { data, status } = await getAllValuators()
      const valuators = valuatorAdapter(data)
      if (status === 200) {
        setGuaranteeValuatorList(valuators)
        setContractValuatorList(JSON.parse(JSON.stringify(valuators)))
      }
    }
    if (organizationType === 'colibri') {
      getValuators()
    }
  }, [])

  return (
    <PrivateLayout title="Cargar archivos">
      <div className="grid grid-cols-2 gap-8">
        <Card>
          <h5 className="mb-4">Archivo de valorización de derivados</h5>
          {organizationType === 'colibri' && (
            <Select
              className="mb-4"
              placeholder="Seleccione el valorador"
              options={contract.valuatorList}
              onChange={(e) => setSelectedContractValuator(e)}
              value={contract.selectedValuator}
            />
          )}
          <InputFile
            label="contract_valuation"
            serviceFunc={sendExcelContractValuations}
            valuator={contract.selectedValuator}
          />
        </Card>
        <Card>
          <h5 className="mb-4">Archivo de valorización de colaterales</h5>
          {user.organizationType === 'colibri' && (
            <Select
              className="mb-4"
              placeholder="Seleccione el valorador"
              options={guarantee.valuatorList}
              onChange={(e) => setSelectedGuaranteeValuator(e)}
              value={guarantee.selectedValuator}
            />
          )}
          <InputFile
            label="guarantee_valuation"
            serviceFunc={sendExcelGuaranteeValuations}
            valuator={guarantee.selectedValuator}
          />
        </Card>
      </div>
    </PrivateLayout>
  )
}

export default LoadExcelFilesView
