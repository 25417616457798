import {
  HiOutlineColorSwatch,
  HiOutlineDesktopComputer,
  HiOutlineTemplate,
  HiOutlineViewGridAdd,
  HiOutlineHome,
  HiOutlinePresentationChartLine,
  HiUserGroup,
  HiOutlineSave,
  HiAdjustments,
  HiUpload,
  HiOutlineCash,
  HiOutlinePlusCircle,
  HiOutlineDuplicate,
  HiClipboardList
} from 'react-icons/hi'
import { TbCoins } from 'react-icons/tb'
import { SlPlus } from 'react-icons/sl'
import { FaUserCog } from 'react-icons/fa'
import { RiSoundModuleFill } from 'react-icons/ri'

const navigationIcon = {
  home: <HiOutlineHome />,
  singleMenu: <HiOutlineViewGridAdd />,
  collapseMenu: <HiOutlineTemplate />,
  groupSingleMenu: <HiOutlineDesktopComputer />,
  groupCollapseMenu: <HiOutlineColorSwatch />,
  movements: <HiOutlinePresentationChartLine />,
  contracts: <HiUserGroup />,
  cashOut: <HiOutlineSave />,
  valuations: <HiAdjustments />,
  upload: <HiUpload />,
  cash: <HiOutlineCash />,
  netting: <TbCoins />,
  create: <SlPlus />,
  organization: <HiUserGroup />,
  add: <HiOutlinePlusCircle />,
  collateral: <HiOutlineDuplicate />,
  logs: <HiClipboardList />,
  permission: <FaUserCog />,
  modules: <RiSoundModuleFill />
}

export default navigationIcon
