import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  modal: {
    open: false,
    message: null,
    type: null
  },
  modules: {
    data: [],
    selectedModule: null,
    loading: true
  },

  dashboard: {
    portfolios: [],
    contracts: [],
    selectedPortfolio: null,
    collaterals: {
      loading: false,
      portfolios: [],
      contracts: [],
      selectedPortfolio: null,
      eligibleCollateral: '$ 0,00',
      totalCollaterals: '$ 0,00',
      blockedCollateral: '$ 0,00',
      availableCollateral: '$ 0,00',
      receivedCollateral: '$ 0,00',
      deliveredCollateral: '$ 0,00',
      marginCall: false
    },
    valuations: {
      loading: false,
      delivered: [],
      received: [],
      totalDelivered: 0,
      totalReceived: 0,
      marginalCall: 0,
      totalAmount: 0,
      initDate: new Date(),
      endDate: new Date()
    }
  }
}

export const revertAll = createAction('REVERT_ALL')

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // ---- MODAL ---- //
    onOpenModal: (state, { payload }) => {
      state.modal.open = true
      state.modal.message = payload.message
      state.modal.type = payload.type
    },
    onCloseModal: (state) => {
      state.modal.open = false
      state.modal.message = initialState.modal.message
      state.modal.type = initialState.modal.type
    },

    // ---- MODULES ---- //
    onGetModules: (state, { payload }) => {
      state.modules.data = payload
      state.modules.loading = false
    },
    onHandleSelectedModule: (state, { payload }) => {
      state.modules.selectedModule = payload
      const modules = [...current(state.modules.data)]
      const selectedModule = modules.find((el) => el.parent.id === payload)
      state.modules.selectedModule = selectedModule
    },
    onHandleResetModule: (state, { payload }) => {
      state.modules.selectedModule = initialState.modules.selectedModule
    },

    // ---- COLLATERALS ---- //
    onStartCollateralsLoading: (state) => {
      state.dashboard.collaterals.loading = true
    },
    onFinishCollateralsLoading: (state) => {
      state.dashboard.collaterals.loading = false
    },
    onGetPortfolios: (state, { payload }) => {
      state.dashboard.portfolios = payload
    },
    onGetContracts: (state, { payload }) => {
      state.dashboard.contracts = payload
    },
    onChangeSelectedPortfolio: (state, { payload }) => {
      const portfolios = [...current(state.dashboard.portfolios)]
      const portfolio = portfolios.find((el) => el.NIT === payload)
      state.dashboard.selectedPortfolio = portfolio
    },
    onChangeCollateralsData: (state, { payload }) => {
      state.dashboard.collaterals.eligibleCollateral = payload.eligibleCollateral
      state.dashboard.collaterals.totalCollaterals = payload.totalCollaterals
      state.dashboard.collaterals.blockedCollateral = payload.blockedCollateral
      state.dashboard.collaterals.availableCollateral = payload.availableCollateral
      state.dashboard.collaterals.receivedCollateral = payload.receivedCollateral
      state.dashboard.collaterals.deliveredCollateral = payload.deliveredCollateral
      state.dashboard.collaterals.marginCall = payload.marginCall
    },

    // ---- VALUATIONS ---- //
    onStartValuationsLoading: (state) => {
      state.dashboard.valuations.loading = true
    },
    onFinishValuationsLoading: (state) => {
      state.dashboard.valuations.loading = false
    },
    onGetValuations: (state, { payload }) => {
      state.dashboard.valuations.delivered = payload.deliveredCollateral
      state.dashboard.valuations.received = payload.receivedCollateral
      state.dashboard.valuations.totalDelivered = payload.totalDeliveredCollateral
      state.dashboard.valuations.totalReceived = payload.totalReceivedCollateral
      state.dashboard.valuations.totalAmount = payload.totalCollateralsAmount
      state.dashboard.valuations.marginalCall = payload.totalReceivedCollateral - payload.totalDeliveredCollateral
    },
    onChangeInitValuationDate: (state, { payload }) => {
      state.dashboard.valuations.initDate = payload
    },
    onChangeEndValuationDate: (state, { payload }) => {
      state.dashboard.valuations.endDate = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  // Modal
  onOpenModal,
  onCloseModal,

  // Modules
  onGetModules,
  onHandleSelectedModule,
  onHandleResetModule,

  // ---- COLLATERALS ---- //
  onStartCollateralsLoading,
  onFinishCollateralsLoading,
  onGetPortfolios,
  onGetContracts,
  onChangeSelectedPortfolio,
  onChangeCollateralsData,

  // ---- VALUATIONS ---- //
  onStartValuationsLoading,
  onFinishValuationsLoading,
  onGetValuations,
  onChangeInitValuationDate,
  onChangeEndValuationDate
} = uiSlice.actions
