import classNames from 'classnames'

// eslint-disable-next-line react/display-name
const withHeaderItem = (Component) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { className, hoverable = true } = props

  return (
    <Component
      {...props}
      className={classNames('header-action-item', hoverable && 'header-action-item-hoverable', className)}
    />
  )
}

export default withHeaderItem
