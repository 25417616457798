import { formatToCurrency } from 'helpers/formatToCurrency'

export const formatGuaranteedEntities = (data, contracts) => {
  const formattedEntities = data.map((item) => {
    return {
      value: item.entityKey,
      key: item.entityKey,
      label: item.entityName,
      type: item.entityType,
      portfolios: item.portfolios.map((item, index) => ({
        value: index,
        label: item.portfolioName,
        NIT: item.portfolioNIT,
        LEI: item.portfolioLEI,
        guaranteeId: item.guaranteeId,
        guaranteeDate: item.guaranteeDate,
        guarantees: item.guarantees.map((subItem, index) => {
          const formattedAmount = formatToCurrency(
            subItem.Amount,
            'es-CO',
            subItem.Guarante_Type === 'USD' ? 'USD' : 'COP'
          )
          const collateral = (Number(subItem.Amount) * Number(subItem.Haircut)) / 100

          const blockedInitialMargin = collateral * (Number(contracts[0].Initial_Margin) / 100)
          const blockedIndependentAmount = collateral * (Number(contracts[0].Independent_Amount) / 100)

          const collateralAvailable = collateral - blockedInitialMargin - blockedIndependentAmount

          const formattedAvailableCollateral = formatToCurrency(
            collateralAvailable,
            'es-CO',
            subItem.Guarante_Type === 'USD' ? 'USD' : 'COP'
          )

          const formattedInitDate = `${new Date(subItem.Init_Date).toLocaleDateString()} - ${new Date(
            subItem.Init_Date
          ).toLocaleTimeString()}`

          return {
            key: subItem.Guarantes_Type_Id,
            amount: formattedAmount,
            haircut: `${subItem.Haircut}%`,
            guaranteeType: subItem.Guarante_Type,
            guaranteeName: subItem.Guarantee_Name,
            collateral: formattedAvailableCollateral,
            withdrawCollateral: 0,
            initDate: subItem.Init_Date,
            formattedInitDate,
            updateDate: subItem.Update_Date,
            guaranteeParentId: subItem.guaranteeParentId,
            collateralReceived: subItem.Collateral_Received,
            collateralDelivered: subItem.Collateral_Delivered
          }
        })
      }))
    }
  })
  return formattedEntities
}
