import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  permissions: {
    data: [],
    hasPutPermission: false,
    hasPostPermission: false
  },
  organization: {
    data: [],
    isLoading: true,
    selectedOrganization: null
  },
  users: {
    data: [],
    roles: [],
    status: [],
    isLoading: true,
    selected: {
      user: null,
      name: null,
      email: null,
      status: null,
      role: null
    }
  }
}

export const revertAll = createAction('REVERT_ALL')

export const organizationUsersSlice = createSlice({
  name: 'organizationUsers',
  initialState,
  reducers: {
    onCheckOrganizationUsersPermissions: (state, { payload }) => {
      const updatedPermissions = {
        data: payload,
        hasPutPermission: false,
        hasPostPermission: false
      }

      payload.forEach((element) => {
        if (element.method === 'POST') {
          updatedPermissions.hasPostPermission = true
        }
        if (element.method === 'PUT') {
          updatedPermissions.hasPutPermission = true
        }
      })

      return {
        ...state,
        permissions: updatedPermissions
      }
    },
    onGetOrganizations: (state, { payload }) => {
      state.organization.isLoading = false
      state.organization.data = payload
    },
    onHandleSelectedOrganization: (state, { payload }) => {
      const organizations = [...current(state.organization.data)]
      const selectedOrganization = organizations.find((el) => el.key === payload)
      state.organization.selectedOrganization = selectedOrganization
      state.users.selected = initialState.users.selected
    },
    onResetSelectedOrganization: (state, { payload }) => {
      state.organization.selectedOrganization = initialState.organization.selectedOrganization
      state.users.data = initialState.users.data
      state.users.selected = initialState.users.selected
    },
    onGetUsers: (state, { payload }) => {
      state.users.isLoading = false
      state.users.data = payload
    },
    onGetUsersData: (state, { payload }) => {
      state.users.isLoading = false
      state.users.data = payload.users
      state.users.roles = payload.roles
      state.users.status = payload.status
    },
    onHandleSelectedUser: (state, { payload }) => {
      const users = [...current(state.users.data)]
      const selectedUser = users.find((el) => el.id === payload)
      const roles = [...current(state.users.roles)]
      const selectedRole = roles.find((el) => el.value === selectedUser.roleId)
      const status = [...current(state.users.status)]
      const selectedStatus = status.find((el) => el.value === selectedUser.statusId)
      state.users.selected.user = selectedUser
      state.users.selected.role = selectedRole
      state.users.selected.status = selectedStatus
      state.users.selected.email = selectedUser.email
      state.users.selected.name = selectedUser.name
    },
    onResetSelectedUser: (state, { payload }) => {
      state.users.selected = initialState.users.selected
    },
    onChangeUserEmail: (state, { payload }) => {
      state.users.selected.email = payload
    },
    onChangeUserName: (state, { payload }) => {
      state.users.selected.name = payload
    },
    onChangeUserRole: (state, { payload }) => {
      const selectedRole = [...current(state.users.roles)].find((el) => el.key === payload)
      state.users.selected.role = selectedRole
    },
    onChangeUserStatus: (state, { payload }) => {
      const selectedStatus = [...current(state.users.status)].find((el) => el.key === payload)
      state.users.selected.status = selectedStatus
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onCheckOrganizationUsersPermissions,
  onGetOrganizations,
  onHandleSelectedOrganization,
  onResetSelectedOrganization,
  onGetUsers,
  onGetUsersData,
  onHandleSelectedUser,
  onResetSelectedUser,
  onChangeUserEmail,
  onChangeUserName,
  onChangeUserRole,
  onChangeUserStatus
} = organizationUsersSlice.actions
