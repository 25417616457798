import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  blockchain: {
    data: [],
    loading: false
  },
  assign: {
    loading: true,
    disabled: false,
    blockchainData: null,
    entities: [],
    portfolios: [],
    collateralTypes: [],
    selectedEntity: null,
    selectedPortfolio: null,
    selectedCollateral: null,
    haircut: 100,
    amount: null,
    divisibility: null,
    collaterals: []
  }
}

export const revertAll = createAction('REVERT_ALL')

export const collateralSlice = createSlice({
  name: 'collateral',
  initialState,
  reducers: {
    // blockchain
    onStartLoadBlockchainData: (state) => {
      state.blockchain.loading = true
    },
    onGetCollateralsFromBlockchain: (state, { payload }) => {
      state.blockchain.data = payload
    },
    onFinishLoadBlockchainData: (state) => {
      state.blockchain.loading = false
    },

    // Assign collaterals
    onStartLoadAssignCollaterals: (state) => {
      state.assign.loading = true
    },
    onFinishLoadAssignCollaterals: (state) => {
      state.assign.loading = false
    },
    onSendRequest: (state) => {
      state.assign.disabled = true
    },
    onFinishSendRequest: (state) => {
      state.assign.disabled = false
    },
    onResetCollaterals: (state) => {
      state.assign.selectedEntity = initialState.assign.selectedEntity
      state.assign.selectedPortfolio = initialState.assign.selectedPortfolio
      state.assign.selectedCollateral = initialState.assign.selectedCollateral
      state.assign.haircut = initialState.assign.haircut
      state.assign.amount = initialState.assign.amount
      state.assign.collaterals = initialState.assign.collaterals
      state.assign.divisibility = initialState.assign.divisibility
    },

    onGetAssignData: (state, { payload }) => {
      state.assign.entities = payload.entities
      state.assign.collateralTypes = payload.isin
    },
    onGetCollateralTypes: (state, { payload }) => {
      state.assign.collateralTypes = payload
    },
    onResetAssignCollaterals: (state) => {
      state.assign.selectedEntity = initialState.assign.selectedEntity
      state.assign.selectedPortfolio = initialState.assign.selectedPortfolio
      state.assign.selectedCollateral = initialState.assign.selectedCollateral
      state.assign.collaterals = initialState.assign.collaterals
    },

    onGetAssignedCollaterals: (state, { payload }) => {
      state.assign.collaterals = payload
    },

    onSelectAssignEntity: (state, { payload }) => {
      const entities = [...current(state.assign.entities)]
      const entity = entities.find((el) => el.key === payload)
      state.assign.selectedEntity = entity
      state.assign.portfolios = initialState.assign.portfolios
      state.assign.selectedPortfolio = initialState.assign.selectedPortfolio
    },
    onResetAssignedPortfolio: (state) => {
      state.assign.selectedPortfolio = null
    },

    onGetAssignedEntityPortfolios: (state, { payload }) => {
      state.assign.portfolios = payload
    },
    onSelectAssignPortfolio: (state, { payload }) => {
      const portfolios = [...current(state.assign.portfolios)]
      const portfolio = portfolios.find((el) => el.NIT === payload)
      state.assign.selectedPortfolio = portfolio
    },

    onSelectCollateralType: (state, { payload }) => {
      state.assign.selectedCollateral = payload
    },
    onChangeAmount: (state, { payload }) => {
      state.assign.amount = payload
    },
    onChangeHaircut: (state, { payload }) => {
      state.assign.haircut = parseInt(payload, 10).toString()
    },
    onChangeDivisibility: (state, { payload }) => {
      state.assign.divisibility = payload
    },
    onAddExistingCollateral: (state, { payload }) => {
      const collaterals = [...current(state.assign.collaterals)]
      const collateralIndex = collaterals.findIndex((el) => el.guarante_type_key === payload.key)
      state.assign.collaterals[collateralIndex] = payload.data
      state.assign.haircut = initialState.assign.haircut
      state.assign.amount = initialState.assign.amount
      state.assign.selectedCollateral = initialState.assign.selectedCollateral
    },
    onAddCollateral: (state, { payload }) => {
      state.assign.collaterals.push(payload)
      state.assign.haircut = initialState.assign.haircut
      state.assign.amount = initialState.assign.amount
      state.assign.selectedCollateral = initialState.assign.selectedCollateral
    },
    onRemoveCollateral: (state, { payload }) => {
      state.assign.collaterals = payload
    },

    onGetAssignedCollateralFromBlockchain: (state, { payload }) => {
      state.assign.blockchainData = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  // ---- Blockchain ----
  onStartLoadBlockchainData,
  onGetCollateralsFromBlockchain,
  onFinishLoadBlockchainData,

  // ---- Assign Collaterals ----
  onStartLoadAssignCollaterals,
  onFinishLoadAssignCollaterals,
  onSendRequest,
  onFinishSendRequest,
  onResetCollaterals,

  onGetAssignData,
  onGetCollateralTypes,
  onResetAssignCollaterals,
  onGetAssignedCollaterals,

  onSelectAssignEntity,
  onResetAssignedPortfolio,

  onGetAssignedEntityPortfolios,
  onSelectAssignPortfolio,

  onSelectCollateralType,
  onChangeAmount,
  onChangeHaircut,
  onChangeDivisibility,

  onAddExistingCollateral,
  onAddCollateral,
  onRemoveCollateral,

  onGetAssignedCollateralFromBlockchain
} = collateralSlice.actions
