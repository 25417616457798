import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  history: [],
  limit: 30,
  loading: true,
  loading2: false,
  filter: ''
}

export const revertAll = createAction('REVERT_ALL')

export const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    onGetLogsData: (state, { payload }) => {
      state.history = payload
    },
    onSetLimit: (state, { payload }) => {
      state.limit = payload
    },
    onAddLimit: (state) => {
      state.limit += 30
    },
    onSetLoading: (state, { payload }) => {
      state.loading = payload
    },
    onSetLoading2: (state, { payload }) => {
      state.loading2 = payload
    },
    onSetFilter: (state, { payload }) => {
      state.filter = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onGetLogsData,
  onAddLimit,
  onSetLimit,
  onSetLoading,
  onSetLoading2,
  onSetFilter
} = logsSlice.actions
