import { Button } from 'components/ui'
import { useGuaranteeValoration } from 'hooks/useGuaranteeValoration'
import { useContractValoration } from 'hooks/useContractValoration'
import { PrivateLayout } from 'layout/private'

const ColibriNettingView = () => {
  const guaranteeValoration = useGuaranteeValoration()
  const contractValoration = useContractValoration()

  return (
    <PrivateLayout title="Proceso de neteo">
      <div>
        <Button
          className="mb-8 mr-5"
          variant="solid"
          loading={guaranteeValoration.buttonIsLoading}
          onClick={guaranteeValoration.makeTransaction}
        >
          Iniciar valorización de garantías
        </Button>
      </div>

      <div>
        <Button
          className="mb-8"
          variant="solid"
          loading={contractValoration.buttonIsLoading}
          onClick={contractValoration.makeTransaction}
        >
          Iniciar valorización de contratos
        </Button>
      </div>
    </PrivateLayout>
  )
}

export default ColibriNettingView
