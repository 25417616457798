import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Select } from 'components/ui'
import { SelectedOrganizationContainer } from 'components/features/organizationUsers'
import {
  // onCheckOrganizationUsersPermissions,
  onGetOrganizations,
  onGetUsersData,
  onHandleSelectedOrganization
} from 'app/features/organizationUsers.slice'
import { rolesAdapter, simpleOrganizationsAdapter, statusAdapter } from 'adapters/organization'
import { getAllOrganizations, getOrganizationUsers } from 'services/organization-service'
import { onOpenModal } from 'app/features/ui.slice'

export const OrganizationUsersView = () => {
  const { organization } = useSelector((state) => state.organizationUsers)
  const { data, isLoading, selectedOrganization } = organization
  const dispatch = useDispatch()

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const { data } = await getAllOrganizations()
        const organizations = simpleOrganizationsAdapter(data.response)
        dispatch(onGetOrganizations(organizations))
      } catch (err) {
        console.log(err)
      }
    }
    getOrganizations()
  }, [])

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        if (selectedOrganization) {
          const { data } = await getOrganizationUsers(selectedOrganization.key)
          const roles = rolesAdapter(data.response.roles)
          const status = statusAdapter(data.response.status)
          dispatch(onGetUsersData({ users: data.response.user, roles, status }))
        }
      } catch (err) {
        dispatch(onOpenModal({ message: err?.response.data.message ?? 'Fallo al cargar organizaciones', type: 'ERROR' }))
      }
    }
    getOrganizations()
  }, [selectedOrganization])

  return (
    <PrivateLayout title="Usuarios">
      <div className="mb-8">
        <h5 className="mb-2">Organizaciones</h5>
        <Select
          placeholder="Seleccione organización"
          options={data}
          isLoading={isLoading}
          onChange={(value) => dispatch(onHandleSelectedOrganization(value.key))}
          value={selectedOrganization}
        />
      </div>
      {selectedOrganization && <SelectedOrganizationContainer />}
    </PrivateLayout>
  )
}
