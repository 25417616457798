import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: {
    current: 'es-ES'
  },
  route: {
    currentKey: ''
  }
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    onChangeLang: (state, { payload }) => (state.lang.current = payload),
    onSetCurrentRouteKey: (state, { payload }) => (state.route.currentKey = payload)
  }
})

export const { onChangeLang, onSetCurrentRouteKey } = layoutSlice.actions
