/* eslint-disable multiline-ternary */
import { useSelector } from 'react-redux'
import AuthLayout from 'layout/authentication/AuthLayout'
import { OtpVerification, SignInForm } from 'components/features/auth'

export const SignInView = () => {
  const { user, auth } = useSelector((state) => state.authorization)
  return (
    <AuthLayout>
      <div className="max-w-md w-full">
        <h3 className="mb-1">MarketXM</h3>
        {auth.session == null ? (
          <p>Ingresa tus credenciales para acceder al sistema.</p>
        ) : (
          <div>
            <p>Hola {user.name}.</p>
            {user.otpActive ? (
              <p>Por favor ingresa tu clave dinámica.</p>
            ) : (
              <p>Tu organización requiere configuración de clave dinámica con Google Authenticator.</p>
            )}
          </div>
        )}
      </div>
      <div className="max-w-md w-full">{auth.session == null ? <SignInForm /> : <OtpVerification />}</div>
    </AuthLayout>
  )
}
