import classNames from 'classnames'
import { HiChevronLeft } from 'react-icons/hi'

const Prev = (props) => {
  // eslint-disable-next-line react/prop-types
  const { currentPage, pagerClass, onPrev } = props

  const disabled = currentPage <= 1

  const onPrevClick = (e) => {
    if (disabled) {
      return
    }
    onPrev(e)
  }

  const pagerPrevClass = classNames(
    // eslint-disable-next-line react/prop-types
    pagerClass.default,
    'pagination-pager-prev',
    // eslint-disable-next-line react/prop-types
    disabled ? pagerClass.disabled : pagerClass.inactive
  )

  return (
    <span
      className={pagerPrevClass}
      onClick={onPrevClick}
    >
      <HiChevronLeft />
    </span>
  )
}

export default Prev
