export const portfoliosAdapter = (data) => {
  return data.map((el) => {
    const formattedCreatedAt = `${new Date(el.createdAt).toLocaleDateString()} - ${new Date(
      el.createdAt
    ).toLocaleTimeString()}`
    return {
      value: el.id,
      key: el.NIT,
      NIT: el.NIT,
      LEI: el.LEI,
      label: el.name,
      status: el.status.key,
      createdAt: data.createdAt,
      formattedCreatedAt
    }
  })
}
