import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Tag } from 'components/ui'
import { CollateralCard } from 'features/dashboard/components'
import { useCalculateCollaterals } from 'features/dashboard/hooks'
import { onGetContracts, onGetPortfolios, onOpenModal } from 'app/features/ui.slice'
import { contractsAdapter, portfolioAdapter } from 'adapters'
import { getContractPortfoliosByOrganizationKey } from 'services/contracts.service'

export const PortfolioRelevantInformation = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationKey } = user
  const { dashboard } = useSelector((state) => state.ui)
  const { collaterals, portfolios, selectedPortfolio } = dashboard

  const dispatch = useDispatch()

  const { onSelectPortfolio } = useCalculateCollaterals()

  useEffect(() => {
    const getData = async () => {
      try {
        if (organizationKey) {
          const { data } = await getContractPortfoliosByOrganizationKey(organizationKey)
          const formattedPortfolios = portfolioAdapter(data.response.results)
          const formattedContracts = contractsAdapter(data.response.contracts)
          dispatch(onGetPortfolios(formattedPortfolios))
          dispatch(onGetContracts(formattedContracts))
        }
      } catch (err) {
        dispatch(onOpenModal({ message: 'Algo salio mal cargando datos', type: 'ERROR' }))
      }
    }
    getData()
  }, [organizationKey])

  return (
    <div>
      <div className="mb-8">
        <h5 className="mb-2">Portafolios</h5>
        <Select
          placeholder="Seleccione portafolio correspondiente"
          options={portfolios}
          isDisabled={collaterals.loading}
          onChange={onSelectPortfolio}
          value={selectedPortfolio}
        />
      </div>
      {collaterals.marginCall && (
        <div className="mb-8">
          <Tag className={'bg-emerald-100 text-red-600 dark:bg-red-600 dark:text-emerald-100 border-0 rounded '}>
            <h6>&#x1F4A1; ¡Constituir más garantías!</h6>
          </Tag>
        </div>
      )}
      <div className="grid grid-cols-3 gap-8 mb-8">
        <CollateralCard
          title="Colateral Elegible"
          description="Monto de Colateral Entregado a agente de custodia."
          value={collaterals.eligibleCollateral}
        />
        <CollateralCard
          title="Colateral Constituido"
          description="Monto de Colateral Elegible después aplicar Haircut."
          value={collaterals.totalCollaterals}
        />
        <CollateralCard
          title="Colateral Bloqueado"
          description="Monto porcentual independiente + Margen porcentual inicial + Colateral entregado."
          value={collaterals.blockedCollateral}
        />
        <CollateralCard
          title="Colateral Disponible"
          description="Monto de colateral que puede ser redimido."
          value={collaterals.availableCollateral}
        />
        <CollateralCard
          title="Colateral Recibido"
          description="Monto de Colateral Bloqueado por Contrapartes a favor de la entidad usuaria."
          value={collaterals.receivedCollateral}
        />
        <CollateralCard
          title="Colateral Entregado"
          description="Monto de Colateral Bloqueado a favor de Contrapartes por la entidad usuaria."
          value={collaterals.deliveredCollateral}
        />
      </div>
    </div>
  )
}
