/* eslint-disable multiline-ternary */
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Logo } from 'layout/private'
import { onHandleResetModule, onHandleSelectedModule } from 'app/features/ui.slice'
import navigationIcon from 'configs/navigation-icon.config'

export const SideNavigation = () => {
  const dispatch = useDispatch()
  const sideNavCollapse = useSelector((state) => state.theme.layout.sideNavCollapse)
  const { modules } = useSelector((state) => state.ui)
  const { data, selectedModule } = modules

  const handleSelectModule = (item) => {
    if (selectedModule == null) {
      dispatch(onHandleSelectedModule(item.parent.id))
    } else if (selectedModule.parent.module === item.parent.module) {
      dispatch(onHandleResetModule())
    }
  }
  return (
    <div
      className={`${
        sideNavCollapse ? 'w-20' : 'w-80'
      } flex flex-col flex-auto flex-shrink-0 sticky top-0 h-screen z-20 transition-all ease-in-out duration-200`}
    >
      <div className="side-nav-header">
        <Logo />
        <div className="w-full border border-transparent">
          {data.map((item) => (
            <div
              key={item.parent.id}
              className={`${
                sideNavCollapse ? 'justify-center' : 'justify-start '
              } flex flex-col m-4 rounded-md overflow-hidden hover:bg-gray-800 duration-200`}
            >
              {item.parent.path ? (
                <Link
                  to={item.parent.path}
                  className={`${sideNavCollapse ? 'justify-center' : 'justify-start '} flex w-full p-4 items-start`}
                >
                  <span className="text-2xl flex items-center justify-center">{navigationIcon[item.parent.icon]}</span>
                  {!sideNavCollapse && <span className="ml-2">{item.parent.label}</span>}
                </Link>
              ) : (
                <button
                  className={`${sideNavCollapse ? 'justify-center' : 'justify-start '} flex w-full p-4 items-start`}
                  onClick={() => handleSelectModule(item)}
                >
                  <span className="text-2xl flex items-center justify-center">{navigationIcon[item.parent.icon]}</span>
                  {!sideNavCollapse && <span className="ml-2">{item.parent.label}</span>}
                </button>
              )}
              {selectedModule && selectedModule.parent.module === item.parent.module && (
                <div className="flex flex-col items-start justify-center px-4">
                  {item.child
                    ?.filter((child) => child.visible)
                    .map((child) => (
                      <Link
                        to={child.path}
                        key={child.id}
                        className="w-full p-2 my-2 first:mt-4 last:mb-4 rounded-md hover:bg-gray-700"
                      >
                        <span>{child.label}</span>
                      </Link>
                    ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
