import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: 'checking', // authenticated, not-authenticated
  loading: false,
  user: {
    id: null,
    name: null,
    lastIp: null,
    lastLogin: null,
    roleId: null,
    otp: null,
    otpRequired: null,
    organizationId: null,
    organizationKey: null,
    organizationName: null,
    organizationType: null
  },
  token: null,
  error: undefined
}

// export const revertAll = createAction('REVERT_ALL')

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onStartLoading: (state) => {
      state.loading = true
    },
    onFinishLoading: (state) => {
      state.loading = false
    },
    onChecking: (state) => {
      state.user = {}
      state.token = null
      state.error = null
      state.status = 'checking'
    },
    onReceiveSignInData: (state, { payload }) => {
      state.user = payload.data
      state.token = payload.token
      state.error = null
      state.loading = false
    },
    onSignIn: (state, { payload }) => {
      state.user = payload.data
      state.token = payload.token
      state.error = null
      state.status = 'authenticated'
      state.loading = false
    },
    onSignOut: (state) => {
      state.user = {}
      state.token = null
      state.error = undefined
      state.status = 'not-authenticated'
    },
    onErrorMessage: (state, { payload }) => {
      state.error = payload.message
    },
    onClearErrorMessage: (state) => {
      state.error = undefined
    }
  }
  // extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onStartLoading,
  onFinishLoading,
  onChecking,
  onReceiveSignInData,
  onSignIn,
  onSignOut,
  onErrorMessage,
  onClearErrorMessage
} = authSlice.actions
