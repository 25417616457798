/* eslint-disable multiline-ternary */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Card } from 'components/ui'
import { DataTable } from 'components/dataTable'
import { ParagraphSkeleton } from 'components/skeleton/ParagraphSkeleton'
import { onFinishDashboardLoading, onGetCustodianDashboardData, onStartDashboardLoading } from 'app/features/dashboard.slice'
import { formatToCurrency } from 'helpers/formatToCurrency'
import { custodianDataAdapter } from 'adapters/custodianData.adapter'
import { custodianDashboardDataService } from 'services/custodian.service'
import custodianDashboardDataTable from 'config/json/custodianDashboardDataTable.json'

export const CustodianDashboardView = () => {
  const dispatch = useDispatch()
  // const { user } = useSelector((state) => state.authorization)
  const { custodian, loading } = useSelector((state) => state.dashboard)
  const { totalCollateral, totalCollateralCustodian, data } = custodian

  useEffect(() => {
    const getData = async () => {
      dispatch(onStartDashboardLoading())
      const { data } = await custodianDashboardDataService()
      const result = custodianDataAdapter(data.response)
      const totalCollateral = result.reduce((acc, el) => acc + parseFloat(el.collateralReceived), 0)
      const formattedCollateralReceived = formatToCurrency(totalCollateral, 'es-CO', 'COP')
      const totalFaceValue = result.reduce((acc, el) => acc + parseFloat(el.faceValue), 0)
      const formattedCollateralCustodian = formatToCurrency(totalFaceValue, 'es-CO', 'COP')

      dispatch(
        onGetCustodianDashboardData({
          data: result,
          totalCollateral: formattedCollateralReceived,
          totalCollateralCustodian: formattedCollateralCustodian
        })
      )
      dispatch(onFinishDashboardLoading())
    }
    getData()
  }, [])

  return (
    <PrivateLayout title="Colaterales asignados">
      <Card
        bordered
        className="mb-8"
      >
        <div className="grid grid-cols-6 gap-8">
          <div className="col-span-4">
            <h4>Total Colaterales Asignados</h4>
          </div>
          <div className="max-w-xs">
            <h5>Colateral Constituido</h5>
            {loading ? (
              <ParagraphSkeleton />
            ) : (
              <div>
                <p>{totalCollateral}</p>
              </div>
            )}
          </div>
          <div className="max-w-xs">
            <h5>Colateral Custodiado</h5>
            {loading ? (
              <ParagraphSkeleton />
            ) : (
              <div>
                <p>{totalCollateralCustodian}</p>
              </div>
            )}
          </div>
        </div>
      </Card>
      <DataTable
        columns={custodianDashboardDataTable}
        data={data}
      />
    </PrivateLayout>
  )
}
