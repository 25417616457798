import { useSelector } from 'react-redux'
import { Card, Input, Select } from 'components/ui'
import { useCreateContract } from 'hooks/useCreateContract'

export const PartBCard = () => {
  const { contract, data } = useSelector((state) => state.contract)
  const { loading, partB } = contract
  const { modulePermissions } = useSelector((state) => state.permission)
  const { handleChangeEntityB, handlePortfolioB } = useCreateContract()

  return (
    <Card className="mb-8">
      <h3 className="mb-4">Parte B</h3>
      <div className="mb-8">
        <h5 className="mb-2">Entidad</h5>
        <p className="mb-2">Seleccione entidad correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.funds}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partB.entity}
          onChange={handleChangeEntityB}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Portafolio</h5>
        <p className="mb-2">Seleccione portafolio correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={partB.portfolios}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partB.portfolio}
          onChange={handlePortfolioB}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">LEI</h5>
        <Input
          placeholder="LEI"
          type="text"
          disabled={true}
          value={partB.portfolio ? partB.portfolio.LEI : ''}
        />
      </div>

      <div>
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="NIT"
          type="text"
          disabled={true}
          value={partB.portfolio ? partB.portfolio.NIT : ''}
        />
      </div>
    </Card>
  )
}
