import { formatToCurrency } from 'helpers/formatToCurrency'

export const contractValuationsAdapter = (data) => {
  return data.map((item) => {
    const formattedMarketValue = formatToCurrency(item.market_value, 'es-CO', 'COP')
    return {
      date: new Date(item.date).toLocaleDateString('es-ES'),
      portfolioA_LEI: item.portfolio_a_lei,
      portfolioB_LEI: item.portfolio_b_lei,
      marketValue: formattedMarketValue,
      beneficiary: item.beneficiary,
      uploadAt: `${new Date(item.upload_at).toLocaleDateString('es-ES')} - ${new Date(
        item.upload_at
      ).toLocaleTimeString('es-ES')}`,
      fileUrl: item.file_url,
      createdUser: item.createdUser.email,
      assignedUser: item.assignedUser.email
    }
  })
}
