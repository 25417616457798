/* eslint-disable multiline-ternary */
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Button, Dialog, Input, Tag } from 'components/ui'
import { ParagraphSkeleton } from 'components/skeleton/ParagraphSkeleton'
import { onChangePartB, onFinishContractLoading, onGetRelatedData, onStartContractLoading, onViewContract } from 'app/features/contractSlice'
import { PartAEditContractCard, PartBEditContractCard, PartContractEditContractCard } from 'features/bank/updateContracts'
import { currencyAdapter, custodianAdapter, entityAdapter, portfolioAdapter, valuatorAdapter } from 'adapters'
import { getBankContractDataLinked } from 'services/bank.service'
import { useEditContract } from 'hooks/useEditContract'

export const DerivativeDetailView = () => {
  const { id: contractId } = useParams()
  const { user } = useSelector((state) => state.authorization)
  const { organizationId, organization, organizationKey, organizationType } = user
  const { modulePermissions } = useSelector((state) => state.permission)

  const { contract } = useSelector((state) => state.contract)

  const { btnLoading, message, modalOpen, onCloseModal, updateTransaction } = useEditContract()
  const dispatch = useDispatch()

  useEffect(() => {
    const getData = async () => {
      if (contractId) {
        try {
          dispatch(onStartContractLoading())
          const { data } = await getBankContractDataLinked(contractId)
          const portfolios = portfolioAdapter(data.response.portfolios)
          const currencies = currencyAdapter(data.response.currencies)
          const valuators = valuatorAdapter(data.response.valuators)
          const custodians = custodianAdapter(data.response.custodians)
          const funds = entityAdapter(data.response.funds)
          dispatch(onGetRelatedData({ portfolios, currencies, valuators, custodians, funds }))
          dispatch(
            onViewContract({
              approved: data.response.contract[0].Accepted,
              contractId: data.response.contract[0].Contract_Register_Id,
              entityA: {
                value: organizationId,
                label: organization,
                key: organizationKey,
                type: organizationType
              },
              APortfolioNIT: data.response.contract[0].APortfolio_NIT,
              BEntityKey: data.response.contract[0].BEntity_Id,
              CSADate: new Date(data.response.contract[0].CSA_Date),
              finishDate: new Date(data.response.contract[0].Finish_Date),
              valuatorKey: data.response.contract[0].Valuator_Id,
              ACustodianKey: data.response.contract[0].ACustodian_Id,
              BCustodianKey: data.response.contract[0].BCustodian_Id,
              currencyKey: data.response.contract[0].Currency_Id,
              initialMargin: data.response.contract[0].Initial_Margin,
              independentAmount: data.response.contract[0].Independent_Amount,
              threshold: data.response.contract[0].Threshold,
              minTransferAmount: data.response.contract[0].Minimum_Transfer_Amount,
              createdUser: {
                name: data.response.contract[0].Created_User_Name,
                email: data.response.contract[0].Created_User_Email
              },
              approvedUser: {
                name: data.response.contract[0].Approved_User_Name,
                email: data.response.contract[0].Approved_User_Email
              }
            })
          )
          dispatch(onFinishContractLoading())
          dispatch(onChangePartB(data.response.contract[0].BPortfolio_NIT))
        } catch (err) {
          console.log(err)
        }
      }
    }
    getData()
  }, [contractId])

  return (
    <PrivateLayout title="Datos de contrato">
      {contract.loading ? (
        <div className="max-w-xs mb-8">
          <ParagraphSkeleton />
        </div>
      ) : (
        <div className="mb-10">
          <Tag
            className={
              contract.approved
                ? 'bg-emerald-100 text-green-600 dark:bg-green-600 dark:text-emerald-100 border-0 rounded mb-2 '
                : 'bg-emerald-100 text-orange-600 dark:bg-orange-600 dark:text-emerald-100 border-0 rounded mb-2 '
            }
          >
            {contract.approved ? 'Aprobado' : 'Por aprobar'}
          </Tag>

          <div className="mb-4">
            <h5 className="mb-2">Creado por:</h5>
            <Input
              placeholder="Creado por:"
              type="text"
              disabled={true}
              value={`${contract.createdUser.name} (${contract.createdUser.email})`}
            />
          </div>

          {contract.approved && (
            <div className="mb-8">
              <h5 className="mb-2">Aprobado por:</h5>
              <Input
                placeholder="Aprobado por:"
                type="text"
                disabled={true}
                value={`${contract.approvedUser.name} (${contract.approvedUser.email})`}
              />
            </div>
          )}
        </div>
      )}
      <PartAEditContractCard />
      <PartBEditContractCard />
      <PartContractEditContractCard />
      <Button
        className="mb-8"
        variant="solid"
        loading={btnLoading}
        disabled={contract.approved || btnLoading || !modulePermissions.hasPutPermission}
        onClick={updateTransaction}
      >
        Actualizar
      </Button>
      <Dialog
        isOpen={modalOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onCloseModal}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </PrivateLayout>
  )
}
