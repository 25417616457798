import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FilterDataTableWithLink } from 'components/dataTable'
import TabContent from 'components/ui/Tabs/TabContent'
import guaranteeValuations from 'features/valuator/dashboard/json/guaranteeValuations.json'
import { onFinishDashboardLoading, onGetGuaranteeValuations, onStartDashboardLoading } from 'app/features/dashboard.slice'
import { guaranteeValuationsAdapter } from 'adapters/guaranteeValuations'
import { getGuaranteeValuations } from 'services/valuator.service'

export const GuaranteeValuationsTable = ({ currentTab }) => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationId } = user
  const { valuator } = useSelector((state) => state.dashboard)
  const { guarantees } = valuator
  const dispatch = useDispatch()

  useEffect(() => {
    const getValuations = async () => {
      if (currentTab === 'guarantee_valuation') {
        dispatch(onStartDashboardLoading())
        const { data, status } = await getGuaranteeValuations(organizationId)
        if (status === 200) {
          const result = guaranteeValuationsAdapter(data)
          dispatch(onGetGuaranteeValuations(result))
        }
        dispatch(onFinishDashboardLoading())
      }
    }
    getValuations()
  }, [currentTab])

  return (
    <TabContent value="guarantee_valuation">
      <FilterDataTableWithLink
        columns={guaranteeValuations}
        data={guarantees}
      />
    </TabContent>
  )
}

GuaranteeValuationsTable.propTypes = {
  currentTab: PropTypes.string.isRequired
}
