import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'components/ui'
import { UserDetailEditCard, UserDetailCreateCard } from 'features/organizations/components'
import usersDataTableHeaders from 'features/organizations/json/usersDataTable.json'
import { onResetSelectedUser, onSearchSelectedUser } from 'app/features/organization.slice'
import { SelectDataTable } from 'components/dataTable'

export const UserDetailCard = () => {
  const { users, input } = useSelector((state) => state.organization)
  const { user } = input
  const dispatch = useDispatch()

  const [cardIsOpen, setCardIsOpen] = useState(false)

  const onSelectItem = (e, row) => {
    dispatch(onSearchSelectedUser(row.original.key))
    setCardIsOpen(false)
  }

  const onOpenCard = () => {
    dispatch(onResetSelectedUser())
    setCardIsOpen(!cardIsOpen)
  }

  return (
    <Card className="mb-8">
      <div className="mb-8">
        <h4 className="mb-2">Usuarios</h4>
        <SelectDataTable
          columns={usersDataTableHeaders}
          data={users}
          onSelectItem={onSelectItem}
        />
      </div>

      {user.selectedUser !== null && <UserDetailEditCard />}

      <div className="flex items-center justify-end">
        <Button
          className="mb-0"
          onClick={onOpenCard}
        >
          Crear usuario
        </Button>
      </div>

      {cardIsOpen && <UserDetailCreateCard setCardIsOpen={setCardIsOpen} />}
    </Card>
  )
}
