const Total = (props) => {
  // eslint-disable-next-line react/prop-types
  const { total } = props
  return (
    <div className="pagination-total">
            Total <span>{total}</span> Items
    </div>
  )
}

export default Total
