import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import jwtDecode from 'jwt-decode'
import { AuthLayout } from 'layout/authentication'
import { PasswordInput } from 'components/shared'
import { Button, FormContainer, FormItem } from 'components/ui'
import { resetPasswordValidationSchema } from 'helpers/validators'
import { resetPasswordService } from 'services/auth.service'
import { useDispatch } from 'react-redux'
import { onOpenModal } from 'app/features/ui.slice'
import axios from 'axios'

const initialValues = {
  password: '',
  rePassword: ''
}

const ENV = process.env.REACT_APP_ENV
const API_ENDPOINT = process.env.REACT_APP_API_URL
const API_DEV_ENDPOINT = process.env.REACT_APP_API_URL_DEV
const BASE_URL = ENV === 'develop' ? API_DEV_ENDPOINT : API_ENDPOINT

export const ResetPasswordView = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChangePassword = async (values) => {
    setIsLoading(true)
    if (values.password === values.rePassword) {
      const data = jwtDecode(token)
      const user = { uuid: data.uuid, email: data.email, password: values.password, first_login: data.first_login }
      try {
        const { data: response, status } = await resetPasswordService(user)
        if (status === 200) {
          const headers = { Authorization: token }
          await axios.post(String(`${BASE_URL}/api/user/activate`), { email: data.email }, { headers })
          setIsLoading(false)
          dispatch(onOpenModal({ message: response.message, type: 'SUCCESS' }))
          navigate('/sign-in')
        }
      } catch (err) {
        setIsLoading(false)
        dispatch(onOpenModal({ message: err.response.data.message, type: 'SUCCESS' }))
      }
    } else {
      dispatch(onOpenModal({ message: 'Contraseñas deben coincidir', type: 'ERROR' }))
      setIsLoading(false)
    }
  }

  return (
    <AuthLayout>
      <div className="mb-8 max-w-md w-full">
        <h3 className="mb-1">MarketXM</h3>
        <p>Ingresa tu nueva contraseña</p>
      </div>
      <div className="max-w-md w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleChangePassword(values, setSubmitting)
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <FormContainer>
                <FormItem
                  label="Ingresa tu contraseña"
                  invalid={errors.password && touched.password}
                  errorMessage={errors.email}
                >
                  <Field
                    autoComplete="off"
                    name="password"
                    placeholder="*********"
                    component={PasswordInput}
                  />
                </FormItem>
                <FormItem
                  label="Confirma tu contraseña"
                  invalid={errors.rePassword && touched.rePassword}
                  errorMessage={errors.password}
                >
                  <Field
                    autoComplete="off"
                    name="rePassword"
                    placeholder="*********"
                    component={PasswordInput}
                  />
                </FormItem>
                <Button
                  block
                  variant="solid"
                  type="submit"
                >
                  {isLoading ? 'Validando...' : 'Cambiar contraseña'}
                </Button>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  )
}
