import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SideContainer from 'layout/authentication/SideContainer'
import { Button, Dialog } from 'components/ui'
import { onCloseModal } from 'app/features/ui.slice'

const AuthLayout = ({ children }) => {
  const { modal } = useSelector((state) => state.ui)
  const dispatch = useDispatch()
  return (
    <div className="grid grid-cols-3">
      <div className="w-full h-screen">
        <SideContainer />
      </div>
      <div className="col-span-2 flex items-center justify-center flex-col">{children}</div>
      <Dialog
        isOpen={modal.open}
        closable={false}
      >
        <h5 className="mb-4">{modal.type === 'ERROR' ? 'Error' : 'Éxito'}</h5>
        <p>{modal.message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={() => dispatch(onCloseModal())}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default AuthLayout

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
}
