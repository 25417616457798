import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Button, Input, Select } from 'components/ui'
import { CreateUserCard, CreatePortfolioCard } from 'features/organizations/components'
import {
  onChangeEntityNIT,
  onChangeEntityName,
  onChangeEntityStatus,
  onChangeEntityType,
  onChangeOTPRequired,
  onGetDataOrganization,
  onResetCreateOrganizationView
} from 'app/features/organization.slice'
import { onOpenModal } from 'app/features/ui.slice'
import { statusAdapter, typeAdapter } from 'adapters/core'
import { createOrganizationService, getDataCreateOrganization } from 'services/colibri.service'
import { createAuthProviderUsers } from 'services/user/user.services'

export const OrganizationCreateView = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { organizationTypes, statusTypes, input, portfolios, users } = useSelector((state) => state.organization)
  const { entity } = input
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(onResetCreateOrganizationView())
        const { data } = await getDataCreateOrganization()
        const statusTypes = statusAdapter(data.statusTypes)
        const organizationTypes = typeAdapter(data.organizationTypes)
        dispatch(onGetDataOrganization({ status: statusTypes, types: organizationTypes }))
      } catch (err) {
        dispatch(onOpenModal({ message: 'Error obteniendo datos iniciales', type: 'ERROR' }))
      }
    }
    getData()
  }, [])

  const handleCreateOrganization = async () => {
    try {
      setIsLoading(true)
      const { status } = await createAuthProviderUsers(users)
      if (status === 200) {
        const { data } = await createOrganizationService(entity, portfolios, users)
        dispatch(onResetCreateOrganizationView())
        dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
        setIsLoading(false)
      }
    } catch (err) {
      dispatch(onOpenModal({ message: 'Algo salio mal', type: 'ERROR' }))
      setIsLoading(false)
    }
  }

  return (
    <PrivateLayout title="Añadir organización">
      <div className="mb-4">
        <h5 className="mb-2">Nombre de organización</h5>
        <Input
          placeholder="Ingrese nombre de la organización"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangeEntityName(target.value))}
          value={entity.name}
        />
      </div>

      <div className="mb-4">
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="Ingrese el NIT de la organización"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangeEntityNIT(target.value))}
          value={entity.NIT}
        />
      </div>

      <div className="mb-4">
        <h5 className="mb-2">Tipo de organización</h5>
        <Select
          placeholder="Seleccione el tipo de organización"
          options={organizationTypes}
          isDisabled={!modulePermissions.hasPostPermission}
          onChange={(value) => dispatch(onChangeEntityType(value.key))}
          value={entity.type}
        />
      </div>

      <div className="mb-4">
        <h5 className="mb-2">Estado de organización</h5>
        <Select
          placeholder="Seleccione el estado de la organización"
          options={statusTypes}
          isDisabled={!modulePermissions.hasPostPermission}
          onChange={(value) => dispatch(onChangeEntityStatus(value.key))}
          value={entity.status}
        />
      </div>

      <div className="mb-4">
        <h5 className="mb-2">Segundo factor de autenticación (OTP Auth)</h5>
        <div className="w-full flex items-center justify-between border p-3 rounded-md">
          <p>Requerido</p>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              id={'otp-create-organization'}
              onChange={() => dispatch(onChangeOTPRequired())}
            />
            <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
          </label>
        </div>
      </div>

      {entity.type && entity.type.key === 'bank' && <CreatePortfolioCard />}
      {entity.type && entity.type.key === 'fund' && <CreatePortfolioCard />}

      <CreateUserCard />

      <Button
        variant="solid"
        className="mb-8"
        loading={isLoading}
        disabled={entity.NIT === null || entity.type === null || entity.name === null || !modulePermissions.hasPostPermission}
        onClick={() => handleCreateOrganization()}
      >
        Añadir organización
      </Button>
    </PrivateLayout>
  )
}
