import { PrivateLayout } from 'layout/private'
import dataTableAllOrganizationsHeaders from 'data/organization/dataTableAllOrganizationsHeaders.json'
import { DataTableWithTwoButtons } from 'components/dataTable/DataTableWithTwoButtons'
import { getAllOrganizationList } from 'services/colibri.service'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onGetOrganizations } from 'app/features/organization.slice'
import { organizationListAdapter } from 'adapters/organization'

export const OrganizationsListView = () => {
  const { organizations } = useSelector((state) => state.organization)
  const dispatch = useDispatch()
  useEffect(() => {
    const getData = async () => {
      const { data, status } = await getAllOrganizationList()
      if (status === 200) {
        const formattedOrganizations = organizationListAdapter(data.response)
        dispatch(onGetOrganizations(formattedOrganizations))
      }
    }
    getData()
  }, [])
  return (
    <PrivateLayout title="Lista de organizaciones">
      <DataTableWithTwoButtons
        columns={dataTableAllOrganizationsHeaders}
        data={organizations}
      />
    </PrivateLayout>
  )
}
