import { onOpenModal } from 'app/features/ui.slice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { colibriValorateGuaranteesService } from 'services/colibri.service'

export const useGuaranteeValoration = () => {
  const dispatch = useDispatch()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const makeTransaction = async (e) => {
    setButtonIsLoading(true)
    try {
      const { data } = await colibriValorateGuaranteesService()
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
    } catch (err) {
      console.log(err)
      dispatch(onOpenModal({ message: err.response?.data.message ?? 'Ocurrió un error.', type: 'ERROR' }))
    }
    setButtonIsLoading(false)
  }

  return {
    makeTransaction,
    buttonIsLoading
  }
}
