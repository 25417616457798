import PropTypes from 'prop-types'
import TabList from 'components/ui/Tabs/TabList'
import TabNav from 'components/ui/Tabs/TabNav'

export const TabsList = ({ tabs }) => {
  return (
    <TabList className="mb-8">
      {tabs.map((tab) => (
        <TabNav
          key={tab.value}
          value={tab.value}
        >
          {tab.label}
        </TabNav>
      ))}
    </TabList>
  )
}

TabsList.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.any).isRequired
}
