import { formatToCurrency } from 'helpers/formatToCurrency'

export const colibriDataAdapter = (data) => {
  return data.map((item) => {
    const formattedCollateralReceived = formatToCurrency(item.Collateral_Received, 'es-CO', 'COP')
    const formattedFaceValue = formatToCurrency(item.Face_Value, 'es-CO', 'COP')
    let guaranteesLength = 0
    if (item.Guarantes_Types !== null) {
      guaranteesLength = item.Guarantes_Types.length
    }
    if (guaranteesLength === 1) {
      guaranteesLength = `${guaranteesLength} Colateral asignado`
    } else {
      guaranteesLength = `${guaranteesLength} Colaterales asignados`
    }
    return {
      collateralReceived: item.Collateral_Received,
      formattedCollateralReceived,
      custodianId: item.Custodian_Id,
      custodianName: item.Custodian_Name,
      entityId: item.Entity_Id,
      entityName: item.Entity_Name,
      entityType: item.Entity_Type,
      formattedFaceValue,
      faceValue: item.Face_Value,
      guaranteeRegisterId: item.Guarantee_Register_Id,
      guarantees: item.Guarantes_Types,
      guaranteesLength,
      portfolioName: item.Portfolio_name,
      portfolioNIT: item.Portfolio_nit,
      portfolioLEI: item.Portfolio_lei
    }
  })
}
