import { backendBaseURL } from 'interceptors/backend.interceptor'

export const getFullyCollateralHistoryByEntityKey = async (organizationKey) => {
  return await backendBaseURL.get(`/api/collaterals/entity/${organizationKey}`)
}
export const getFullyCollateralValuationsHistoryByPortfolioNIT = async (nit, initDate, endDate) => {
  const params = {
    nit,
    initDate: new Date(initDate).toDateString(),
    endDate: new Date(endDate).toDateString()
  }
  return await backendBaseURL.get('/api/collaterals/valuations/entity', { params })
}

export const getCollateralsByPortfolioNIT = async (nit) => {
  return await backendBaseURL.get(`/api/collaterals/portfolio/${nit}`)
}

export const getAssignedCollateralsByEntityId = async () => {
  return await backendBaseURL.get('/api/collaterals/entity/assigned')
}
