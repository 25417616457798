import { backendBaseURL } from '../interceptors/backend.interceptor'

export const fundDashboardDataService = async () => {
  return await backendBaseURL.get('/api/v2/fund/dashboard')
}

export const getFundContracts = async (organizationKey) => {
  return await backendBaseURL.get(`/api/contracts/fund/${organizationKey}`)
}
export const getFundContractDataLinked = async (uuid) => {
  return await backendBaseURL.get(`/api/contracts/fund/contract/${uuid}`)
}
export const approveContract = async (partA, partB, partContract, contractId) => {
  return await backendBaseURL.put('/api/contracts/approve', { partA, partB, partContract, contractId })
}
