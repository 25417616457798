/* eslint-disable multiline-ternary */
import { SelectSkeleton } from 'components/skeleton/SelectSkeleton'
import { Card, Input, InputPercentage } from 'components/ui'
import { useSelector } from 'react-redux'

export const FundContractCard = () => {
  const { contract } = useSelector((state) => state.contract)
  const { partContract, loading } = contract
  return (
    <Card className="mb-8">
      <h3 className="mb-4">Contrato</h3>
      <div className="mb-8">
        <h5 className="mb-2">Fecha CSA</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={new Date(partContract.date).toLocaleDateString()}
          />
        )}
      </div>
      {/* <div className="mb-8">
        <h5 className="mb-2">Fecha de finalización</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={new Date(partContract.endDate).toLocaleDateString()}
          />
        )}
      </div> */}

      <div className="mb-8">
        <h5 className="mb-2">Agente de valoración</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.valuator.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente de custodio Parte A</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.custodianA.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente de custodio Parte B</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.custodianB.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Moneda</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.currency.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Margen inicial</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <InputPercentage
            placeholder="Entidad"
            disabled={true}
            value={partContract.initialMargin}
          />
        )}
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Monto independiente</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <InputPercentage
            placeholder="Entidad"
            disabled={true}
            value={partContract.independentAmount}
          />
        )}
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Umbral/Threshold</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.threshold}
          />
        )}
      </div>
      <div>
        <h5 className="mb-2">Monto mínimo de transferencia</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partContract.minTransferAmount}
          />
        )}
      </div>
    </Card>
  )
}
