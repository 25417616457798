import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: 'checking', // checking, authenticated, not-authenticated
  loading: false,
  auth: {
    uuid: null,
    email: null,
    session: null
  },
  user: {
    name: null,
    otpActive: null,
    otpRequired: null,
    role: null,
    roleId: null,
    organization: null,
    organizationKey: null,
    organizationId: null,
    organizationType: null
  },
  token: {
    refreshToken: null
  }
}

// export const revertAll = createAction('REVERT_ALL')

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    onAuthLoading: (state) => {
      state.loading = true
    },

    onAuthFinishLoading: (state) => {
      state.loading = false
    },

    onAuthChecking: (state) => {
      state.auth = initialState.auth
      state.token = initialState.token
      state.status = 'checking'
      state.loading = true
    },

    onAuthLoginWithoutOTP: (state, { payload }) => {
      state.status = 'authenticated'
      state.user = payload.user
      state.token = payload.token
      state.auth = payload.auth
      state.loading = false
    },

    onAuthReceiveData: (state, { payload }) => {
      state.token = payload.token
      state.auth = payload.auth
      state.user = payload.user
      state.loading = false
    },

    onAuthLogin: (state, { payload }) => {
      state.status = 'authenticated'
      state.user = payload
      state.loading = false
    },

    onAuthLogout: (state) => {
      state.status = 'not-authenticated'
      state.user = initialState.user
      state.token = initialState.token
      state.auth = initialState.auth
      state.loading = false
    }
  }
})

export const { onAuthLoading, onAuthFinishLoading, onAuthChecking, onAuthLoginWithoutOTP, onAuthReceiveData, onAuthLogin, onAuthLogout } =
  authorizationSlice.actions
