import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { onFinishLoading } from 'app/features/authSlice'
import { onAuthLoading, onAuthLogin, onAuthLoginWithoutOTP, onAuthLogout, onAuthReceiveData } from 'app/features/authorizationSlice'
import { onGetModules, onOpenModal, revertAll } from 'app/features/ui.slice'
import { authLoginService, authLogoutService, authValidate } from 'services/auth.service'
import { userDataService } from 'services/user/user.services'
import axios from 'axios'

const ENV = process.env.REACT_APP_ENV
const API_ENDPOINT = process.env.REACT_APP_API_URL
const API_DEV_ENDPOINT = process.env.REACT_APP_API_URL_DEV
const BASE_URL = ENV === 'develop' ? API_DEV_ENDPOINT : API_ENDPOINT

export const useAuthentication = () => {
  const dispatch = useDispatch()
  const { auth } = useSelector((state) => state.authorization)

  const signIn = async (values) => {
    try {
      dispatch(onAuthLoading())
      const { data, status } = await authLoginService(values.email, values.password, 1)
      const decodedRefreshToken = jwtDecode(data.response.refreshToken)
      if (status === 200) {
        const headers = { Authorization: data.response.refreshToken }
        const { data: userResponse } = await axios.get(String(`${BASE_URL}/api/user`), { headers })
        const user = userResponse.response.user
        const modules = userResponse.response.modules
        const otpRequired = user.otpRequired
        const authData = { uuid: decodedRefreshToken.uuid, email: decodedRefreshToken.email, session: decodedRefreshToken.session }
        const refreshTokenExpTime = new Date(new Date().getTime() + 10 * 60 * 1000) // 10 Minutes
        if (!otpRequired) {
          Cookies.set('refreshToken', data.response.refreshToken, { expires: refreshTokenExpTime })
          dispatch(onAuthLoginWithoutOTP({ token: data.response, auth: authData, user }))
          dispatch(onGetModules(modules))
        } else {
          dispatch(onAuthReceiveData({ token: data.response, auth: authData, user }))
          dispatch(onGetModules(modules))
        }
      }
    } catch (err) {
      dispatch(onAuthLogout())
      dispatch(onFinishLoading())
      dispatch(onOpenModal({ message: err.response.data.message, type: 'ERROR' }))
      Cookies.remove('refreshToken')
    }
  }

  const signOut = async () => {
    await authLogoutService(auth.uuid)
    dispatch(onAuthLogout())
    dispatch(revertAll())
    Cookies.remove('refreshToken')
  }

  const checkAuthToken = async () => {
    const refreshToken = Cookies.get('refreshToken')
    try {
      if (refreshToken) {
        const { data } = await authValidate()
        const decodedRefreshToken = jwtDecode(data.response.refreshToken)
        const authData = { uuid: decodedRefreshToken.uuid, email: decodedRefreshToken.email, session: decodedRefreshToken.session }
        const { data: user } = await userDataService()
        Cookies.remove('refreshToken')
        const expTime = new Date(new Date().getTime() + 10 * 60 * 1000) // 10 Minutes
        Cookies.set('refreshToken', data.response.refreshToken, { expires: expTime })
        dispatch(onAuthReceiveData({ token: data.response, auth: authData, user: user.response.user }))
        dispatch(onAuthLogin(user.response.user))
        dispatch(onGetModules(user.response.modules))
        dispatch(onFinishLoading())
      } else {
        dispatch(onAuthLogout())
      }
    } catch (err) {
      dispatch(onAuthLogout())
      dispatch(revertAll())
      Cookies.remove('refreshToken')
    }
  }

  return { signIn, checkAuthToken, signOut }
}
