import { useSelector } from 'react-redux'
import { HiOutlineUser, HiOutlineLogout } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useAuthentication } from 'features/authentication/hooks/useAuthentication'
import { Avatar, Dropdown } from '../ui'
import withHeaderItem from '../../utils/hoc/withHeaderItem'

const dropdownItemList = []

// eslint-disable-next-line react/prop-types
export const UserDropdown = ({ className }) => {
  const { signOut } = useAuthentication()
  const { user } = useSelector((state) => state.authorization)
  const { name, organization } = user

  const UserAvatar = (
    <div className={classNames(className, 'flex items-center gap-2')}>
      <Avatar
        size={32}
        shape="circle"
        icon={<HiOutlineUser />}
      />
      <div className="hidden md:block">
        <div className="text-xs capitalize">{organization}</div>
        <div className="font-bold">{name}</div>
      </div>
    </div>
  )

  return (
    <div>
      <Dropdown
        menuStyle={{ minWidth: 240 }}
        renderTitle={UserAvatar}
        placement="bottom-end"
      >
        <Dropdown.Item variant="header">
          <div className="py-2 px-3 flex items-center gap-2">
            <Avatar
              shape="circle"
              icon={<HiOutlineUser />}
            />
            <div>
              <p className="font-bold text-gray-900 dark:text-gray-100">{name}</p>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item variant="divider" />
        {dropdownItemList.map((item) => (
          <Dropdown.Item
            eventKey={item.label}
            key={item.label}
            className="mb-1"
          >
            <Link
              className="flex gap-2 items-center"
              to={item.path}
            >
              <span className="text-xl opacity-50">{item.icon}</span>
              <span>{item.label}</span>
            </Link>
          </Dropdown.Item>
        ))}
        <Dropdown.Item
          onClick={() => signOut()}
          eventKey="Sign Out"
          className="gap-2"
        >
          <span className="text-xl opacity-50">
            <HiOutlineLogout />
          </span>
          <span>Sign Out</span>
        </Dropdown.Item>
      </Dropdown>
    </div>
  )
}

export default withHeaderItem(UserDropdown)
