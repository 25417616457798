/* eslint-disable multiline-ternary */
import { useSelector } from 'react-redux'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog } from 'components/ui'

export const InputFile = ({ label, serviceFunc, valuator }) => {
  const [file, setFile] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const { user } = useSelector((state) => state.authorization)
  const { organizationId, organizationType } = user

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setFile(event.dataTransfer.files[0])
  }

  const handleSubmitFile = async (e) => {
    setLoading(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', file)
    if (organizationType === 'colibri') {
      formData.append('valuatorId', valuator.value)
    } else {
      formData.append('valuatorId', organizationId)
    }
    try {
      const { data } = await serviceFunc(formData)
      setMessage(data.message)
      setType('SUCCESS')
      setModalOpen(true)
    } catch (err) {
      console.log(err)
      setMessage(err.response.data.message)
      setType('ERROR')
      setModalOpen(true)
    }
  }

  const onDialogOk = () => {
    setModalOpen(false)
    setFile(null)
    setLoading(false)
  }

  return (
    <>
      <form
        className="flex items-center justify-center w-full flex-col"
        onSubmit={(e) => handleSubmitFile(e)}
      >
        <label
          htmlFor={label}
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="flex flex-col items-center justify-center pt-5 pb-6"
          >
            <svg
              aria-hidden="true"
              className="w-10 h-10 mb-3 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            {!file ? (
              <div className="flex items-center justify-center flex-col">
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click para cargar</span> o arrastra y suelta
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">Carga archivo .xlsx (max 2MB)</p>
              </div>
            ) : (
              <p>{file.name}</p>
            )}
          </div>
          <input
            id={label}
            type="file"
            onClick={(e) => {
              e.target.value = null
            }}
            onChange={(e) => handleChangeFile(e)}
            className="sr-only"
          />
        </label>
        {file && (valuator || organizationType !== 'colibri') && (
          <Button
            className="mt-4 w-full"
            variant="solid"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            Cargar archivo
          </Button>
        )}
      </form>
      <Dialog
        isOpen={modalOpen}
        closable={false}
      >
        <h5 className="mb-4">{type === 'ERROR' ? 'Error' : 'Éxito'}</h5>
        <p>{message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </>
  )
}

InputFile.propTypes = {
  label: PropTypes.string.isRequired,
  serviceFunc: PropTypes.func.isRequired,
  valuator: PropTypes.object
}
