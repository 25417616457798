import { authBaseURL } from 'interceptors/auth.interceptor'
import { backendBaseURL } from 'interceptors/backend.interceptor'

export const userDataService = async () => {
  return await backendBaseURL.get('/api/user')
}

export const createAuthProviderUsers = async (users) => {
  return await authBaseURL.post('/api/user/multiple-register', users)
}

export const createAuthProviderUser = async (user) => {
  return await authBaseURL.post('/api/user/register', user)
}

export const activateUserService = async (email) => {
  return await backendBaseURL.post('/api/user/activate', email)
}
