import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Button, Dialog, Select } from 'components/ui'
import { AddCollateralCard } from 'features/assignCollateral/AddCollateralCard'
import { useAssignCollateral } from 'features/assignCollateral/hook/useAssignCollateral'
import {
  onFinishLoadAssignCollaterals,
  onGetAssignData,
  onGetAssignedEntityPortfolios,
  onResetAssignedPortfolio,
  onSelectAssignEntity,
  onSelectAssignPortfolio,
  onStartLoadAssignCollaterals
} from 'app/features/collateral.slice'
import { isinAdapter, portfolioAdapter, singleEntityAdapter } from 'adapters/core'
import { custodianAssociatedOrganizationPortfolios, getAssignedContractsService } from 'services/custodian.service'
// import { contractsAdapter } from 'adapters'

export const CollateralAssignView = () => {
  const { assign } = useSelector((state) => state.collateral)
  const { loading, disabled, entities, portfolios, selectedEntity, selectedPortfolio, collaterals } = assign
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()
  const { assignCollaterals, dialogIsOpen, dialogMessage, onDialogOk } = useAssignCollateral()

  useEffect(() => {
    const getData = async () => {
      dispatch(onStartLoadAssignCollaterals())
      const { data, status } = await getAssignedContractsService()
      if (status === 200) {
        const entities = singleEntityAdapter(data.response.organizations)
        const isin = isinAdapter(data.response.isin)
        dispatch(onGetAssignData({ entities, isin }))
        dispatch(onFinishLoadAssignCollaterals())
      }
    }
    getData()
  }, [])

  const onSelectEntity = async (value) => {
    const { key } = value
    dispatch(onSelectAssignEntity(key))
    const { data } = await custodianAssociatedOrganizationPortfolios(key)
    const portfolios = portfolioAdapter(data.response)
    // Remove duplicates
    const uniquePortfolios = portfolios.filter((elem, index) => {
      return portfolios.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(elem)) === index
    })

    dispatch(onGetAssignedEntityPortfolios(uniquePortfolios))
  }

  const handleChangePortfolio = async (value) => {
    dispatch(onSelectAssignPortfolio(value.NIT))
  }

  return (
    <PrivateLayout title="Asignar Colaterales">
      <div className="mb-8">
        <h4 className="mb-2">Organización</h4>
        <Select
          placeholder="Seleccione organización correspondiente"
          options={entities}
          isLoading={loading}
          isDisabled={disabled || !modulePermissions.hasPostPermission}
          onChange={onSelectEntity}
          value={selectedEntity}
        />
      </div>

      <div className="mb-8">
        <h4 className="mb-2">Portafolio</h4>
        <Select
          placeholder="Seleccione portafolio correspondiente"
          options={portfolios}
          isLoading={loading}
          isDisabled={disabled || !modulePermissions.hasPostPermission}
          onChange={handleChangePortfolio}
          value={selectedPortfolio}
        />
      </div>

      <AddCollateralCard />

      <Button
        className="mb-8"
        variant="solid"
        disabled={
          collaterals.length === 0 ||
          selectedEntity === null ||
          selectedPortfolio === null ||
          disabled ||
          !modulePermissions.hasPostPermission
        }
        onClick={assignCollaterals}
      >
        Asignar colaterales
      </Button>
      <Dialog
        isOpen={dialogIsOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{dialogMessage}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </PrivateLayout>
  )
}
