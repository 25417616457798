import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  disabled: false,
  moduleTypes: [
    { value: 1, label: 'Principal', key: 'parent' },
    { value: 2, label: 'Anidado', key: 'child' }
  ],
  filteredModules: null,
  data: {
    organizationTypes: null,
    permissions: null,
    modules: null
  },
  module: {
    organizationType: null,
    type: null,
    parent: null,
    visible: false,
    name: null,
    path: null,
    order: null,
    icon: null,
    permissions: [
      { method: 'GET', value: false },
      { method: 'POST', value: false },
      { method: 'PUT', value: false }
    ]
  }
}

export const revertAll = createAction('REVERT_ALL')

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    onModuleLoading: (state, { payload }) => {
      state.loading = payload
    },
    onModuleDisable: (state, { payload }) => {
      state.disabled = payload
    },
    onGetModulesData: (state, { payload }) => {
      state.data = payload
    },
    onChangeCreateModuleData: (state, { payload }) => {
      state.module = payload
    },
    onHandleOrganizationTypeData: (state, { payload }) => {
      state.filteredModules = null
      state.module.parent = null
      const organizationTypes = [...current(state.data.organizationTypes)]
      const organizationType = organizationTypes.find((el) => el.key === payload.key)
      state.module.organizationType = organizationType
      const modules = [...current(state.data.modules)]
      const filteredModules = modules.filter((el) => el.typeId === organizationType.value)
      state.filteredModules = filteredModules
    },
    onHandleModuleTypeData: (state, { payload }) => {
      state.module.parent = null
      const modulesTypes = [...current(state.moduleTypes)]
      const moduleType = modulesTypes.find((el) => el.key === payload.key)
      state.module.type = moduleType
    },
    onHandleModuleParentData: (state, { payload }) => {
      const modulesParent = [...current(state.data.modules)]
      const moduleParent = modulesParent.find((el) => el.key === payload.key)
      state.module.parent = moduleParent
    },
    onHandleModulePermissionsData: (state, { payload }) => {
      const permissions = [...current(state.module.permissions)]
      const permission = permissions.find((el) => el.method === payload.method)
      const otherPermissions = permissions.filter((el) => el.method !== payload.method)
      const newPermissions = [...otherPermissions, { method: permission.method, value: payload.value }]
      state.module.permissions = newPermissions
    },
    onResetCreateModuleData: (state, { payload }) => {
      state.filteredModules = null
      state.module = initialState.module
    }
  }
})

export const {
  onModuleLoading,
  onModuleDisable,
  onGetModulesData,
  onChangeCreateModuleData,
  onHandleOrganizationTypeData,
  onHandleModuleTypeData,
  onHandleModuleParentData,
  onHandleModulePermissionsData,
  onResetCreateModuleData
} = moduleSlice.actions
