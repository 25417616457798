import { useSelector } from 'react-redux'
import { Button, DatePicker } from 'components/ui'
import { ValuationChart } from 'features/dashboard/components'
import { useCalculateValuations } from 'features/dashboard/hooks'

export const VariationMargin = () => {
  const { dashboard } = useSelector((state) => state.ui)
  const { valuations, selectedPortfolio } = dashboard
  const { onChangeFinishValuationsDate, onChangeInitialValuationsDate, handleSendChartRequest } =
        useCalculateValuations()

  return (
    <div>
      <h4 className="mb-2">Margen de variación</h4>
      <div className="grid grid-cols-2 gap-x-8">
        <div>
          <p className="mb-2">Ingrese fecha inicial de histórico:</p>
          <DatePicker
            placeholder="Fecha inicial"
            value={new Date(valuations.initDate)}
            disabled={valuations.loading || !selectedPortfolio}
            onChange={onChangeInitialValuationsDate}
          />
        </div>
        <div>
          <p className="mb-2">Ingrese fecha final de histórico:</p>
          <DatePicker
            placeholder="Fecha final"
            value={new Date(valuations.endDate)}
            disabled={valuations.loading || !selectedPortfolio}
            onChange={onChangeFinishValuationsDate}
          />
        </div>
        <div className="col-span-2 flex items-center justify-center my-4">
          <Button
            variant="solid"
            disabled={!selectedPortfolio}
            loading={valuations.loading}
            onClick={handleSendChartRequest}
          >
                        Aplicar filtro
          </Button>
        </div>
      </div>
      <ValuationChart />
    </div>
  )
}
