import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  colibri: {
    data: []
  },
  bank: {
    data: [],
    totalGuarantees: 0,
    blockedGuarantees: 0,
    availableGuarantees: 0,
    receivedGuarantees: 0,
    marginCall: false
  },
  fund: {
    data: [],
    totalGuarantees: 0,
    blockedGuarantees: 0,
    availableGuarantees: 0,
    receivedGuarantees: 0,
    marginCall: false
  },
  custodian: {
    data: [],
    totalCollateral: 0,
    totalCollateralCustodian: 0
  },
  valuator: {
    contracts: [],
    guarantees: []
  }
}

export const revertAll = createAction('REVERT_ALL')

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    onStartDashboardLoading: (state) => {
      state.loading = true
    },
    onFinishDashboardLoading: (state) => {
      state.loading = false
    },

    onResetDashboardData: (state) => {
      state.loading = true
      state.colibri = initialState.colibri
      state.bank = initialState.bank
      state.fund = initialState.fund
      state.custodian = initialState.custodian
      state.valuator = initialState.valuator
    },

    // <---------- COLIBRI DASHBOARD ---------->

    onGetColibriDashboardData: (state, { payload }) => {
      state.colibri.data = payload
    },

    // <---------- BANK DASHBOARD ---------->

    onGetBankDashboardData: (state, { payload }) => {
      state.bank.data = payload.data
      state.bank.totalGuarantees = payload.totalGuarantees
      state.bank.blockedGuarantees = payload.blockedGuarantees
      state.bank.availableGuarantees = payload.availableGuarantees
      state.bank.receivedGuarantees = 0
      state.bank.marginCall = payload.marginCall
    },

    // <---------- FUND DASHBOARD ---------->

    onGetFundDashboardData: (state, { payload }) => {
      state.fund.data = payload.data
      state.fund.totalGuarantees = payload.totalGuarantees
      state.fund.blockedGuarantees = payload.blockedGuarantees
      state.fund.availableGuarantees = payload.availableGuarantees
      state.fund.receivedGuarantees = 0
      state.fund.marginCall = payload.marginCall
    },

    // <---------- CUSTODIAN DASHBOARD ---------->

    onGetCustodianDashboardData: (state, { payload }) => {
      state.custodian.data = payload.data
      state.custodian.totalCollateral = payload.totalCollateral
      state.custodian.totalCollateralCustodian = payload.totalCollateralCustodian
    },

    // <---------- VALUATOR DASHBOARD ---------->

    onGetContractsValuations: (state, { payload }) => {
      state.valuator.contracts = payload
    },
    onGetGuaranteeValuations: (state, { payload }) => {
      state.valuator.guarantees = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onStartDashboardLoading,
  onFinishDashboardLoading,
  onResetDashboardData,
  onGetColibriDashboardData,
  onGetBankDashboardData,
  onGetFundDashboardData,
  onGetCustodianDashboardData,
  onGetContractsValuations,
  onGetGuaranteeValuations
} = dashboardSlice.actions
