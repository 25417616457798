/* eslint-disable react/prop-types */
import React, { useMemo, useState, useCallback } from 'react'
import { Table, Input, Pagination, Spinner, Button } from 'components/ui'
import ReactJson from 'react-json-view'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination, useExpanded } from 'react-table'
import { matchSorter } from 'match-sorter'
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi'
import Loading from './Loading'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

function FilterInput ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, gotoPage, onFilter, loading }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)

  return (
    <div className="flex justify-end">
      <div className="flex items-center mb-4">
        <Input
          size="sm"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            // onChange(e.target.value)
          }}
          style={{ maxWidth: 180 }}
          placeholder={`${count} coincidencias...`}
        />
        <Button
          className="ml-4"
          variant="solid"
          loading={loading}
          onClick={() => onFilter(value)}
        >
          Buscar
        </Button>
      </div>
    </div>
  )
}

function SubRowAsync ({ row, rowProps, visibleColumns }) {
  return (
    <Tr>
      <Td />
      <Td colSpan={visibleColumns.length - 1}>
        <div className="flex justify-start">
          <ReactJson src={row.original.detail} theme="isotope" collapsed={false} />
        </div>
      </Td>
    </Tr>
  )
}

function fuzzyTextFilterFn (rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

const FilteringTable = ({ columns, data, renderRowSubComponent, loading, onClick, loading2, onFilter }) => {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    page,
    gotoPage,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0 },
      manualPagination: false
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useExpanded,
    usePagination
  )

  const onPaginationChange = (page) => {
    gotoPage(page - 1)
  }

  return (
    <>
      <FilterInput
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        onFilter={onFilter}
        loading={loading2}
      />
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup, i) => (
            <Tr
              key={i}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => (
                <Th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    <Sorter sort={column.isSortedDesc} />
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {loading && (
            <Tr>
              <Td
                className="text-center"
                colSpan={allColumns.length || 0}
              >
                <Loading loading={true} />
              </Td>
            </Tr>
          )}
          {page.map((row, i) => {
            prepareRow(row, i)
            const rowProps = row.getRowProps()
            return (
              <React.Fragment key={rowProps.key}>
                <Tr
                  key={i}
                  {...rowProps}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <Td
                        key={i}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                  {/* <input /> */}
                </Tr>
                {row.isExpanded &&
                renderRowSubComponent({
                  row,
                  rowProps,
                  visibleColumns
                })}
              </React.Fragment>
            )
          })}
          {page.length === 0 && !loading && (
            <Tr>
              <Td
                className="text-center"
                colSpan={allColumns.length || 0}
              >
                                No hay coincidencias!
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <div className="flex items-center justify-between mt-4 mb-4">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex + 1}
          total={rows.length}
          onChange={onPaginationChange}
        />
        <Button
          className="mb-8"
          variant="solid"
          loading={loading2}
          onClick={onClick}
        >
          Cargar más
        </Button>
      </div>

    </>
  )
}

const SubComponent = ({ columns, data, loading, onClick, loading2, onFilter }) => {
  const columnsFormmated = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded
              ? (
                <HiOutlineChevronDown />
              )
              : (
                <HiOutlineChevronRight />
              )}
          </span>
        ),
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null // No expander on an expanded row
      },
      ...columns
    ],
    []
  )

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  )

  return (
    <div>
      <FilteringTable
        columns={columnsFormmated}
        data={data}
        renderRowSubComponent={renderRowSubComponent}
        loading={loading}
        loading2={loading2}
        onClick={onClick}
        onFilter={onFilter}
      />
    </div>
  )
}

export default SubComponent
