import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { PasswordInput } from 'components/shared'
import { Button, FormContainer, FormItem, Input } from 'components/ui'
import { signInValidator } from 'helpers/validators'
import { useAuthentication } from 'features/authentication/hooks/useAuthentication'
import { onStartLoading } from 'app/features/authSlice'

const initialValues = {
  email: '',
  password: '',
  rememberMe: true
}

export const SignInForm = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.authorization)
  const { signIn } = useAuthentication()

  const onSignIn = async (values, setSubmitting) => {
    dispatch(onStartLoading())
    await signIn({ ...values })
    setSubmitting(loading)
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signInValidator}
      onSubmit={(values, { setSubmitting }) => {
        onSignIn(values, setSubmitting)
      }}
    >
      {({ touched, errors, isSubmitting }) => (
        <Form className="mt-8">
          <FormContainer>
            <FormItem
              label="Correo electrónico"
              invalid={errors.email && touched.email}
              errorMessage={errors.email}
            >
              <Field
                type="email"
                autoComplete="off"
                name="email"
                placeholder="Ingresa tu correo electrónico"
                component={Input}
                disabled={isSubmitting}
              />
            </FormItem>
            <FormItem
              className="mb-4"
              label="Contraseña"
              invalid={errors.password && touched.password}
              errorMessage={errors.password}
            >
              <Field
                autoComplete="off"
                name="password"
                placeholder="Ingresa tu contraseña"
                component={PasswordInput}
                disabled={isSubmitting}
              />
            </FormItem>
            <div className="flex items-center justify-end mb-4">
              <Link to={'/forgot-password'}>¿Olvidaste tu contraseña?</Link>
            </div>
            <Button
              block
              loading={loading}
              variant="solid"
              type="submit"
            >
              {loading ? 'Validando' : 'Ingresar'}
            </Button>
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
