/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */
import { useMemo, useState } from 'react'
import { Table, Input, Pagination } from 'components/ui'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { matchSorter } from 'match-sorter'
import { TableFile } from './TableFile'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

function FilterInput({ preGlobalFilteredRows, globalFilter, setGlobalFilter, gotoPage }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="flex justify-end">
      <div className="flex items-center mb-4">
        <span className="mr-2">Buscar:</span>
        <Input
          size="sm"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          style={{ maxWidth: 180 }}
        />
      </div>
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = (val) => !val

export const FilteringTableWithInput = ({ columns, data }) => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    allColumns,
    page,
    gotoPage,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0 },
      manualPagination: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const onPaginationChange = (page) => {
    gotoPage(page - 1)
  }

  return (
    <>
      <FilterInput
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup, i) => (
            <Tr
              key={i}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, i) => (
                <Th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <span>
                    <Sorter sort={column.isSortedDesc} />
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row, i)
            return (
              <Tr
                key={i}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <TableFile
                      key={index}
                      cell={cell}
                      index={index}
                      column={row}
                      row={i}
                    />
                  )
                })}
              </Tr>
            )
          })}
          {page.length === 0 && (
            <Tr>
              <Td
                className="text-center"
                colSpan={allColumns.length || 0}
              >
                No se han encontrado resultados
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <div className="flex items-center justify-between mt-4">
        <Pagination
          pageSize={pageSize}
          currentPage={pageIndex + 1}
          total={rows.length}
          onChange={onPaginationChange}
        />
      </div>
    </>
  )
}
