import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FilterDataTableWithLink } from 'components/dataTable'
import TabContent from 'components/ui/Tabs/TabContent'
import contractValuations from 'features/valuator/dashboard/json/contractValuations.json'
import { onFinishDashboardLoading, onGetContractsValuations, onStartDashboardLoading } from 'app/features/dashboard.slice'
import { contractValuationsAdapter } from 'adapters/contractValuations'
import { getContractValuations } from 'services/valuator.service'

export const ContractValuationsTable = ({ currentTab }) => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationId } = user
  const { valuator } = useSelector((state) => state.dashboard)
  const { contracts } = valuator
  const dispatch = useDispatch()

  useEffect(() => {
    const getValuations = async () => {
      if (currentTab === 'contract_valuation') {
        dispatch(onStartDashboardLoading())
        const { data, status } = await getContractValuations(organizationId)
        if (status === 200) {
          const result = contractValuationsAdapter(data)
          dispatch(onGetContractsValuations(result))
        }
        dispatch(onFinishDashboardLoading())
      }
    }
    getValuations()
  }, [currentTab])

  return (
    <TabContent value="contract_valuation">
      <FilterDataTableWithLink
        columns={contractValuations}
        data={contracts}
      />
    </TabContent>
  )
}

ContractValuationsTable.propTypes = {
  currentTab: PropTypes.string.isRequired
}
