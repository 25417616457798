/* eslint-disable multiline-ternary */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Spinner } from 'components/ui'
import { PublicRoutes } from 'routes/publicRoutes'
import { PrivateRoutes } from 'routes/privateRoutes'
import { useAuthentication } from 'features/authentication/hooks/useAuthentication'

export const AppRouter = () => {
  const { status } = useSelector((state) => state.authorization)
  const { pathname } = useLocation()
  const { checkAuthToken } = useAuthentication()

  useEffect(() => {
    checkAuthToken()
  }, [pathname])

  if (status === 'checking') {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner
          className="animate-spin"
          size={64}
        />
      </div>
    )
  }

  return (
    <Routes>
      {status === 'authenticated' ? (
        <Route
          path="/*"
          element={<PrivateRoutes />}
        />
      ) : (
        <Route
          path="/*"
          element={<PublicRoutes />}
        />
      )}
    </Routes>
  )
}
