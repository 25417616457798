import { Card, DatePicker, Input, Select, InputPercentage } from 'components/ui'
import { useCreateContract } from 'hooks/useCreateContract'
import { useSelector } from 'react-redux'

export const PartContractCard = () => {
  const { contract, data } = useSelector((state) => state.contract)
  const { loading, partContract } = contract
  const { modulePermissions } = useSelector((state) => state.permission)

  const {
    onDatePickerChange,
    handleSelectValuator,
    handleSelectCustodianA,
    handleSelectCustodianB,
    handleSelectCurrency,
    handleChangeInitialMargin,
    handleChangeIndependentAmount,
    handleChangeThreshold,
    // onChangeEndingContractDate,
    handleChangeMinTransferAmount
  } = useCreateContract()

  return (
    <Card className="mb-8">
      <h3 className="mb-4">Contrato</h3>
      <div className="mb-8">
        <h5 className="mb-2">Fecha CSA</h5>
        <p className="mb-2">Ingrese fecha CSA correspondiente:</p>
        <DatePicker
          placeholder="Fecha CSA"
          value={partContract.date}
          disabled={!modulePermissions.hasPostPermission}
          onChange={onDatePickerChange}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente de Valoración</h5>
        <p className="mb-3">Seleccione agente de valoración correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.valuators}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partContract.valuator}
          onChange={handleSelectValuator}
        />
      </div>

      <div className="mb-8">
        <h4 className="mb-2">Agente de Custodia Parte A</h4>
        <p className="mb-2">Seleccione agente de custodia de la parte A correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.custodians}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partContract.custodianA}
          onChange={handleSelectCustodianA}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente de Custodia Parte B</h5>
        <p className="mb-2">Seleccione agente de custodia de la parte B correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.custodians}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partContract.custodianB}
          onChange={handleSelectCustodianB}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Moneda</h5>
        <p className="mb-2">Seleccione moneda correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.currencies}
          isLoading={loading}
          isDisabled={!modulePermissions.hasPostPermission}
          value={partContract.currency}
          onChange={handleSelectCurrency}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Margen Inicial</h5>
        <p className="mb-2">Ingrese margen inicial porcentual correspondiente:</p>
        <InputPercentage
          placeholder="Margen Inicial"
          disabled={!modulePermissions.hasPostPermission}
          onChange={(e) => handleChangeInitialMargin(e)}
          value={partContract.initialMargin}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Monto Independiente</h5>
        <p className="mb-2">Ingrese monto independiente porcentual correspondiente:</p>
        <InputPercentage
          placeholder="Monto Independiente"
          disabled={!modulePermissions.hasPostPermission}
          onChange={(e) => handleChangeIndependentAmount(e)}
          value={partContract.independentAmount}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Umbral/ Threshold</h5>
        <p className="mb-3">Ingrese umbral correspondiente:</p>
        <Input
          placeholder="Umbral/ Threshold"
          type="number"
          disabled={!modulePermissions.hasPostPermission}
          onChange={handleChangeThreshold}
          value={partContract.threshold}
        />
      </div>

      <div>
        <h5 className="mb-2">Monto Mínimo de Transferencia</h5>
        <p className="mb-3">Ingrese monto mínimo de transferencia correspondiente:</p>
        <Input
          placeholder="Monto Mínimo de Transferencia"
          type="number"
          disabled={!modulePermissions.hasPostPermission}
          onChange={handleChangeMinTransferAmount}
          value={partContract.minTransferAmount}
        />
      </div>
    </Card>
  )
}
