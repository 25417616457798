import { authBaseURL } from 'interceptors/auth.interceptor'
import { backendBaseURL } from 'interceptors/backend.interceptor'

export const colibriDashboardDataService = async (organizationId) => {
  return await backendBaseURL.get('/api/v2/colibri/dashboard')
}

export const colibriValorateContractsService = async () => {
  return await backendBaseURL.post('/api/valuations/contracts/valorate')
}

export const colibriValorateGuaranteesService = async () => {
  return await backendBaseURL.post('/api/valuations/collaterals/valorate')
}

export const colibriGetAllValuators = async () => {
  return await backendBaseURL.get('/api/v2/colibri/valuators')
}

export const getDataCreateOrganization = async () => {
  return await backendBaseURL.get('/api/v2/colibri/data-organization')
}

export const getAllOrganizationList = async () => {
  return await backendBaseURL.get('/api/v2/colibri/organizations')
}

export const getOrganizationDetail = async (key) => {
  return await backendBaseURL.get(`/api/v2/colibri/organization/${key}`)
}

export const createOrganizationService = async (entity, portfolios, users) => {
  return await backendBaseURL.post('/api/v2/colibri/create-organization', { entity, portfolios, users })
}
export const createUserService = async (user) => {
  return await backendBaseURL.post('/api/v2/colibri/create-user', user)
}
export const createPortfolioService = async (portfolio) => {
  return await backendBaseURL.post('/api/v2/colibri/create-portfolio', portfolio)
}

export const updateOrganizationService = async (data) => {
  return await backendBaseURL.put('/api/v2/colibri/organization/update', data)
}
export const updateUserService = async (data) => {
  return await backendBaseURL.put('/api/v2/colibri/organization/user/update', data)
}

export const updatePortfolioService = async (data) => {
  return await backendBaseURL.put('/api/v2/colibri/organization/portfolio/update', data)
}

export const getAllValuators = async () => {
  return await backendBaseURL.get('/api/v2/colibri/valuators')
}

export const updateAuthUserService = async (user) => {
  return await authBaseURL.put('/api/user/update', user)
}
