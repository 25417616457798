import { formatToCurrency } from 'helpers/formatToCurrency'

export const assignedCollateralsAdapter = (data) => {
  return data.map((item) => {
    const formattedCollateralReceived = formatToCurrency(item.Collateral_Received, 'es-CO', 'COP')
    const formattedFaceValue = formatToCurrency(item.Face_Value, 'es-CO', 'COP')
    const date = `${new Date(item.Date).toLocaleDateString()} - ${new Date(item.Date).toLocaleTimeString()}`

    const guaranteesTypes = item.Guarantes_Types.map((guarantee, i) => {
      const formattedAmount = formatToCurrency(
        guarantee.Amount,
        'es-CO',
        guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
      )
      const formattedCollateral = formatToCurrency(
        guarantee.Collateral,
        'es-CO',
        guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
      )
      const formattedInitDate = `${new Date(guarantee.Init_Date).toLocaleDateString()} - ${new Date(
        guarantee.Init_Date
      ).toLocaleTimeString()}`
      const formattedUpdateDate = `${new Date(guarantee.Update_Date).toLocaleDateString()} - ${new Date(
        guarantee.Update_Date
      ).toLocaleTimeString()}`

      return {
        amount: formattedAmount,
        collateral: formattedCollateral,
        initDate: formattedInitDate,
        updateDate: formattedUpdateDate,
        haircut: `${guarantee.Haircut} %`,
        guaranteeTypeId: guarantee.Guarantes_Type_Id,
        guaranteeName: guarantee.Guarantee_Name,
        guaranteeType: guarantee.Guarante_Type,
        transactionType: guarantee.Transaction_Type,
        collateralReceived: guarantee.Collateral_Received,
        collateralDelivered: guarantee.Collateral_Delivered
      }
    })
    return {
      collateralReceived: item.Collateral_Received,
      formattedCollateralReceived,
      custodianId: item.Custodian_Id,
      custodianName: item.Custodian_Name,
      entityId: item.Entity_Id,
      entityName: item.Entity_Name,
      entityType: item.Entity_Type,
      formattedFaceValue,
      faceValue: item.Face_Value,
      guaranteeRegisterId: item.Guarantee_Register_Id,
      guaranteesTypes,
      portfolioName: item.Portfolio_name,
      portfolioNIT: item.Portfolio_nit,
      portfolioLEI: item.Portfolio_lei,
      date,
      marginCall: item.Margin_Call
    }
  })
}
