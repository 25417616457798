import axios from 'axios'
import Cookies from 'js-cookie'

const ENV = process.env.REACT_APP_ENV
const API_ENDPOINT = process.env.REACT_APP_API_URL
const API_DEV_ENDPOINT = process.env.REACT_APP_API_URL_DEV

export const backendBaseURL = axios.create({
  baseURL: ENV === 'develop' ? API_DEV_ENDPOINT : API_ENDPOINT
})

backendBaseURL.interceptors.request.use(
  (config) => {
    const refreshToken = Cookies.get('refreshToken')
    if (refreshToken) {
      config.headers.Authorization = refreshToken
    }
    return config
  },
  (error) => Promise.reject(error)
)
