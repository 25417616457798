export const rolesAdapter = (data) => {
  return data.map((el) => ({
    id: el.id,
    value: el.id,
    label: el.label,
    key: el.key,
    status: el.status?.key,
    statusLabel: el.status?.id === 1 ? 'Activo' : 'Inactivo'
  }))
}
