/* eslint-disable indent */
/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useMemo, useState, useRef, useEffect } from 'react'
import { Table, Input, Pagination, Checkbox, Button } from 'components/ui'

import {
    useTable,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    usePagination,
    useRowSelect
} from 'react-table'
import { matchSorter } from 'match-sorter'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, onChange, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <Checkbox
            ref={resolvedRef}
            onChange={(_, e) => onChange(e)}
            {...rest}
        />
    )
})

function FilterInput ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, gotoPage }) {
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined)
        // Actualizar paginación aquí
    }, 200)

    return (
        <div className="flex justify-end">
            <div className="flex items-center mb-4">
                <span className="mr-2">Buscar:</span>
                <Input
                    size="sm"
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value)
                        onChange(e.target.value)
                    }}
                    style={{ maxWidth: 180 }}
                    // placeholder={`${count} coincidencias...`}
                />
            </div>
        </div>
    )
}

function fuzzyTextFilterFn (rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

export const DropDataTable = ({ columns, data, onDropSelectedItems }) => {
    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
                        : true
                })
            }
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        allColumns,
        page,
        gotoPage,
        state: { pageIndex, pageSize, globalFilter }
    } = useTable(
        {
            columns,
            data,
            // defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: { pageIndex: 0 },
            manualPagination: false
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox
                                {...row.getToggleRowSelectedProps()}
                                onChange={(e) => {
                                    row.getToggleRowSelectedProps().onChange(e)
                                }}
                            />
                        </div>
                    )
                },
                ...columns
            ])
        }
    )

    const onPaginationChange = (page) => {
        gotoPage(page - 1)
    }

    return (
        <>
            <FilterInput
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            <Table {...getTableProps()}>
                <THead>
                    {headerGroups.map((headerGroup, i) => (
                        <Tr
                            key={i}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    <Th
                                        key={i}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {typeof column.render('Header') === 'string' ||
                                            column.render('Header') instanceof String ? (
                                                <Sorter sort={column.isSortedDesc} />
                                            ) : (
                                                <div></div>
                                            )}
                                        </span>
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </THead>
                <TBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <Tr
                                key={i}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell, i) => {
                                    return (
                                        <Td
                                            key={i}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                    {page.length === 0 && (
                        <Tr>
                            <Td
                                className="text-center"
                                colSpan={allColumns.length || 0}
                            >
                                No se han encontrado datos
                            </Td>
                        </Tr>
                    )}
                </TBody>
            </Table>
            <div className="flex items-center justify-between mt-4">
                <Button
                    variant="solid"
                    disabled={!(selectedFlatRows.length > 0)}
                    onClick={(e) => {
                        onDropSelectedItems(e, selectedFlatRows)
                    }}
                >
                    Eliminar
                </Button>
                <Pagination
                    pageSize={pageSize}
                    currentPage={pageIndex + 1}
                    total={rows.length}
                    onChange={onPaginationChange}
                />
            </div>
        </>
    )
}
