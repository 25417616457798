import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onOpenModal } from 'app/features/ui.slice'
import { colibriValorateContractsService } from 'services/colibri.service'

export const useContractValoration = () => {
  const dispatch = useDispatch()
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [buttonIsLoading, setButtonIsLoading] = useState(false)

  const onDialogOk = () => {
    setDialogIsOpen(false)
    setButtonIsLoading(false)
  }

  const makeTransaction = async (e) => {
    setButtonIsLoading(true)
    try {
      const { data } = await colibriValorateContractsService()
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
    } catch (err) {
      console.log(err)
      dispatch(onOpenModal({ message: err.response?.data.message ?? 'Ocurrió un error.', type: 'ERROR' }))
    }
    setButtonIsLoading(false)
  }

  return {
    makeTransaction,
    onDialogOk,
    dialogIsOpen,
    buttonIsLoading
  }
}
