/* eslint-disable multiline-ternary */
import PropTypes from 'prop-types'
import Td from 'components/ui/Table/Td'
import { Button } from 'components/ui'
import { getDownloadSignedLink } from 'services/valuator.service'
import { useState } from 'react'

export const LinkRow = ({ cell, column, row, index }) => {
  const [link, setLink] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleGetSignedLink = async () => {
    const { data } = await getDownloadSignedLink(column.original.fileUrl)
    setLink(data.response)
  }

  const onClearLink = () => {
    setLoading(true)
    setTimeout(() => {
      setLink(null)
      setLoading(false)
    }, 1000)
  }

  return (
    <Td {...cell.getCellProps()}>
      {index === column.cells.length - 1 ? (
        link === null ? (
          <Button onClick={handleGetSignedLink}>Solicitar descarga</Button>
        ) : (
          <a
            href={link}
            download={true}
          >
            <Button
              variant="solid"
              onClick={onClearLink}
              disabled={loading}
            >
                            Descargar archivo
            </Button>
          </a>
        )
      ) : (
        cell.render('Cell')
      )}
    </Td>
  )
}

LinkRow.propTypes = {
  cell: PropTypes.any.isRequired,
  column: PropTypes.any.isRequired,
  row: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
}
