import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Button, Card, Input, Select } from 'components/ui'
import { useEffect } from 'react'
import { onOpenModal } from 'app/features/ui.slice'
import { createModuleService, getCreateModuleData } from 'services/organization-service'
import {
  onGetModulesData,
  onModuleLoading,
  onModuleDisable,
  onHandleOrganizationTypeData,
  onHandleModuleTypeData,
  onHandleModuleParentData,
  onChangeCreateModuleData,
  onHandleModulePermissionsData,
  onResetCreateModuleData
} from 'app/features/module-slice'
import { parentModuleListAdapter } from 'adapters/organization/parentModuleList-adapter'

export const ModulesView = () => {
  const { modulePermissions } = useSelector((state) => state.permission)
  const { data, filteredModules, module, moduleTypes, loading, disabled } = useSelector((state) => state.module)
  const { organizationTypes, permissions } = data
  const dispatch = useDispatch()

  useEffect(() => {
    const getData = async () => {
      dispatch(onModuleLoading(true))
      dispatch(onModuleDisable(true))
      try {
        const { data } = await getCreateModuleData()
        const modules = parentModuleListAdapter(data.response.modules)
        dispatch(onGetModulesData({ organizationTypes: data.response.types, permissions: data.response.permissions, modules }))
      } catch (err) {
        dispatch(onOpenModal({ message: err.response.data.message ?? 'Fallo en la recepción de datos para creación de módulos.', type: 'ERROR' }))
      }
      dispatch(onModuleLoading(false))
      dispatch(onModuleDisable(false))
    }
    getData()
  }, [])

  const handleCreateModule = async () => {
    try {
      dispatch(onModuleDisable(true))
      const { data } = await createModuleService(module)
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
      dispatch(onResetCreateModuleData())
    } catch (err) {
      dispatch(onOpenModal({ message: err.response.data.message ?? 'Fallo al crear módulo.', type: 'ERROR' }))
    }
    dispatch(onModuleDisable(false))
  }

  return (
    <PrivateLayout title="Módulos">
      <Card className="mb-8">
        <h4 className="mb-8">Añadir nuevo módulo</h4>

        <div className="mb-4">
          <h5 className="mb-2">Tipo de organización</h5>
          <Select
            placeholder="Seleccione tipo de organización correspondiente"
            options={organizationTypes}
            isLoading={loading}
            isDisabled={!modulePermissions.hasPostPermission || disabled}
            value={module.organizationType}
            onChange={(value) => dispatch(onHandleOrganizationTypeData(value))}
          />
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Tipo de módulo</h5>
          <Select
            placeholder="Seleccione tipo de módulo correspondiente"
            options={moduleTypes}
            isLoading={loading}
            isDisabled={!modulePermissions.hasPostPermission || disabled}
            value={module.type}
            onChange={(value) => dispatch(onHandleModuleTypeData(value))}
          />
        </div>
        {module.type?.key === 'child' && (
          <Card className="mb-4">
            <div>
              <h5 className="mb-2">Módulo principal</h5>
              <Select
                placeholder="Seleccione módulo principal correspondiente"
                options={filteredModules}
                isLoading={loading}
                isDisabled={!modulePermissions.hasPostPermission || disabled}
                value={module.parent}
                onChange={(value) => dispatch(onHandleModuleParentData(value))}
              />
            </div>
          </Card>
        )}

        <div className="mb-4">
          <h5 className="mb-2">Visibilidad</h5>
          <div className="w-full flex items-center justify-between border p-3 rounded-md">
            <p>Menú lateral</p>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                id="intern-route"
                name="intern-route"
                disabled={!modulePermissions.hasPostPermission || disabled}
                onChange={(e) => dispatch(onChangeCreateModuleData({ ...module, visible: e.target.checked }))}
                value={module.visible}
              />
              <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
            </label>
          </div>
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Nombre</h5>
          <Input
            placeholder="Ingrese nombre de módulo"
            type="text"
            name="name"
            disabled={!modulePermissions.hasPostPermission || disabled}
            onChange={(e) => dispatch(onChangeCreateModuleData({ ...module, name: e.target.value }))}
            value={module.name}
          />
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Ruta</h5>
          <Input
            placeholder="Ingrese ruta de módulo"
            type="text"
            name="path"
            disabled={!modulePermissions.hasPostPermission || disabled}
            onChange={(e) => dispatch(onChangeCreateModuleData({ ...module, path: e.target.value }))}
            value={module.path}
          />
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Orden de jerarquía</h5>
          <Input
            placeholder="Ingrese orden de jerarquía de módulo"
            type="number"
            name="order"
            disabled={!modulePermissions.hasPostPermission || disabled}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => dispatch(onChangeCreateModuleData({ ...module, order: e.target.value }))}
            value={module.order}
          />
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Icono</h5>
          <Input
            placeholder="Ingrese nombre de icono"
            type="text"
            name="icon"
            disabled={!modulePermissions.hasPostPermission || disabled}
            onChange={(e) => dispatch(onChangeCreateModuleData({ ...module, icon: e.target.value }))}
            value={module.icon}
          />
        </div>

        <Card className="mb-4">
          <h4 className="mb-4">Permisos de ruta</h4>
          <div className="w-full space-y-4">
            {permissions?.map((item) => (
              <div
                className="w-full flex items-center justify-between border border-gray-600 p-3 rounded-md"
                key={item.method}
              >
                <div>
                  <p className="text-base">{item.name}</p>
                  <span className="text-xs">Permiso: {item.method}</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    disabled={!modulePermissions.hasPostPermission || disabled}
                    className="sr-only peer"
                    id={`permission-create-${item.key}-${item.method}`}
                    onChange={({ target }) => dispatch(onHandleModulePermissionsData({ method: item.method, value: target.checked }))}
                  />
                  <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
                </label>
              </div>
            ))}
          </div>
        </Card>

        <Button
          variant="solid"
          onClick={() => handleCreateModule()}
          disabled={!modulePermissions.hasPostPermission || disabled}
        >
          {disabled ? 'Creando módulo' : 'Crear módulo'}
        </Button>
      </Card>
    </PrivateLayout>
  )
}
