import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'

import ColibriDashboardView from 'views/private/colibri/ColibriDashboardView'
import ColibriNettingView from 'views/private/colibri/ColibriNettingView'
import ColibriLogView from 'views/private/colibri/ColibriLogView'
import { OrganizationDetailView, OrganizationCreateView } from 'views/private/colibri/organizations'

import { DerivativesView, DashboardView, PortfolioMovementsView, DefaultView } from 'views/private/common'
import { AddContractView, CollateralHistoryView, DerivativeDetailView } from 'views/private/bank'
import { CustodianDashboardView, CollateralAssignView, CollateralWithdrawnView } from 'views/private/custodian'

import ValuatorDashboard from 'views/private/valuator/ValuatorDashboard'
import LoadExcelFilesView from 'views/private/LoadExcelFilesView'
import { DerivativeDetail } from 'views/private/fund'
import { ModulesView, OrganizationUsersView, OrganizationsListView, PermissionsView, RolesView } from 'views/private/colibri'

export const PrivateRoutes = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationType } = user
  return (
    <Routes>
      {organizationType === 'colibri' && (
        <>
          <Route
            path="/colibri/dashboard"
            element={<ColibriDashboardView />}
          />
          <Route
            path="/colibri/start-netting"
            element={<ColibriNettingView />}
          />
          <Route
            path="/colibri/load-excel"
            element={<LoadExcelFilesView />}
          />
          <Route
            path="/colibri/organizations"
            element={<OrganizationsListView />}
          />
          <Route
            path="/colibri/detail/organization/:key"
            element={<OrganizationDetailView />}
          />
          <Route
            path="/colibri/create-organization"
            element={<OrganizationCreateView />}
          />
          <Route
            path="/logs-history"
            element={<ColibriLogView />}
          />
          <Route
            path="/colibri/users/organization"
            element={<OrganizationUsersView />}
          />
          <Route
            path="/colibri/roles"
            element={<RolesView />}
          />
          <Route
            path="/colibri/permissions/:key"
            element={<PermissionsView />}
          />
          <Route
            path="/colibri/modules"
            element={<ModulesView />}
          />
          <Route
            path="/*"
            element={<Navigate to="/" />}
          />
        </>
      )}

      {organizationType === 'bank' && (
        <>
          <Route
            path="/bank/dashboard"
            element={<DashboardView />}
          />
          <Route
            path="/bank/create/contract"
            element={<AddContractView />}
          />
          <Route
            path="/bank/contracts"
            element={<DerivativesView />}
          />
          <Route
            path="/bank/contract/:id"
            element={<DerivativeDetailView />}
          />
          <Route
            path="/bank/portfolios"
            element={<PortfolioMovementsView />}
          />
          <Route
            path="/bank/portfolio/history/:id"
            element={<CollateralHistoryView />}
          />
          <Route
            path="/logs-history"
            element={<ColibriLogView />}
          />
          <Route
            path="/*"
            element={<Navigate to="/" />}
          />
        </>
      )}

      {organizationType === 'fund' && (
        <>
          <Route
            path="/fund/dashboard"
            element={<DashboardView />}
          />
          <Route
            path="/fund/contracts"
            element={<DerivativesView />}
          />
          <Route
            path="/fund/contract/:id"
            element={<DerivativeDetail />}
          />
          <Route
            path="/fund/portfolios"
            element={<PortfolioMovementsView />}
          />
          <Route
            path="/fund/portfolio/history/:id"
            element={<CollateralHistoryView />}
          />
          <Route
            path="/logs-history"
            element={<ColibriLogView />}
          />
          <Route
            path="/*"
            element={<Navigate to="/" />}
          />
        </>
      )}

      {organizationType === 'custodian' && (
        <>
          <Route
            path="/custodian/dashboard"
            element={<CustodianDashboardView />}
          />
          <Route
            path="/custodian/collaterals/assign"
            element={<CollateralAssignView />}
          />
          <Route
            path="/custodian/collaterals/withdrawn"
            element={<CollateralWithdrawnView />}
          />
          <Route
            path="/logs-history"
            element={<ColibriLogView />}
          />
          <Route
            path="/*"
            element={<Navigate to="/" />}
          />
        </>
      )}

      {organizationType === 'valuator' && (
        <>
          <Route
            path="/valuator/dashboard"
            element={<ValuatorDashboard />}
          />
          <Route
            path="/valuator/load-excel"
            element={<LoadExcelFilesView />}
          />
          <Route
            path="/logs-history"
            element={<ColibriLogView />}
          />
          <Route
            path="/*"
            element={<Navigate to="/" />}
          />
        </>
      )}

      <Route
        path="/"
        element={<DefaultView />}
      />
    </Routes>
  )
}
