import Logo from 'components/template/Logo'

const SideContainer = () => {
  return (
    <div
      className="h-screen bg-no-repeat bg-cover py-6 px-16 flex-col justify-between hidden lg:flex"
      style={{
        // eslint-disable-next-line quotes
        backgroundImage: "url('/img/others/auth-side-bg.jpg')"
      }}
    >
      <Logo mode="dark" />
      <span className="text-white">
                Copyright &copy; {`${new Date().getFullYear()}`} <span className="font-semibold">MarketXM</span>
      </span>
    </div>
  )
}

export default SideContainer
