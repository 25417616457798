import { formatToCurrency } from 'helpers/formatToCurrency'

export const guaranteeValuationsAdapter = (data) => {
  return data.map((item) => {
    const formattedMarketValue = formatToCurrency(item.market_value, 'es-CO', 'COP')
    const formattedFacialValue = formatToCurrency(item.facial_value, 'es-CO', 'COP')
    return {
      date: new Date(item.date).toLocaleDateString('es-ES'),
      custodianNIT: item.custodian_nit,
      portfolioLEI: item.portfolio_lei,
      isin: item.isin,
      facialValue: formattedFacialValue,
      marketValue: formattedMarketValue,
      uploadAt: `${new Date(item.upload_at).toLocaleDateString('es-ES')} - ${new Date(
        item.upload_at
      ).toLocaleTimeString('es-ES')}`,
      fileUrl: item.file_url,
      createdUser: item.createdUser.email,
      assignedUser: item.assignedUser.email
    }
  })
}
