import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/display-name
const Tr = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { children, asElement: Component, className, ...rest } = props

  const trClass = classNames(Component !== 'tr' && 'tr', className)

  return (
    <Component
      className={trClass}
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  )
})

Tr.propTypes = {
  asElement: PropTypes.string
}

Tr.defaultProps = {
  asElement: 'tr'
}

export default Tr
