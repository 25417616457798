import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Button } from 'components/ui'

const ENV = process.env.REACT_APP_ENV
const API_ENDPOINT = process.env.REACT_APP_API_URL
const API_DEV_ENDPOINT = process.env.REACT_APP_API_URL_DEV
const BASE_URL = ENV === 'develop' ? API_DEV_ENDPOINT : API_ENDPOINT
export const OTPImageGenerator = ({ setStep }) => {
  const [imgSrc, setImgSrc] = useState(null)
  const { token } = useSelector((state) => state.authorization)
  const { refreshToken } = token

  useEffect(() => {
    const getData = async () => {
      const headers = { Authorization: refreshToken }
      const { data } = await axios.get(String(`${BASE_URL}/api/auth/generate-qr`), { headers })
      setImgSrc(data.response)
    }
    getData()
  }, [])

  const handleActivateOTP = async () => {
    const headers = { Authorization: refreshToken }
    await axios.get(String(`${BASE_URL}/api/auth/activate-otp`), { headers })
    setStep('insert-code')
  }

  return (
    <div>
      <div className="my-8 flex items-center justify-center">
        {imgSrc && (
          <img
            src={imgSrc}
            alt="marketXM-qr"
          />
        )}
      </div>
      <Button
        block
        disabled={!imgSrc}
        variant="solid"
        onClick={() => handleActivateOTP()}
      >
        Activar OTP
      </Button>
    </div>
  )
}

OTPImageGenerator.propTypes = {
  setStep: PropTypes.func.isRequired
}
