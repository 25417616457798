import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PrivateLayout } from 'layout/private'
import { SelectDataTable } from 'components/dataTable'
import collateralMovementsHeaders from 'features/collaterals/json/CollateralMovementsTable.json'
import { onFinishLoadBlockchainData, onGetCollateralsFromBlockchain, onStartLoadBlockchainData } from 'app/features/collateral.slice'
import { collateralsAdapter } from 'adapters/collaterals.adapter'
import { getAssignedCollateralsByEntityId } from 'services/collateral.service'

export const PortfolioMovementsView = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationKey, organizationType } = user
  const { blockchain } = useSelector((state) => state.collateral)
  const { data } = blockchain
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getData = async () => {
      dispatch(onStartLoadBlockchainData())
      try {
        const { data, status } = await getAssignedCollateralsByEntityId(organizationKey)
        if (status === 200) {
          const formattedCollaterals = collateralsAdapter(data.response)
          dispatch(onGetCollateralsFromBlockchain(formattedCollaterals))
        }
      } catch (err) {
        console.log(err)
      }

      dispatch(onFinishLoadBlockchainData())
    }
    getData()
  }, [])

  const onSelectItem = (e, row) => {
    if (organizationType === 'bank') {
      navigate(`/bank/portfolio/history/${row.original.guaranteeRegisterId}`)
    } else {
      navigate(`/fund/portfolio/history/${row.original.guaranteeRegisterId}`)
    }
  }

  return (
    <PrivateLayout title="Histórico">
      <SelectDataTable
        onSelectItem={onSelectItem}
        columns={collateralMovementsHeaders}
        data={data}
      />
    </PrivateLayout>
  )
}
