/* eslint-disable multiline-ternary */
import { useSelector } from 'react-redux'
import { OTPImageGenerator, OTPInput } from 'components/features/auth'
import { useState } from 'react'

export const OtpVerification = () => {
  const { user } = useSelector((state) => state.authorization)
  const { otpActive } = user
  const [step, setStep] = useState('generate-image')
  return (
    <div className="max-w-full">
      {otpActive ? <OTPInput /> : step === 'generate-image' ? <OTPImageGenerator setStep={setStep} /> : <OTPInput setStep={setStep} />}
    </div>
  )
}
