import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { PrivateLayout } from 'layout/private'
import { Button, Card, Select } from 'components/ui'
import { onOpenModal } from 'app/features/ui.slice'
import { onAddPermissions, onGetPermissionData, onHandleSelectedRole, onResetPermissions, onResetSelectedRole } from 'app/features/permissions-slice'
import { useAssignPermissions } from 'hooks'
import { handleCheckPermissions } from 'helpers/handleCheckPermissions'
import { rolesAdapter } from 'adapters/organization'
import { getAssignedOrganizationRolePermissionsData, getAssignedRolePermissionsData } from 'services/organization-service'

export const PermissionsView = () => {
  const { key } = useParams()
  const { data, selectedRole, permissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()
  const { handleActivatePermission, saveRoleOrganizationPermissions } = useAssignPermissions()

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(onResetPermissions())
        dispatch(onResetSelectedRole())
        const { data } = await getAssignedRolePermissionsData(key)
        const roles = rolesAdapter(data?.response.roles)
        dispatch(onGetPermissionData({ roles, modules: data.response.modules, organization: data.response.organization }))
      } catch (err) {
        dispatch(onOpenModal({ message: 'Algo salio mal cargando datos, por favor intenta de nuevo más tarde.', type: 'ERROR' }))
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getOrganizationRolePermissions = async () => {
      try {
        dispatch(onResetPermissions())
        if (selectedRole != null) {
          handleCheckPermissions(permissions, false)
          const { data } = await getAssignedOrganizationRolePermissionsData(key, selectedRole.id)
          handleCheckPermissions(data.response.permissions, true)
          dispatch(onAddPermissions(data.response.permissions))
        }
      } catch (err) {
        dispatch(onOpenModal({ message: 'Algo salio mal cargando permisos, por favor intenta de nuevo más tarde.', type: 'ERROR' }))
      }
    }
    getOrganizationRolePermissions()
  }, [selectedRole])

  return (
    <PrivateLayout title="Asignar permisos">
      <div className="mb-8">
        <h5 className="mb-2">Roles</h5>
        <p className="mb-2">Seleccione rol correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={data.roles}
          value={selectedRole}
          onChange={(value) => dispatch(onHandleSelectedRole(value.key))}
        />
      </div>
      {selectedRole !== null &&
        data.modules.map((item) => (
          <Card
            key={item.parent.id}
            className="mb-4"
          >
            <h4 className="mb-2">{item.parent.label}</h4>
            <div className={`${item.child?.length > 0 && 'mb-4'}`}>
              {item.parent.module_permission.map((el) => (
                <div
                  key={el.permission.id}
                  className="w-full flex items-center justify-between mb-2 last:mb-0"
                >
                  <p>{el.permission.name}</p>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      id={`select-all-${item.parent.id}-${el.permission.id}`}
                      onChange={({ target }) => handleActivatePermission(item.parent.id, el.permission.id, target)}
                    />
                    <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
                  </label>
                </div>
              ))}
            </div>
            <div>
              {item.child?.map((child) => (
                <Card
                  key={child.id}
                  className="mb-4 last:mb-0"
                >
                  <div className="mb-2">
                    <h4>{child.label}</h4>
                    <p className="text-xs flex items-center justify-start">
                      Acceso desde menu lateral:
                      <span className={`text-xl ml-2 ${child.visible ? 'text-green-400' : 'text-red-500'}`}>
                        {child.visible ? <AiOutlineCheckCircle /> : <AiOutlineCloseCircle />}
                      </span>
                    </p>
                  </div>
                  <div className="mb-4 last:mb-0">
                    {child.module_permission.map((el) => (
                      <div
                        key={el.permission.id}
                        className="w-full flex items-center justify-between mb-2 last:mb-0"
                      >
                        <p>{el.permission.name}</p>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            id={`select-all-${child.id}-${el.permission.id}`}
                            onChange={({ target }) => handleActivatePermission(child.id, el.permission.id, target)}
                          />
                          <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
                        </label>
                      </div>
                    ))}
                  </div>
                </Card>
              ))}
            </div>
          </Card>
        ))}
      {selectedRole && (
        <Button
          className="mt-4 mb-8"
          variant="solid"
          onClick={saveRoleOrganizationPermissions}
        >
          Guardar cambios
        </Button>
      )}
    </PrivateLayout>
  )
}
