import { formatToCurrency } from 'helpers/formatToCurrency'

export const collateralHistoryAdapter = (data) => {
  return data.map((item) => {
    const formattedCollateralReceived = formatToCurrency(item.Value.Collateral_Received, 'es-CO', 'COP')
    const formattedFaceValue = formatToCurrency(item.Value.Face_Value, 'es-CO', 'COP')
    const date = `${new Date(item.Value.Date).toLocaleDateString()} - ${new Date(item.Value.Date).toLocaleTimeString()}`
    const formattedTimestamp = `${new Date(item.Timestamp).toLocaleDateString()} - ${new Date(
      item.Timestamp
    ).toLocaleTimeString()}`

    const guarantees = item.Value.Guarantes_Types.map((guarantee, i) => {
      const formattedAmount = formatToCurrency(
        guarantee.Amount,
        'es-CO',
        guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
      )
      const formattedCollateral = formatToCurrency(
        guarantee.Collateral,
        'es-CO',
        guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
      )
      const formattedInitDate = `${new Date(guarantee.Init_Date).toLocaleDateString()} - ${new Date(
        guarantee.Init_Date
      ).toLocaleTimeString()}`
      const formattedUpdateDate = `${new Date(guarantee.Update_Date).toLocaleDateString()} - ${new Date(
        guarantee.Update_Date
      ).toLocaleTimeString()}`
      const formattedValuation = formatToCurrency(
        guarantee.Valuation_Amount,
        'es-CO',
        guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
      )

      return {
        amount: formattedAmount,
        amountNumber: Number(guarantee.Amount),
        collateral: formattedCollateral,
        custodianId: item.Value.Custodian_Id,
        initDate: formattedInitDate,
        updateDate: formattedUpdateDate,
        haircut: `${guarantee.Haircut} %`,
        guaranteeTypeId: guarantee.Guarantes_Type_Id,
        guaranteeName: guarantee.Guarantee_Name,
        guaranteeType: guarantee.Guarante_Type,
        transactionType: guarantee.Transaction_Type,
        userName: guarantee.User_Name,
        userEmail: guarantee.User_Email,
        valuationAmount: formattedValuation
      }
    })
    return {
      txId: item.TxId,
      value: {
        collateralReceived: item.Value.Collateral_Received,
        formattedCollateralReceived,
        custodianId: item.Value.Custodian_Id,
        custodianName: item.Value.Custodian_Name,
        entityId: item.Value.Entity_Id,
        entityName: item.Value.Entity_Name,
        entityType: item.Value.Entity_Type,
        formattedFaceValue,
        faceValue: item.Value.Face_Value,
        guaranteeRegisterId: item.Value.Guarantee_Register_Id,
        guarantees,
        portfolioName: item.Value.Portfolio_name,
        portfolioNIT: item.Value.Portfolio_nit,
        portfolioLEI: item.Value.Portfolio_lei,
        date
      },
      formattedTimestamp,
      timestamp: item.Timestamp,
      isDelete: item.IsDelete
    }
  })
}
