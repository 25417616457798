export const organizationFullAdapter = (data) => {
  const formattedCreatedAt = `${new Date(data.createdAt).toLocaleDateString()} - ${new Date(data.createdAt).toLocaleTimeString()}`
  return {
    id: data.id,
    key: data.key,
    name: data.name,
    status: data.status.key,
    type: data.type.key,
    otpRequired: data.otp_required,
    createdAt: data.createdAt,
    formattedCreatedAt,
    portfolios: data.portfolio.map((el) => {
      const formattedCreatedAt = `${new Date(el.createdAt).toLocaleDateString()} - ${new Date(el.createdAt).toLocaleTimeString()}`
      return {
        value: el.id,
        key: el.NIT,
        NIT: el.NIT,
        LEI: el.LEI,
        label: el.name,
        status: el.status.key,
        createdAt: data.createdAt,
        formattedCreatedAt
      }
    }),
    users: data.user.map((el) => {
      return {
        value: el.id,
        key: el.email,
        label: el.name,
        email: el.email,
        status: el.status.key,
        role: el.role?.label ?? 'No asignado'
      }
    })
  }
}
