import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, Dialog, Input, Select, InputPercentage } from 'components/ui'
import { DropDataTable } from 'components/dataTable'
import { useAssignCollateral } from 'features/assignCollateral/hook/useAssignCollateral'
import dropCollateralDataTable from 'features/assignCollateral/json/dropCollateralDataTable.json'
import { onChangeAmount, onChangeHaircut, onSelectCollateralType } from 'app/features/collateral.slice'
import { AddIsinCard } from './AddIsinCard'

export const AddCollateralCard = () => {
  const { assign } = useSelector((state) => state.collateral)
  const { disabled, selectedCollateral, amount, haircut, collaterals, collateralTypes } = assign
  const { modulePermissions } = useSelector((state) => state.permission)
  const {
    addNewCollateral,
    onDropSelectedCollaterals,
    isinCardOpen,
    setIsinCardOpen,
    dialogIsOpen,
    onDialogOk,
    dialogMessage
  } = useAssignCollateral()
  const dispatch = useDispatch()

  const handleChangeAmount = ({ target }) => {
    if (target.value !== '') {
      const numeroFormateado = parseFloat(target.value.replace(/[^0-9.-]+/g, '')).toLocaleString('en-US')
      dispatch(onChangeAmount(numeroFormateado))
    } else {
      dispatch(onChangeAmount(''))
    }
  }

  const handleChangeHaircut = ({ target }) => {
    if (target.value >= 0) {
      dispatch(onChangeHaircut(target.value))
    }
    if (target.value > 100) {
      dispatch(onChangeHaircut(100))
    }
  }

  const handleChangeCollateral = (value) => {
    setIsinCardOpen(false)
    dispatch(onSelectCollateralType(value))
  }

  return (
    <Card className="mb-8">
      <div className="mb-6">
        <h5 className="mb-2">ISIN</h5>
        <Select
          placeholder="Seleccione ISIN"
          options={collateralTypes}
          isDisabled={!modulePermissions.hasPostPermission}
          value={selectedCollateral}
          onChange={(e) => handleChangeCollateral(e)}
        />

        <p className="mt-2 mb-6">
          ¿No encuentras tu ISIN?,{' '}
          <button
            disabled={!modulePermissions.hasPostPermission}
            onClick={() => setIsinCardOpen(!isinCardOpen)}
            className="font-bold hover:underline"
          >
            Créalo aquí
          </button>
        </p>
        {isinCardOpen && <AddIsinCard />}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Monto</h5>
        <Input
          placeholder="Asigne monto correspondiente"
          type="string"
          value={amount}
          disabled={disabled || !modulePermissions.hasPostPermission}
          onChange={(e) => handleChangeAmount(e)}
          // onWheel={(e) => e.target.blur()}
        />
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Haircut</h5>
        <InputPercentage
          placeholder="Asigne el haircut correspondiente"
          value={haircut}
          disabled={disabled || !modulePermissions.hasPostPermission}
          onChange={(e) => handleChangeHaircut(e)}
          // onWheel={(e) => e.target.blur()}
        />
      </div>

      <Button
        variant="solid"
        onClick={addNewCollateral}
        disabled={amount === null || selectedCollateral === null || disabled || !modulePermissions.hasPostPermission}
      >
        Añadir
      </Button>
      <DropDataTable
        columns={dropCollateralDataTable}
        data={collaterals}
        onDropSelectedItems={onDropSelectedCollaterals}
      />
      <Dialog
        isOpen={dialogIsOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{dialogMessage}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </Card>
  )
}
