/* eslint-disable multiline-ternary */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PrivateLayout } from 'layout/private'
import { Button, Dialog, Input, Tag } from 'components/ui'
import { ParagraphSkeleton } from 'components/skeleton/ParagraphSkeleton'
import { FundContractCard, FundPartACard, FundPartBCard } from 'features/fund/contracts'
import { onFinishContractLoading, onGetDataApproveContract, onStartContractLoading } from 'app/features/contractSlice'
import { approveContract, getFundContractDataLinked } from 'services/fund.service'

export const DerivativeDetail = () => {
  const { id: contractId } = useParams()
  const { contract } = useSelector((state) => state.contract)
  const { partA, partB, partContract, loading } = contract
  const { modulePermissions } = useSelector((state) => state.permission)

  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const getData = async () => {
      dispatch(onStartContractLoading())

      try {
        const { data } = await getFundContractDataLinked(contractId)
        dispatch(
          onGetDataApproveContract({
            status: data.response.contract[0].Status,
            contractId: data.response.contract[0].Contract_Register_Id,
            approved: data.response.contract[0].Accepted,

            entityA: data.response.formattedBank,
            portfolioA: data.response.formattedBank.portfolios.find(
              (el) => el.NIT === data.response.contract[0].APortfolio_NIT
            ),
            entityB: data.response.formattedFund,
            portfolioB: data.response.formattedFund.portfolios.find(
              (el) => el.NIT === data.response.contract[0].BPortfolio_NIT
            ),
            date: new Date(data.response.contract[0].CSA_Date),
            endDate: new Date(data.response.contract[0].Finish_Date),
            valuator: data.response.formattedValuator,
            custodianA: data.response.formattedCustodianA,
            custodianB: data.response.formattedCustodianB,
            currency: data.response.formattedCurrency,
            initialMargin: data.response.contract[0].Initial_Margin,
            independentAmount: data.response.contract[0].Independent_Amount,
            threshold: data.response.contract[0].Threshold,
            minTransferAmount: data.response.contract[0].Minimum_Transfer_Amount,

            createdUser: {
              name: data.response.contract[0].Created_User_Name,
              email: data.response.contract[0].Created_User_Email
            },
            approvedUser: {
              name: data.response.contract[0].Approved_User_Name,
              email: data.response.contract[0].Approved_User_Email
            }
          })
        )
        dispatch(onFinishContractLoading())
      } catch (err) {
        openDialog(err.response.data.message)
        dispatch(onFinishContractLoading())
      }
    }

    getData()
  }, [])

  const makeTransaction = async (e) => {
    setIsLoading(true)
    try {
      const { data } = await approveContract(partA, partB, partContract, partContract.contractId)
      openDialog(data.message)
    } catch (err) {
      openDialog(err.response.data.message)
    }
    setIsLoading(false)
  }

  const openDialog = (message) => {
    setMessage(message)
    setModalOpen(true)
  }
  const onDialogOk = (e) => {
    setModalOpen(false)
    navigate('/fund/contracts')
  }

  return (
    <PrivateLayout title="Detalles de contrato">
      {contract.loading ? (
        <div className="max-w-xs mb-8">
          <ParagraphSkeleton />
        </div>
      ) : (
        <div className="mb-10">
          <Tag
            className={
              contract.partContract.approved
                ? 'bg-emerald-100 text-green-600 dark:bg-green-600 dark:text-emerald-100 border-0 rounded mb-2 '
                : 'bg-emerald-100 text-orange-600 dark:bg-orange-600 dark:text-emerald-100 border-0 rounded mb-2 '
            }
          >
            {contract.partContract.approved ? 'Aprobado' : 'Por aprobar'}
          </Tag>

          <div className="mb-4">
            <h5 className="mb-2">Creado por:</h5>
            <Input
              placeholder="Creado por:"
              type="text"
              disabled={true}
              value={`${contract.createdUser.name} (${contract.createdUser.email})`}
            />
          </div>

          {contract.partContract.approved && (
            <div className="mb-8">
              <h5 className="mb-2">Aprobado por:</h5>
              <Input
                placeholder="Aprobado por:"
                type="text"
                disabled={true}
                value={`${contract.approvedUser.name} (${contract.approvedUser.email})`}
              />
            </div>
          )}
        </div>
      )}

      <FundPartACard />
      <FundPartBCard />
      <FundContractCard />

      <Button
        className="mb-8"
        variant="solid"
        disabled={!!contract.partContract.approved || loading || !modulePermissions.hasPutPermission}
        loading={isLoading}
        onClick={makeTransaction}
      >
        Aprobar
      </Button>
      <Dialog
        isOpen={modalOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </PrivateLayout>
  )
}
