import axios from 'axios'
import Cookies from 'js-cookie'

const ENV = process.env.REACT_APP_ENV
const AUTH_API_DEV_ENDPOINT = process.env.REACT_APP_API_AUTH_URL_DEV

console.log('Direction:')
console.log(ENV === 'develop' ? AUTH_API_DEV_ENDPOINT : AUTH_API_DEV_ENDPOINT)
export const authBaseURL = axios.create({
  baseURL: ENV === 'develop' ? AUTH_API_DEV_ENDPOINT : AUTH_API_DEV_ENDPOINT,
  headers: { 'Content-Type': 'application/json' }
})

authBaseURL.interceptors.request.use(
  (config) => {
    const refreshToken = Cookies.get('refreshToken')
    if (refreshToken) {
      config.headers.Authorization = refreshToken
    }
    return config
  },
  (error) => Promise.reject(error)
)
