export const contractsAdapter = (data, organizationType) => {
  return data
    .map((item) => {
      const formattedInitialMargin = `${item.Initial_Margin} %`
      const formattedIndependentAmount = `${item.Independent_Amount} %`
      const completeContractName =
        organizationType === 'bank'
          ? `${item.APortfolio_Name} - ${item.BPortfolio_Name}`
          : `${item.BPortfolio_Name} - ${item.APortfolio_Name}`

      const NIT = organizationType === 'bank' ? item.APortfolio_NIT : item.BPortfolio_NIT
      const LEI = organizationType === 'bank' ? item.APortfolio_LEI : item.BPortfolio_LEI
      const formattedCSADate = `${new Date(item.CSA_Date).toLocaleDateString()} - ${new Date(
        item.CSA_Date
      ).toLocaleTimeString()}`
      const formattedFinishDate = `${new Date(item.Finish_Date).toLocaleDateString()} - ${new Date(
        item.Finish_Date
      ).toLocaleTimeString()}`

      return {
        NIT,
        LEI,
        label: completeContractName,
        key: item.Contract_Register_Id,
        value: item.Contract_Register_Id,

        ACustodianId: item.ACustodian_Id,
        ACustodianName: item.ACustodian_Name,
        AEntityId: item.AEntity_Id,
        AEntityName: item.AEntity_Name,
        AEntityType: item.AEntity_Type,
        APortfolioLEI: item.APortfolio_LEI,
        APortfolioNIT: item.APortfolio_NIT,
        APortfolioName: item.APortfolio_Name,

        BCustodianId: item.BCustodian_Id,
        BCustodianName: item.BCustodian_Name,
        BEntityId: item.BEntity_Id,
        BEntityName: item.BEntity_Name,
        BEntityType: item.BEntity_Type,
        BPortfolioLEI: item.BPortfolio_LEI,
        BPortfolioNIT: item.BPortfolio_NIT,
        BPortfolioName: item.BPortfolio_Name,

        accepted: item.Accepted === 'true' ? 'Sí' : 'No',
        csaDate: item.CSA_Date,
        formattedCSADate,
        formattedFinishDate,
        contractRegisterId: item.Contract_Register_Id,
        currencyId: item.Currency_Id,
        currencyName: item.Currency_Name,
        finishDate: item.Finish_Date,
        independentAmount: item.Independent_Amount,
        formattedIndependentAmount,
        initialMargin: item.Initial_Margin,
        formattedInitialMargin,
        minimumTransferAmount: item.Minimum_Transfer_Amount,
        status: item.Status,
        threshold: item.Threshold,
        valuatorId: item.Valuator_Id,
        valuatorName: item.Valuator_Name
      }
    })
    .sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })
}
