import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Select } from 'components/ui'
import {
  onChangeEntityNIT,
  onChangeEntityName,
  onChangeEntityStatus,
  onChangeEntityType,
  onChangeOTPRequired
} from 'app/features/organization.slice'
import { onOpenModal } from 'app/features/ui.slice'
import { updateOrganizationService } from 'services/colibri.service'

export const EntityDetailCard = () => {
  const { input, organizationTypes, statusTypes } = useSelector((state) => state.organization)
  const { modulePermissions } = useSelector((state) => state.permission)
  const { entity } = input
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const handleUpdateEntity = async () => {
    const dataToUpdate = {
      id: entity.id,
      name: entity.name,
      key: entity.NIT,
      type: entity.type.value,
      status: entity.status.value,
      otp: entity.otpRequired
    }
    setIsLoading(true)
    const { data } = await updateOrganizationService(dataToUpdate)
    dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
    setIsLoading(false)
  }

  return (
    <div>
      <div className="mb-4">
        <h5 className="mb-2">Nombre de organización</h5>
        <Input
          placeholder="Ingrese nombre de la organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={({ target }) => dispatch(onChangeEntityName(target.value))}
          value={entity.name}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="Ingrese el NIT de la organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={({ target }) => dispatch(onChangeEntityNIT(target.value))}
          value={entity.NIT}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Tipo de organización</h5>
        <Select
          placeholder="Seleccione el tipo de organización"
          options={organizationTypes}
          isLoading={isLoading}
          isDisabled={!modulePermissions.hasPutPermission}
          onChange={(value) => dispatch(onChangeEntityType(value.key))}
          value={entity.type}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Estado de organización</h5>
        <Select
          placeholder="Seleccione el estado de la organización"
          options={statusTypes}
          isLoading={isLoading}
          isDisabled={!modulePermissions.hasPutPermission}
          onChange={(value) => dispatch(onChangeEntityStatus(value.key))}
          value={entity.status}
        />
      </div>
      <div className="mb-4">
        <h5 className="mb-2">Segundo factor de autenticación (OTP Auth)</h5>
        <div className="w-full flex items-center justify-between border p-3 rounded-md">
          <p>Requerido</p>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              id={`otp-${entity.id}-${entity.NIT}`}
              onChange={() => dispatch(onChangeOTPRequired())}
            />
            <div className="w-11 h-6 rounded-full outline-none bg-gray-700   dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-green-600" />
          </label>
        </div>
      </div>

      <Button
        variant="solid"
        className="mb-8"
        onClick={() => handleUpdateEntity()}
        loading={isLoading}
        disabled={!modulePermissions.hasPutPermission}
      >
        Actualizar información
      </Button>
    </div>
  )
}
