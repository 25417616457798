import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PrivateLayout } from 'layout/private'
import { SelectDataTable } from 'components/dataTable'
import { onLoadContracts } from 'app/features/contractSlice'
import bankDataTable from 'features/derivatives/json/bankDataTable.json'
import fundDataTable from 'features/derivatives/json/fundDataTable.json'
import { contractsAdapter } from 'adapters'
import { getBankContracts } from 'services/bank.service'
import { getFundContracts } from 'services/fund.service'

export const DerivativesView = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationKey, organizationType } = user
  const { contracts } = useSelector((state) => state.contract)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getData = async () => {
      if (organizationType === 'bank') {
        const { data } = await getBankContracts(organizationKey)
        const contracts = contractsAdapter(data.response)
        dispatch(onLoadContracts(contracts))
      }
      if (organizationType === 'fund') {
        const { data } = await getFundContracts(organizationKey)
        const contracts = contractsAdapter(data.response)
        dispatch(onLoadContracts(contracts))
      }
    }
    getData()
  }, [])

  const onSelectItem = (e, row) => {
    if (organizationType === 'bank') {
      navigate(`/bank/contract/${contracts.data[row.index].contractRegisterId}`)
    } else {
      navigate(`/fund/contract/${contracts.data[row.index].contractRegisterId}`)
    }
  }

  return (
    <PrivateLayout title="Lista de contratos">
      <SelectDataTable
        columns={organizationType === 'bank' ? bankDataTable : fundDataTable}
        data={contracts.data}
        onSelectItem={onSelectItem}
      />
    </PrivateLayout>
  )
}
