import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import allContractsTableHeaders from 'features/colibri/dashboard/json/allContractsTableHeaders.json'
import FilteringTable from 'marketxm/views/Components/FilteringTable'
import { colibriDataAdapter } from 'adapters/colibriData.adapter'
import { onFinishDashboardLoading, onGetColibriDashboardData, onStartDashboardLoading } from 'app/features/dashboard.slice'
import { colibriDashboardDataService } from 'services/colibri.service'
import { PrivateLayout } from 'layout/private'

const ColibriDashboardView = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationKey } = user
  const { colibri } = useSelector((state) => state.dashboard)
  const { data } = colibri
  const dispatch = useDispatch()

  useEffect(() => {
    const getCustodianData = async () => {
      dispatch(onStartDashboardLoading())
      const { status, data } = await colibriDashboardDataService(organizationKey)
      if (status === 200) {
        const result = colibriDataAdapter(data.output)
        dispatch(onGetColibriDashboardData(result))
        dispatch(onFinishDashboardLoading())
      }
    }
    getCustodianData()
  }, [])

  return (
    <PrivateLayout title="Dashboard">
      <FilteringTable
        columns={allContractsTableHeaders}
        data={data}
      />
    </PrivateLayout>
  )
}

export default ColibriDashboardView
