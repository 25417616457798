import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Theme from 'components/template/Theme'
import { store } from 'app/store'
import { AppRouter } from 'routes/AppRouter'

function App () {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Theme>
          <AppRouter />
        </Theme>
      </BrowserRouter>
    </Provider>
  )
}

export default App
