import { backendBaseURL } from 'interceptors/backend.interceptor'

export const sendExcelContractValuations = async (formData) => {
  return await backendBaseURL.post('/api/v2/valuator/load-contracts-excel', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const sendExcelGuaranteeValuations = async (formData) => {
  return await backendBaseURL.post('/api/v2/valuator/load-guarantees-excel', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export const getContractValuations = async (organizationKey) => {
  return await backendBaseURL.get(`/api/v2/valuator/contracts/${organizationKey}`)
}
export const getGuaranteeValuations = async (organizationKey) => {
  return await backendBaseURL.get(`/api/v2/valuator/guarantees/${organizationKey}`)
}

export const getDownloadSignedLink = async (fileUrl) => {
  const params = { fileUrl }
  return await backendBaseURL.get('/api/v2/valuator/download', { params })
}
