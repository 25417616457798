/* eslint-disable multiline-ternary */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PrivateLayout } from 'layout/private'
import { Card, Input, Select, Spinner } from 'components/ui'
import { DataTable } from 'components/dataTable'
import assignedCollaterals from 'features/collaterals/history/json/assignedCollaterals.json'
import { collateralHistoryAdapter } from 'adapters/collateralHistory.adapter'
import { getCollateralHistory } from 'services/bank.service'
import { formatToCurrency } from 'helpers/formatToCurrency'

export const CollateralHistoryView = () => {
  const [collaterals, setCollaterals] = useState([])
  const [types, setTypes] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [loading, setLoading] = useState(true)
  const [history, setHistory] = useState([])
  const { id } = useParams()

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getCollateralHistory(id)
        const collateralTypes = data.response.map((item) =>
          item.Value.Guarantes_Types.map((el) => ({
            value: el.Guarante_Type,
            key: el.Guarante_Type,
            label: el.Guarantee_Name
          }))
        )
        setTypes(collateralTypes[0])
        const formattedHistory = collateralHistoryAdapter(data.response)
        setCollaterals(formattedHistory)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    getData()
  }, [])

  useEffect(() => {
    let collateralsData = collaterals.map((item) => {
      const filteredCollaterals = item.value.guarantees
        .filter((el) => el.transactionType !== 'Stable')
        .find((el) => el.guaranteeType === selectedType.key)
      return { ...filteredCollaterals, transactionId: item.txId }
    })
    collateralsData = collateralsData.filter((el) => el.amount)

    // Set variation
    for (let i = collateralsData.length - 1; i >= 0; i--) {
      const current = collateralsData[i].amountNumber
      const last = i < collateralsData.length - 1 ? collateralsData[i + 1].amountNumber : 0
      const variation = current - last
      const formattedVariation = formatToCurrency(
        variation,
        'es-CO',
        collateralsData[i].guaranteeType === 'USD' ? 'USD' : 'COP'
      )
      collateralsData[i].variation = variation
      collateralsData[i].formattedVariation = formattedVariation
    }
    setHistory(collateralsData)
  }, [selectedType])

  return (
    <PrivateLayout title="Movimientos">
      {loading ? (
        <div className="min-h-screen flex items-center justify-center">
          <Spinner
            className="animate-spin"
            size={64}
          />
        </div>
      ) : (
        <div>
          <Card className="mb-8">
            <div className="mb-4">
              <h5 className="mb-2">Nombre de portafolio</h5>
              <Input
                type="text"
                disabled={true}
                value={collaterals[0].value.portfolioName}
              />
            </div>
            <div className="mb-4">
              <h5 className="mb-2">Fecha de actualización</h5>
              <Input
                type="text"
                disabled={true}
                value={collaterals[0].formattedTimestamp}
              />
            </div>
            <div className="mb-4">
              <h5 className="mb-2">Colateral elegible</h5>
              <Input
                type="text"
                disabled={true}
                value={collaterals[0].value.formattedFaceValue}
              />
            </div>

            <div className="mb-4">
              <h5 className="mb-2">Colateral constituido</h5>
              <Input
                type="text"
                disabled={true}
                value={collaterals[0].value.formattedCollateralReceived}
              />
            </div>
            <div className="mb-8">
              <h5 className="mb-2">ISIN</h5>
              <p className="mb-2">Seleccione el ISIN para ver histórico</p>
              <Select
                placeholder="Seleccione por favor"
                options={types}
                isLoading={loading}
                onChange={(value) => setSelectedType(value)}
                value={selectedType}
              />
            </div>

            <DataTable
              columns={assignedCollaterals}
              data={history}
            />
          </Card>
        </div>
      )}
    </PrivateLayout>
  )
}
