import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const labels = ['Margen de variación']

export const ValuationChart = () => {
  const { dashboard } = useSelector((state) => state.ui)
  const { valuations } = dashboard
  const marginCall = valuations.totalReceived - Math.abs(valuations.totalDelivered)
  const data = {
    labels,
    datasets: [
      {
        label: 'Colateral recibido',
        data: labels.map(() => valuations.totalReceived),
        backgroundColor: 'rgba(71, 85, 105, 1)'
      },
      {
        label: 'Colateral entregado',
        data: labels.map(() => valuations.totalDelivered),
        backgroundColor: 'rgba(75, 85, 99, 1)'
      },
      {
        label: 'Llamado al margen',
        data: labels.map(() => marginCall),
        backgroundColor: marginCall > -1 ? 'rgba(8, 145, 178, 0.5)' : 'rgba(205, 0, 0, 0.5)'
      }
    ]
  }

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false
      },
      legend: {
        position: 'top'
      }
    },
    scales: {
      y: {
        min: valuations.totalDelivered === 0 ? 0 : valuations.totalDelivered * 2,
        max: marginCall > valuations.totalReceived ? marginCall * 2 : valuations.totalReceived * 2
      }
    }
  }
  return (
    <div className="mb-8">
      <Bar
        options={options}
        data={data}
      />
    </div>
  )
}
