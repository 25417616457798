/* eslint-disable multiline-ternary */
import { useSelector } from 'react-redux'
import { useEditContract } from 'hooks/useEditContract'
import { Card, DatePicker, Input, Select, InputPercentage } from 'components/ui'
import { SelectSkeleton } from 'components/skeleton/SelectSkeleton'

export const PartContractEditContractCard = () => {
  const { contract, data } = useSelector((state) => state.contract)
  const { partContract, loading, approved } = contract
  const { modulePermissions } = useSelector((state) => state.permission)
  const {
    onDatePickerChange,
    handleSelectValuator,
    handleSelectCustodianA,
    handleSelectCustodianB,
    handleSelectCurrency,
    handleChangeInitialMargin,
    handleChangeIndependentAmount,
    handleChangeThreshold,
    handleChangeMinTransferAmount,
    // onEndDatePickerChange,
    btnLoading
  } = useEditContract()

  return (
    <Card className="mb-8">
      <h3 className="mb-4">Contrato</h3>
      <div className="mb-8">
        <h5 className="mb-2">Fecha CSA</h5>
        <p className="mb-2">Ingrese fecha CSA correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <DatePicker
            placeholder="Fecha CSA"
            value={partContract.date}
            disabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            onChange={onDatePickerChange}
          />
        )}
      </div>

      {/* <div className="mb-8">
        <h5 className="mb-2">Fecha de finalización</h5>
        <p className="mb-2">Ingrese fecha de finalización correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <DatePicker
            placeholder="Fecha CSA"
            value={partContract.endDate}
            disabled={approved || btnLoading}
            onChange={onEndDatePickerChange}
          />
        )}
      </div> */}

      <div className="mb-8">
        <h5 className="mb-2">Agente de valoración</h5>
        <p className="mb-3">Seleccione agente de valoración correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={data.valuators}
            isDisabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={partContract.valuator}
            onChange={handleSelectValuator}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente custodio Parte A</h5>
        <p className="mb-2">Seleccione agente custodio de la parte A correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={data.custodians}
            isDisabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={partContract.custodianA}
            onChange={handleSelectCustodianA}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Agente custodio Parte B</h5>
        <p className="mb-2">Seleccione agente custodio de la parte B correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={data.custodians}
            isDisabled={approved || !modulePermissions.hasPutPermission}
            value={partContract.custodianB}
            onChange={handleSelectCustodianB}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Moneda</h5>
        <p className="mb-2">Seleccione tipo de moneda correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={data.currencies}
            isDisabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={partContract.currency}
            onChange={handleSelectCurrency}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Margen inicial</h5>
        <p className="mb-2">Ingrese margen inicial porcentual correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <InputPercentage
            placeholder="Margen Inicial"
            type="number"
            disabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={partContract.initialMargin}
            onChange={handleChangeInitialMargin}
          />
        )}
      </div>

      <div className="mb-8">
        <h4 className="mb-2">Monto independiente</h4>
        <p className="mb-2">Ingrese monto independiente porcentual correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <InputPercentage
            placeholder="Monto Independiente"
            type="number"
            disabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={Number(partContract.independentAmount)}
            onChange={handleChangeIndependentAmount}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Umbral/ Threshold</h5>
        <p className="mb-3">Ingrese umbral correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Umbral/ Threshold"
            type="number"
            disabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={Number(partContract.threshold)}
            onChange={handleChangeThreshold}
          />
        )}
      </div>

      <div>
        <h5 className="mb-2">Monto mínimo de transferencia</h5>
        <p className="mb-3">Ingrese monto mínimo de transferencia correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Monto Mínimo de Transferencia"
            type="number"
            disabled={contract.approved || btnLoading || !modulePermissions.hasPutPermission}
            value={Number(partContract.minTransferAmount)}
            onChange={handleChangeMinTransferAmount}
          />
        )}
      </div>
    </Card>
  )
}
