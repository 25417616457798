import { rolesAdapter } from 'adapters/organization'
import { onOpenModal } from 'app/features/ui.slice'
import { DataTable } from 'components/dataTable'
// import { Button } from 'components/ui'
import { PrivateLayout } from 'layout/private'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getRolesService } from 'services/organization-service'
const columns = [
  {
    Header: 'Llave',
    accessor: 'key'
  },
  {
    Header: 'Nombre',
    accessor: 'label'
  },
  {
    Header: 'Estado',
    accessor: 'statusLabel'
  }
]

export const RolesView = () => {
  // const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getRolesService()
        const formattedRoles = rolesAdapter(data.response)
        setRoles(formattedRoles)
      } catch (err) {
        dispatch(onOpenModal({ message: err.response.data.message, type: 'ERROR' }))
      }
    }
    getData()
  }, [])

  return (
    <PrivateLayout title="Roles">
      <DataTable
        data={roles}
        columns={columns}
      />
      {/* <div className="w-full flex justify-end mb-8">
        <Button disabled={modulePermissions.hasPostPermission}>Crear nuevo rol</Button>
      </div> */}
    </PrivateLayout>
  )
}
