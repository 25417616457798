import { Navigate, Route, Routes } from 'react-router-dom'
import { ForgotPasswordView, ResetPasswordView, SignInView } from 'views/auth'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path="/sign-in"
        element={<SignInView />}
      />
      <Route
        path="/forgot-password"
        element={<ForgotPasswordView />}
      />
      <Route
        path="/reset-password/:token"
        element={<ResetPasswordView />}
      />
      <Route
        path="/*"
        element={<Navigate to="/sign-in" />}
      />
    </Routes>
  )
}
