import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card } from 'components/ui'
import { ParagraphSkeleton } from 'components/skeleton/ParagraphSkeleton'

export const CollateralCard = ({ title, description, value }) => {
  const { dashboard } = useSelector((state) => state.ui)
  const { collaterals } = dashboard

  return (
    <Card>
      <h5>{title}</h5>
      <p className="my-2">{description}</p>
      <div className="max-w-xs">{collaterals.loading ? <ParagraphSkeleton /> : <p>{value}</p>}</div>
    </Card>
  )
}

CollateralCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}
