import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Input, Select } from 'components/ui'
import { onGetOrganizationPortfolios } from 'app/features/organization.slice'
import { onOpenModal } from 'app/features/ui.slice'
import { portfoliosAdapter } from 'adapters/core/portfolios.adapter'
import { createPortfolioService } from 'services/colibri.service'

export const PortfolioDetailCreateCard = ({ setCardIsOpen }) => {
  const { statusTypes, input } = useSelector((state) => state.organization)
  const { entity } = input
  const { modulePermissions } = useSelector((state) => state.permission)

  const [values, setValues] = useState({ name: '', LEI: '', NIT: '', status: null })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleChange = (ev) => {
    setValues({ ...values, [ev.target.name]: ev.target.value })
  }

  const handleChangeStatus = (value) => {
    const selectedStatus = statusTypes.find((el) => el.key === value.key)
    setValues({ ...values, status: selectedStatus })
  }

  const handleCreatePortfolio = async () => {
    try {
      const dataToSend = { ...values, organizationId: entity.id }
      setIsLoading(true)
      const { data } = await createPortfolioService(dataToSend)
      const portfolios = portfoliosAdapter(data.data)
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
      dispatch(onGetOrganizationPortfolios(portfolios))
      setIsLoading(false)
      setCardIsOpen(false)
    } catch (err) {
      dispatch(onOpenModal({ message: 'Algo salio mal', type: 'ERROR' }))
    }
  }

  return (
    <Card className="mt-8">
      <div className="mb-8">
        <h5 className="mb-2">Nombre de portafolio</h5>
        <Input
          placeholder="Ingrese nombre de portafolio"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPostPermission}
          name="name"
          onChange={(e) => handleChange(e)}
          value={values.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="Ingrese NIT de organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPostPermission}
          name="NIT"
          onChange={(e) => handleChange(e)}
          value={values.NIT}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">LEI</h5>
        <Input
          placeholder="Ingrese LEI de organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPostPermission}
          name="LEI"
          onChange={(e) => handleChange(e)}
          value={values.LEI}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Estado</h5>
        <Select
          placeholder="Seleccione estado de la organización"
          options={statusTypes}
          isLoading={isLoading}
          isDisabled={!modulePermissions.hasPostPermission}
          onChange={(value) => handleChangeStatus(value)}
          value={values.status}
        />
      </div>

      <Button
        variant="solid"
        className="mb-0"
        loading={isLoading}
        disabled={!modulePermissions.hasPostPermission}
        onClick={() => handleCreatePortfolio()}
      >
        Crear portfolio
      </Button>
    </Card>
  )
}

PortfolioDetailCreateCard.propTypes = {
  setCardIsOpen: PropTypes.func.isRequired
}
