import { useDispatch, useSelector } from 'react-redux'
import { onAddPermissions, onResetSelectedRole } from 'app/features/permissions-slice'
import { onOpenModal } from 'app/features/ui.slice'
import { storeOrganizationRolePermissions } from 'services/organization-service'

export const useAssignPermissions = () => {
  const dispatch = useDispatch()
  const { data, permissions, selectedRole } = useSelector((state) => state.permission)

  const handleActivatePermission = (id, permissionId, target) => {
    const permissionsList = [...permissions]
    const existingIndex = permissionsList.findIndex((item) => item.moduleId === id && item.permissionId === permissionId)
    if (existingIndex === -1 && target.checked) {
      permissionsList.push({
        moduleId: id,
        permissionId,
        roleId: selectedRole.id,
        organizationId: data.organization.id
      })
    } else {
      permissionsList.splice(existingIndex, 1)
    }
    if (permissionId === 2 || permissionId === 3) {
      const existingGetIndex = permissionsList.findIndex((item) => item.moduleId === id && item.permissionId === 1)
      if (existingGetIndex === -1 && target.checked) {
        permissionsList.push({
          moduleId: id,
          permissionId: 1,
          roleId: selectedRole.id,
          organizationId: data.organization.id
        })
        const checkbox = document.getElementById(`select-all-${id}-${1}`)
        checkbox.checked = true
      }
    }

    if (permissionId === 1) {
      const existingPostIndex = permissionsList.findIndex((item) => item.moduleId === id && item.permissionId === 2)
      const existingPutIndex = permissionsList.findIndex((item) => item.moduleId === id && item.permissionId === 3)
      if (existingPostIndex === 0 && existingPutIndex === 0 && !target.checked) {
        permissionsList.splice(existingPostIndex, 1)
        const checkboxPost = document.getElementById(`select-all-${id}-${2}`)
        checkboxPost.checked = false
        permissionsList.splice(existingPutIndex, 1)
        const checkboxPut = document.getElementById(`select-all-${id}-${3}`)
        checkboxPut.checked = false
      }
      if (existingPostIndex === 0 && !target.checked) {
        permissionsList.splice(existingPostIndex, 1)
        const checkbox = document.getElementById(`select-all-${id}-${2}`)
        checkbox.checked = false
      }
      if (existingPutIndex === 0 && !target.checked) {
        permissionsList.splice(existingPutIndex, 1)
        const checkbox = document.getElementById(`select-all-${id}-${3}`)
        checkbox.checked = false
      }
    }
    dispatch(onAddPermissions(permissionsList))
  }

  const saveRoleOrganizationPermissions = async () => {
    try {
      const { data: response } = await storeOrganizationRolePermissions(permissions, selectedRole.id, data.organization.id)
      dispatch(onOpenModal({ message: response.message, type: 'SUCCESS' }))
      dispatch(onResetSelectedRole())
    } catch (err) {
      console.log(err)
      dispatch(onOpenModal({ message: err.response?.data.message ?? 'Ocurrió un error.', type: 'ERROR' }))
    }
  }

  return { handleActivatePermission, saveRoleOrganizationPermissions }
}
