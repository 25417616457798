import { organizationFullAdapter, statusAdapter, typeAdapter } from 'adapters'
import { onGetDataOrganization, onGetDetailOrganization } from 'app/features/organization.slice'
import { useDispatch } from 'react-redux'
import { getOrganizationDetail } from 'services/colibri.service'

export const useOrganization = () => {
  const dispatch = useDispatch()
  const getUserOrganizationDetail = async (key) => {
    try {
      const { data } = await getOrganizationDetail(key)
      const status = statusAdapter(data.response.status)
      const types = typeAdapter(data.response.types)
      const organization = organizationFullAdapter(data.response.organization)
      dispatch(onGetDataOrganization({ status, types }))
      dispatch(
        onGetDetailOrganization({
          entityId: organization.id,
          entityName: organization.name,
          otpRequired: organization.otpRequired,
          entityNIT: organization.key,
          entityStatus: organization.status,
          entityType: organization.type,
          portfolios: organization.portfolios,
          users: organization.users
        })
      )
      const checkbox = document.getElementById(`otp-${organization.id}-${organization.key}`)
      if (organization.otpRequired) {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { getUserOrganizationDetail }
}
