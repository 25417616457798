import { createAction, createSlice, current } from '@reduxjs/toolkit'

const currentDay = new Date()
currentDay.setHours(0, 0, 0, 0)
const nextDay = new Date()
nextDay.setDate(new Date().getDate() + 1)
nextDay.setHours(0, 0, 0, 0)

const initialState = {
  contracts: {
    loading: true,
    data: []
  },
  data: {
    banks: [],
    funds: [],
    portfolios: [],
    custodians: [],
    valuators: [],
    currencies: []
  },
  contract: {
    loading: true,
    status: '',
    approved: false,
    version: '',
    contractId: null,
    partA: {
      entity: null,
      portfolios: null,
      portfolio: null
    },
    partB: {
      entity: null,
      portfolios: null,
      portfolio: null
    },
    partContract: {
      date: currentDay,
      endDate: nextDay,
      valuator: null,
      custodianA: null,
      custodianB: null,
      currency: null,
      initialMargin: 0,
      independentAmount: 0,
      threshold: null,
      minTransferAmount: null
    },
    createdUser: {
      name: '',
      email: ''
    },
    approvedUser: {
      name: '',
      email: ''
    }
  }
}

export const revertAll = createAction('REVERT_ALL')

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    onStartLoadContracts: (state) => {
      state.contracts.loading = true
    },
    onLoadContracts: (state, { payload }) => {
      state.contracts.data = payload
    },
    onFinishLoadContracts: (state) => {
      state.contracts.loading = false
    },

    onStartContractLoading: (state) => {
      state.contract.loading = true
    },
    onFinishContractLoading: (state) => {
      state.contract.loading = false
    },
    onGetRelatedData: (state, { payload }) => {
      state.data.currencies = payload.currencies
      state.data.custodians = payload.custodians
      state.data.funds = payload.funds
      state.data.portfolios = payload.portfolios
      state.data.valuators = payload.valuators
    },

    onGetContractData: (state, { payload }) => {
      state.contract.approved = payload.isApproved === 'true'
      state.contract.contractId = payload.contractId
      state.contract.createdUser = payload.createdUser
      state.contract.approvedUser = payload.approvedUser
    },
    onViewContract: (state, { payload }) => {
      state.contract.approved = payload.approved === 'true'
      state.contract.contractId = payload.contractId
      state.contract.partA.entity = payload.entityA
      const portfolios = [...current(state.data.portfolios)]

      const funds = [...current(state.data.funds)]
      const valuators = [...current(state.data.valuators)]
      const custodians = [...current(state.data.custodians)]
      const currencies = [...current(state.data.currencies)]
      const portfolioA = portfolios.find((el) => el.NIT === payload.APortfolioNIT)
      state.contract.partA.portfolio = portfolioA
      const fund = funds.find((el) => el.key === payload.BEntityKey)
      state.contract.partB.entity = fund
      state.contract.partB.portfolios = fund.portfolios
      state.contract.partB.portfolio = null
      state.contract.partContract.date = payload.CSADate
      state.contract.partContract.date.setHours(0, 0, 0, 0)
      state.contract.partContract.endDate = payload.finishDate
      state.contract.partContract.endDate.setHours(0, 0, 0, 0)
      const valuator = valuators.find((el) => el.key === payload.valuatorKey)
      state.contract.partContract.valuator = valuator
      const custodianA = custodians.find((el) => el.key === payload.ACustodianKey)
      state.contract.partContract.custodianA = custodianA
      const custodianB = custodians.find((el) => el.key === payload.BCustodianKey)
      state.contract.partContract.custodianB = custodianB
      const currency = currencies.find((el) => el.key === payload.currencyKey)
      state.contract.partContract.currency = currency
      state.contract.partContract.initialMargin = payload.initialMargin
      state.contract.partContract.independentAmount = payload.independentAmount
      state.contract.partContract.threshold = payload.threshold
      state.contract.partContract.minTransferAmount = payload.minTransferAmount

      state.contract.createdUser = payload.createdUser
      state.contract.approvedUser = payload.approvedUser
    },

    onSelectContract: (state, { payload }) => {
      state.contract.contractId = payload
    },

    onSelectEntityA: (state, { payload }) => {
      state.contract.partA.entity = payload
    },
    onChangePartA: (state, { payload }) => {
      const portfolios = [...current(state.data.portfolios)]
      const portfolioA = portfolios.find((el) => el.NIT === payload)
      state.contract.partA.portfolio = portfolioA
    },
    onSelectEntityB: (state, { payload }) => {
      const funds = [...current(state.data.funds)]
      const fund = funds.find((el) => el.key === payload)
      state.contract.partB.entity = fund
      state.contract.partB.portfolios = fund.portfolios
      state.contract.partB.portfolio = null
    },

    onChangePartB: (state, { payload }) => {
      const portfolios = [...current(state.contract.partB.portfolios)]
      const portfolio = portfolios.find((el) => el.NIT === payload)
      state.contract.partB.portfolio = portfolio
    },
    // ---- CONTRACT ------------
    onChangeContractDate: (state, { payload }) => {
      state.contract.partContract.date = payload
      state.contract.partContract.date.setHours(0, 0, 0, 0)
    },

    onChangeEndContractDate: (state, { payload }) => {
      state.contract.partContract.endDate = payload
      state.contract.partContract.endDate.setHours(0, 0, 0, 0)
    },
    onChangeContractValuator: (state, { payload }) => {
      const valuators = [...current(state.data.valuators)]
      const valuator = valuators.find((el) => el.key === payload)
      state.contract.partContract.valuator = valuator
    },
    onChangeContractCustodianA: (state, { payload }) => {
      const custodians = [...current(state.data.custodians)]
      const custodianA = custodians.find((el) => el.key === payload)
      state.contract.partContract.custodianA = custodianA
    },
    onChangeContractCustodianB: (state, { payload }) => {
      const custodians = [...current(state.data.custodians)]
      const custodianB = custodians.find((el) => el.key === payload)
      state.contract.partContract.custodianB = custodianB
    },

    onChangeContractCurrency: (state, { payload }) => {
      const currencies = [...current(state.data.currencies)]
      const currency = currencies.find((el) => el.key === payload)
      state.contract.partContract.currency = currency
    },

    onChangeContractInitialMargin: (state, { payload }) => {
      state.contract.partContract.initialMargin = payload
    },

    onChangeContractIndependentAmount: (state, { payload }) => {
      state.contract.partContract.independentAmount = payload
    },

    onChangeContractThreshold: (state, { payload }) => {
      state.contract.partContract.threshold = payload
    },

    onChangeContractMinTransferAmount: (state, { payload }) => {
      state.contract.partContract.minTransferAmount = payload
    },
    onResetCreateContract: (state) => {
      state.contract.partA = initialState.contract.partA
      state.contract.partB = initialState.contract.partB
      state.contract.partContract = initialState.contract.partContract
      state.contract.contractId = initialState.contract.contractId
      state.contract.approved = initialState.contract.approved
    },

    onGetDataApproveContract: (state, { payload }) => {
      state.contract.status = payload.status
      state.contract.partContract.contractId = payload.contractId
      state.contract.partContract.approved = payload.approved !== 'false'

      state.contract.partA.entity = payload.entityA
      state.contract.partA.portfolio = payload.portfolioA

      state.contract.partB.entity = payload.entityB
      state.contract.partB.portfolio = payload.portfolioB

      state.contract.partContract.date = payload.date
      state.contract.partContract.date.setHours(0, 0, 0, 0)
      state.contract.partContract.endDate = payload.endDate
      state.contract.partContract.endDate.setHours(0, 0, 0, 0)
      state.contract.partContract.valuator = payload.valuator
      state.contract.partContract.custodianA = payload.custodianA
      state.contract.partContract.custodianB = payload.custodianB
      state.contract.partContract.currency = payload.currency
      state.contract.partContract.initialMargin = payload.initialMargin
      state.contract.partContract.independentAmount = payload.independentAmount
      state.contract.partContract.threshold = payload.threshold
      state.contract.partContract.minTransferAmount = payload.minTransferAmount

      state.contract.createdUser = payload.createdUser
      state.contract.approvedUser = payload.approvedUser
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onStartLoadContracts,
  onLoadContracts,
  onFinishLoadContracts,

  onStartContractLoading,
  onFinishContractLoading,
  onGetRelatedData,
  onSelectEntityA,
  onChangePartA,
  onSelectEntityB,
  onChangePartB,
  onChangeContractDate,
  onChangeEndContractDate,
  onChangeContractValuator,
  onChangeContractCustodianA,
  onChangeContractCustodianB,
  onChangeContractCurrency,
  onChangeContractInitialMargin,
  onChangeContractIndependentAmount,
  onChangeContractThreshold,
  onChangeContractMinTransferAmount,

  onGetContractData,
  onSelectContract,
  onResetCreateContract,
  onViewContract,

  onGetDataApproveContract
} = contractSlice.actions
