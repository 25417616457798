import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  modulePermissions: {
    data: [],
    hasPutPermission: false,
    hasPostPermission: false
  },
  data: {
    roles: [],
    modules: [],
    organization: null,
    loading: false
  },
  permissions: [],
  selectedRole: null
}

export const revertAll = createAction('REVERT_ALL')

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    onGetPermissionData: (state, { payload }) => {
      state.data.modules = payload.modules
      state.data.permissions = payload.permissions
      state.data.roles = payload.roles
      state.data.organization = payload.organization
    },
    onHandleSelectedRole: (state, { payload }) => {
      const roles = [...current(state.data.roles)]
      const selectedRole = roles.find((el) => el.key === payload)
      state.selectedRole = selectedRole
    },
    onResetSelectedRole: (state, { payload }) => {
      state.selectedRole = initialState.selectedRole
    },
    onAddPermissions: (state, { payload }) => {
      state.permissions = payload
    },
    onResetPermissions: (state, { payload }) => {
      state.permissions = initialState.permissions
    },
    onCheckModulePermissions: (state, { payload }) => {
      const permissions = { data: payload, hasPutPermission: false, hasPostPermission: false }
      payload.forEach((element) => {
        if (element.method === 'POST') {
          permissions.hasPostPermission = true
        }
        if (element.method === 'PUT') {
          permissions.hasPutPermission = true
        }
      })
      return { ...state, modulePermissions: permissions }
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onGetPermissionData,
  onHandleSelectedRole,
  onResetSelectedRole,
  onAddPermissions,
  onResetPermissions,
  onCheckModulePermissions
} = permissionSlice.actions
