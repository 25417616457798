export const isinAdapter = (data) => {
  return data
    .map((el) => ({
      value: el.id,
      label: el.key,
      key: el.key,
      divisible: el.divisible
    }))
    .sort((a, b) => {
      if (a.key < b.key) return -1
      if (a.key > b.key) return 1
      return 0
    })
    .sort((a, b) => {
      if (a.divisible < b.divisible) return 1
      if (a.divisible > b.divisible) return -1
      return 0
    })
}
