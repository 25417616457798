export const usersAdapter = (data) => {
  return data.map((el) => {
    const formattedCreatedAt = `${new Date(el.createdAt).toLocaleDateString()} - ${new Date(
      el.createdAt
    ).toLocaleTimeString()}`

    const formattedLastLogin = `${new Date(el.lastLogin).toLocaleDateString()} - ${new Date(
      el.lastLogin
    ).toLocaleTimeString()}`
    return {
      value: el.id,
      key: el.email,
      label: el.name,
      email: el.email,
      lastIp: el.lastIp,
      status: el.status.key,
      createdAt: el.createdAt,
      formattedCreatedAt,
      lastLogin: el.lastLogin,
      formattedLastLogin
    }
  })
}
