import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Dialog, Input, Select } from 'components/ui'
import { onChangeUserEmail, onChangeUserName, onChangeUserStatus, onGetOrganizationUsers, onResetSelectedUser } from 'app/features/organization.slice'
import { useState } from 'react'
import { updateAuthUserService, updateUserService } from 'services/colibri.service'
import { usersAdapter } from 'adapters/core/users.adapter'

export const UserDetailEditCard = () => {
  const { statusTypes, input } = useSelector((state) => state.organization)
  const { user, entity } = input
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleUpdateUser = async () => {
    setIsLoading(true)

    const authCredentials = { name: user.name, email: user.email, provider_id: 1, copy_email: user.selectedUser.email }
    const { status } = await updateAuthUserService(authCredentials)
    if (status === 200) {
      const dataToUpdate = {
        organizationId: entity.id,
        id: user.selectedUser.value,
        name: user.name,
        email: user.email,
        status: user.status.value
      }
      const { data } = await updateUserService(dataToUpdate)
      openDialog(data.message)
      const users = usersAdapter(data.data)
      dispatch(onGetOrganizationUsers(users))
    }
  }

  const openDialog = (message) => {
    setMessage(message)
    setModalOpen(true)
  }

  const onDialogOk = (e) => {
    setModalOpen(false)
    setMessage(null)
    setIsLoading(false)
    dispatch(onResetSelectedUser())
  }

  return (
    <Card className="mb-8">
      <div className="mb-8">
        <h5 className="mb-2">Nombre de usuario</h5>
        <Input
          placeholder="Ingrese nombre completo de usuario"
          type="text"
          disabled={isLoading}
          onChange={({ target }) => dispatch(onChangeUserName(target.value))}
          value={user.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Correo electrónico</h5>
        <Input
          placeholder="Ingrese correo electrónico de usuario"
          type="text"
          disabled={isLoading}
          onChange={({ target }) => dispatch(onChangeUserEmail(target.value))}
          value={user.email}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Estado</h5>
        <Select
          placeholder="Seleccione estado de usuario"
          options={statusTypes}
          isDisabled={isLoading}
          onChange={(value) => dispatch(onChangeUserStatus(value.key))}
          value={user.status}
        />
      </div>

      <Button
        variant="solid"
        className="mb-0"
        disabled={isLoading}
        onClick={() => handleUpdateUser()}
      >
        Actualizar usuario
      </Button>

      <Dialog
        isOpen={modalOpen}
        closable={false}
      >
        <h5 className="mb-4">Respuesta</h5>
        <p>{message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </Card>
  )
}

UserDetailEditCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  handleUpdateUser: PropTypes.func.isRequired
}
