import { useDispatch } from 'react-redux'
import {
  onSetContractValuatorList,
  onSetSelectedContractValuator,
  onSetGuaranteeValuatorList,
  onSetSelectedGuaranteeValuator

} from 'app/features/valuator.slice'

export const useAssignValuator = () => {
  const dispatch = useDispatch()

  const setSelectedGuaranteeValuator = async (value) => {
    dispatch(onSetSelectedGuaranteeValuator(value))
  }

  const setSelectedContractValuator = async (value) => {
    dispatch(onSetSelectedContractValuator(value))
  }

  const setGuaranteeValuatorList = async (list) => {
    dispatch(onSetGuaranteeValuatorList(list))
  }

  const setContractValuatorList = async (list) => {
    dispatch(onSetContractValuatorList(list))
  }

  return {
    setSelectedGuaranteeValuator,
    setSelectedContractValuator,
    setGuaranteeValuatorList,
    setContractValuatorList
  }
}
