import { useDispatch, useSelector } from 'react-redux'
import { DropDataTable } from 'components/dataTable/DropDataTable'
import { Button, Card, Input } from 'components/ui'
import dataTableAddUser from 'features/organizations/json/dataTableAddUser.json'
import { onAddLocalUser, onChangeUserEmail, onChangeUserName, onRemoveLocalUser } from 'app/features/organization.slice'

export const CreateUserCard = () => {
  const { users, input } = useSelector((state) => state.organization)
  const { user } = input
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()

  const handleCreateUser = () => {
    const obj = {
      name: user.name,
      email: user.email,
      provider_id: 1,
      status: 'PEN'
    }
    const emailExist = users.find((el) => el.email === user.email)
    if (!emailExist) {
      dispatch(onAddLocalUser(obj))
    }
  }

  const onRemoveUserFromDataTable = (e, selectedFlatRows) => {
    const totalUsers = [...users]
    for (let index = selectedFlatRows.length - 1; index >= 0; index--) {
      totalUsers.splice(selectedFlatRows[index].index, 1)
    }
    dispatch(onRemoveLocalUser(totalUsers))
  }

  return (
    <Card className="mb-8">
      <h4 className="mb-8">Agregar usuario</h4>
      <div className="mb-8">
        <h5 className="mb-2">Nombre del usuario</h5>
        <Input
          placeholder="Ingrese nombre del usuario"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangeUserName(target.value))}
          value={user.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Correo electrónico</h5>
        <Input
          placeholder="Ingrese correo electrónico"
          type="email"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangeUserEmail(target.value))}
          value={user.email}
        />
      </div>
      <Button
        variant="solid"
        className="mb-8"
        disabled={user.name === null || user.email === null || !modulePermissions.hasPostPermission}
        onClick={handleCreateUser}
      >
        Añadir usuario
      </Button>
      <DropDataTable
        columns={dataTableAddUser}
        data={users}
        onDropSelectedItems={onRemoveUserFromDataTable}
      />
    </Card>
  )
}
