import { useRef } from 'react'
import { Input } from 'components/ui'
import PropTypes from 'prop-types'

function InputPercentage (props) {
  const inputRef = useRef(null)
  const movePointer = () => {
    const inputElement = inputRef.current
    const currentPosition = inputElement.selectionStart
    if (currentPosition > String(props.value).length) {
      inputElement.setSelectionRange(String(props.value).length, String(props.value).length)
    }
  }

  const handleChangePercentage = ({ target }, onChange) => {
    const stringValue = target.value.replace(/[%\s]/g, '')
    const value = Number(stringValue)
    onChange({ target: { value } })
    setTimeout(() => {
      const inputElement = inputRef.current
      const currentPosition = inputElement.selectionStart
      if (currentPosition > stringValue.length) {
        inputElement.setSelectionRange(stringValue.length, stringValue.length)
      }
    }, 100)
  }

  return (
    <Input
      ref={inputRef}
      placeholder={ props.placeholder }
      type="text"
      value={ props.value + ' %' }
      disabled={ props.disabled }
      onChange={(e) => handleChangePercentage(e, props.onChange)}
      onClick={movePointer}
    >
    </Input>
  )
}

InputPercentage.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default InputPercentage
