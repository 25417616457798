import { backendBaseURL } from 'interceptors/backend.interceptor'

export const bankDashboardDataService = async () => {
  return await backendBaseURL.get('/api/collaterals/entity/assigned')
}

export const getBankContracts = async (organizationKey) => {
  return await backendBaseURL.get(`/api/contracts/bank/${organizationKey}`)
}

export const getBankDataLinked = async (organizationId) => {
  return await backendBaseURL.get(`/api/contracts/data-create/${organizationId}`)
}

export const createBankContract = async (partA, partB, partContract) => {
  return await backendBaseURL.post('/api/contracts/create', { partA, partB, partContract })
}

export const getBankContractDataLinked = async (uuid) => {
  return await backendBaseURL.get(`/api/contracts/bank/contract/${uuid}`)
}

export const createContract = async (partA, partB, partContract) => {
  return await backendBaseURL.post('/api/v2/bank/update-contract', { partA, partB, partContract })
}

export const updateContract = async (contractId, partA, partB, partContract) => {
  return await backendBaseURL.put('/api/contracts/update', { contractId, partA, partB, partContract })
}
export const getCollateralHistory = async (id) => {
  return await backendBaseURL.get(`/api/collaterals/history/${id}`)
}
