import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const Logo = () => {
  const sideNavCollapse = useSelector((state) => state.theme.layout.sideNavCollapse)

  return (
    <Link
      to={'/'}
      className={` ${
        sideNavCollapse ? 'p-2 m-3' : 'p-5 m-5'
      }  bg-neutral-900 rounded-xl flex items-center justify-center`}
    >
      <img
        className={`${sideNavCollapse ? 'w-8  aspect-square' : 'w-full h-full '}`}
        src={`/img/logo/logo-dark-${sideNavCollapse ? 'streamline' : 'full'}.png`}
        alt="marketxm-logo"
      />
    </Link>
  )
}
