import { formatToCurrency } from 'helpers/formatToCurrency'

export const collateralsAdapter = (data) => {
  return data.map((item) => {
    const formattedCollateralReceived = formatToCurrency(item.Collateral_Received, 'es-CO', 'COP')
    const formattedFaceValue = formatToCurrency(item.Face_Value, 'es-CO', 'COP')
    const date = `${new Date(item.Date).toLocaleDateString()} - ${new Date(item.Date).toLocaleTimeString()}`
    const formattedMarginCall = item.Margin_Call !== 'No' ? formatToCurrency(item.Margin_Call, 'es-CO', 'COP') : item.Margin_Call
    return {
      collateralReceived: item.Collateral_Received,
      formattedCollateralReceived,
      custodianId: item.Custodian_Id,
      custodianName: item.Custodian_Name,
      entityId: item.Entity_Id,
      entityName: item.Entity_Name,
      entityType: item.Entity_Type,
      formattedFaceValue,
      faceValue: item.Face_Value,
      guaranteeRegisterId: item.Guarantee_Register_Id,
      guarantees: item.Guarantes_Types,
      portfolioName: item.Portfolio_name,
      portfolioNIT: item.Portfolio_nit,
      portfolioLEI: item.portfolio_lei,
      date,
      formattedMarginCall
    }
  })
}
