import { useDispatch, useSelector } from 'react-redux'
import { onChangeCollateralsData, onChangeSelectedPortfolio, onFinishCollateralsLoading, onStartCollateralsLoading } from 'app/features/ui.slice'
import { formatToCurrency } from 'helpers/formatToCurrency'
import { assignedCollateralsAdapter } from 'adapters/core/assignedCollaterals.adapter'
import { getCollateralsByPortfolioNIT } from 'services/collateral.service'

export const useCalculateCollaterals = () => {
  const { dashboard } = useSelector((state) => state.ui)
  const { contracts } = dashboard

  const dispatch = useDispatch()

  const onSelectPortfolio = async (value) => {
    dispatch(onStartCollateralsLoading())
    const { NIT } = value
    dispatch(onChangeSelectedPortfolio(NIT))
    const { data } = await getCollateralsByPortfolioNIT(NIT)
    if (data.response.length === 0) {
      dispatch(
        onChangeCollateralsData({
          eligibleCollateral: '$ 0,00',
          totalCollaterals: '$ 0,00',
          blockedCollateral: '$ 0,00',
          availableCollateral: '$ 0,00',
          receivedCollateral: '$ 0,00',
          deliveredCollateral: '$ 0,00',
          marginCall: null
        })
      )
      dispatch(onFinishCollateralsLoading())
    } else {
      const formattedCollaterals = assignedCollateralsAdapter(data.response)
      const totalCollateral = formattedCollaterals.reduce((acc, el) => acc + parseFloat(el.collateralReceived), 0)
      const totalFaceValue = formattedCollaterals.reduce((acc, el) => acc + parseFloat(el.faceValue), 0)
      const formattedTotalCollaterals = formatToCurrency(totalCollateral, 'es-CO', 'COP')
      const formattedTotalFaceValue = formatToCurrency(totalFaceValue, 'es-CO', 'COP')
      const blockedCollateral =
        (Number(contracts[0].initialMargin) / 100) * totalCollateral + (Number(contracts[0].independentAmount) / 100) * totalCollateral
      const formattedBlockedCollateral = formatToCurrency(blockedCollateral, 'es-CO', 'COP')
      const availableCollateral = totalCollateral - blockedCollateral
      const formattedAvailableCollateral = formatToCurrency(availableCollateral, 'es-CO', 'COP')
      const marginCall = formattedCollaterals.find((value) => value.portfolioNIT === NIT).marginCall !== 'No'

      const totalReceivedCollateral = formattedCollaterals.reduce(
        (acc, el) => acc + el.guaranteesTypes.reduce((acc2, el2) => acc2 + parseFloat(el2.collateralReceived), 0),
        0
      )
      const totalDeliveredCollateral = formattedCollaterals.reduce(
        (acc, el) => acc + el.guaranteesTypes.reduce((acc2, el2) => acc2 + parseFloat(el2.collateralDelivered), 0),
        0
      )
      const formattedTotalReceivedCollateral = formatToCurrency(totalReceivedCollateral, 'es-CO', 'COP')
      const formattedTotalDeliveredCollateral = formatToCurrency(totalDeliveredCollateral, 'es-CO', 'COP')

      dispatch(
        onChangeCollateralsData({
          eligibleCollateral: formattedTotalFaceValue,
          totalCollaterals: formattedTotalCollaterals,
          blockedCollateral: formattedBlockedCollateral,
          availableCollateral: formattedAvailableCollateral,
          receivedCollateral: formattedTotalReceivedCollateral,
          deliveredCollateral: formattedTotalDeliveredCollateral,
          marginCall
        })
      )
      dispatch(onFinishCollateralsLoading())
    }
  }

  return { onSelectPortfolio }
}
