import { createAction, createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  statusTypes: [],
  organizationTypes: [],
  portfolios: [],
  users: [],
  organizations: [],
  input: {
    entity: {
      id: null,
      name: null,
      NIT: null,
      type: null,
      status: null,
      createdAt: null,
      otpRequired: false
    },
    portfolio: {
      selectedPortfolio: null,
      name: null,
      NIT: null,
      LEI: null,
      status: null,
      createdAt: null
    },
    user: {
      selectedUser: null,
      name: null,
      email: null,
      status: null,
      createdAt: null
    }
  }
}

export const revertAll = createAction('REVERT_ALL')

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    onResetCreateOrganizationView: (state) => {
      state.input = initialState.input
      state.portfolios = initialState.portfolios
      state.users = initialState.users
    },
    onResetDetailView: (state) => {
      state.input = initialState.input
    },
    onGetOrganizations: (state, { payload }) => {
      state.organizations = payload
    },
    onGetDataOrganization: (state, { payload }) => {
      state.organizationTypes = payload.types
      state.statusTypes = payload.status
    },
    onGetDetailOrganization: (state, { payload }) => {
      state.input.entity.id = payload.entityId
      state.input.entity.name = payload.entityName
      state.input.entity.NIT = payload.entityNIT
      state.input.entity.otpRequired = payload.otpRequired
      // state.input.entity.createdAt = payload.entityCreatedAt
      const types = [...current(state.organizationTypes)]
      const selectedType = types.find((el) => el.key === payload.entityType)
      state.input.entity.type = selectedType
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === payload.entityStatus)
      state.input.entity.status = selectedStatus
      state.portfolios = payload.portfolios
      state.users = payload.users
    },
    onGetOrganizationUsers: (state, { payload }) => {
      state.users = payload
    },
    onGetOrganizationPortfolios: (state, { payload }) => {
      state.portfolios = payload
    },

    onChangeEntityName: (state, { payload }) => {
      state.input.entity.name = payload
    },
    onChangeEntityNIT: (state, { payload }) => {
      state.input.entity.NIT = payload
    },
    onChangeEntityType: (state, { payload }) => {
      const types = [...current(state.organizationTypes)]
      const selectedType = types.find((el) => el.key === payload)
      state.input.entity.type = selectedType
    },
    onChangeEntityStatus: (state, { payload }) => {
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === payload)
      state.input.entity.status = selectedStatus
    },
    onChangeOTPRequired: (state, { payload }) => {
      state.input.entity.otpRequired = !state.input.entity.otpRequired
    },

    onSearchSelectedUser: (state, { payload }) => {
      const users = [...current(state.users)]
      const user = users.find((el) => el.key === payload)
      state.input.user.selectedUser = user
      state.input.user.name = user.label
      state.input.user.email = user.email
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === user.status)
      state.input.user.status = selectedStatus
      state.input.user.createdAt = user.createdAt
    },
    onResetSelectedUser: (state) => {
      state.input.user = initialState.input.user
    },
    onChangeUserName: (state, { payload }) => {
      state.input.user.name = payload
    },
    onChangeUserEmail: (state, { payload }) => {
      state.input.user.email = payload
    },
    onChangeUserStatus: (state, { payload }) => {
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === payload)
      state.input.user.status = selectedStatus
    },
    onAddLocalUser: (state, { payload }) => {
      state.users.push(payload)
      state.input.user.name = initialState.input.user.name
      state.input.user.email = initialState.input.user.email
      state.input.user.status = initialState.input.user.status
    },
    onRemoveLocalUser: (state, { payload }) => {
      state.users = payload
    },

    onSearchSelectedPortfolio: (state, { payload }) => {
      const portfolios = [...current(state.portfolios)]
      const portfolio = portfolios.find((el) => el.key === payload)
      state.input.portfolio.selectedPortfolio = portfolio
      state.input.portfolio.name = portfolio.label
      state.input.portfolio.NIT = portfolio.NIT
      state.input.portfolio.LEI = portfolio.LEI
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === portfolio.status)
      state.input.portfolio.status = selectedStatus
      state.input.portfolio.createdAt = portfolio.createdAt
    },
    onResetSelectedPortfolio: (state) => {
      state.input.portfolio = initialState.input.portfolio
    },
    onChangePortfolioName: (state, { payload }) => {
      state.input.portfolio.name = payload
    },
    onChangePortfolioNIT: (state, { payload }) => {
      state.input.portfolio.NIT = payload
    },
    onChangePortfolioLEI: (state, { payload }) => {
      state.input.portfolio.LEI = payload
    },
    onChangePortfolioStatus: (state, { payload }) => {
      const status = [...current(state.statusTypes)]
      const selectedStatus = status.find((el) => el.key === payload)
      state.input.portfolio.status = selectedStatus
    },
    onAddLocalPortfolio: (state, { payload }) => {
      state.portfolios.push(payload)
      state.input.portfolio.name = initialState.input.portfolio.name
      state.input.portfolio.LEI = initialState.input.portfolio.LEI
      state.input.portfolio.NIT = initialState.input.portfolio.NIT
      state.input.portfolio.status = initialState.input.portfolio.status
    },
    onRemoveLocalPortfolio: (state, { payload }) => {
      state.portfolios = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onResetCreateOrganizationView,
  onResetDetailView,

  onGetOrganizations,
  onGetDataOrganization,
  onGetDetailOrganization,
  onGetOrganizationUsers,
  onGetOrganizationPortfolios,

  onChangeEntityName,
  onChangeEntityNIT,
  onChangeEntityType,
  onChangeEntityStatus,
  onChangeOTPRequired,

  onSearchSelectedUser,
  onResetSelectedUser,
  onChangeUserName,
  onChangeUserEmail,
  onChangeUserStatus,
  onAddLocalUser,
  onRemoveLocalUser,

  onSearchSelectedPortfolio,
  onResetSelectedPortfolio,
  onChangePortfolioName,
  onChangePortfolioNIT,
  onChangePortfolioLEI,
  onChangePortfolioStatus,
  onAddLocalPortfolio,
  onRemoveLocalPortfolio
} = organizationSlice.actions
