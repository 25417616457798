/* eslint-disable multiline-ternary */
import { SelectSkeleton } from 'components/skeleton/SelectSkeleton'
import { Card, Input, Select } from 'components/ui'
import { useEditContract } from 'hooks/useEditContract'
import { useSelector } from 'react-redux'

export const PartBEditContractCard = () => {
  const { contract, data } = useSelector((state) => state.contract)
  const { loading, partB, approved } = contract
  const { modulePermissions } = useSelector((state) => state.permission)
  const { handlePortfolioB, handleChangeEntityB, btnLoading } = useEditContract()

  return (
    <Card className="mb-8">
      <h3 className="mb-4">Parte B</h3>
      <div className="mb-8">
        <h5 className="mb-2">Entidad</h5>
        <p className="mb-2">Seleccione entidad correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={data.funds}
            isDisabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            value={partB.entity}
            onChange={handleChangeEntityB}
          />
        )}
      </div>

      <div className="mb-8">
        <h5 className="mb-2">Portafolio</h5>
        <p className="mb-2">Seleccione portafolio correspondiente:</p>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Select
            placeholder="Seleccione por favor"
            options={partB.portfolios}
            value={partB.portfolio}
            isDisabled={approved || btnLoading || !modulePermissions.hasPutPermission}
            onChange={handlePortfolioB}
          />
        )}
      </div>
      <div className="mb-8">
        <h5 className="mb-2">LEI</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="LEI"
            type="text"
            disabled={true}
            value={partB.portfolio ? partB.portfolio.LEI : ''}
          />
        )}
      </div>

      <div>
        <h5 className="mb-2">NIT</h5>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="NIT"
            type="text"
            disabled={true}
            value={partB.portfolio ? partB.portfolio.NIT : ''}
          />
        )}
      </div>
    </Card>
  )
}
