import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Input, Select } from 'components/ui'
import {
  onChangePortfolioLEI,
  onChangePortfolioNIT,
  onChangePortfolioName,
  onChangePortfolioStatus,
  onGetOrganizationPortfolios,
  onResetSelectedPortfolio
} from 'app/features/organization.slice'
import { onOpenModal } from 'app/features/ui.slice'
import { portfoliosAdapter } from 'adapters/core/portfolios.adapter'
import { updatePortfolioService } from 'services/colibri.service'

export const PortfolioDetailEditCard = () => {
  const { statusTypes, input } = useSelector((state) => state.organization)
  const { portfolio, entity } = input
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  const handleUpdatePortfolio = async () => {
    const dataToUpdate = {
      organizationId: entity.id,
      id: portfolio.selectedPortfolio.value,
      name: portfolio.name,
      NIT: portfolio.NIT,
      LEI: portfolio.LEI,
      status: portfolio.status.value
    }
    try {
      setIsLoading(true)
      const { data } = await updatePortfolioService(dataToUpdate)
      const portfolios = portfoliosAdapter(data.data)
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
      dispatch(onGetOrganizationPortfolios(portfolios))
      dispatch(onResetSelectedPortfolio())
      setIsLoading(false)
    } catch (error) {
      dispatch(onOpenModal({ message: 'Algo salio mal', type: 'ERROR' }))
    }
  }

  return (
    <Card className="mb-8">
      <div className="mb-8">
        <h5 className="mb-2">Nombre de portafolio</h5>
        <Input
          placeholder="Ingrese nombre de portafolio"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={({ target }) => dispatch(onChangePortfolioName(target.value))}
          value={portfolio.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="Ingrese NIT de organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={({ target }) => dispatch(onChangePortfolioNIT(target.value))}
          value={portfolio.NIT}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">LEI</h5>
        <Input
          placeholder="Ingrese LEI de organización"
          type="text"
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={({ target }) => dispatch(onChangePortfolioLEI(target.value))}
          value={portfolio.LEI}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Estado</h5>
        <Select
          placeholder="Seleccione estado de la organización"
          options={statusTypes}
          loading={isLoading}
          disabled={!modulePermissions.hasPutPermission}
          onChange={(value) => dispatch(onChangePortfolioStatus(value.key))}
          value={portfolio.status}
        />
      </div>
      <Button
        variant="solid"
        className="mb-0"
        loading={isLoading}
        disabled={!modulePermissions.hasPutPermission}
        onClick={() => handleUpdatePortfolio()}
      >
        Actualizar portafolio
      </Button>
    </Card>
  )
}
