import { FilteringTableWithInput } from 'components/table/FilteringTableWithInput'
import { useSelector } from 'react-redux'

const columns = [
  {
    Header: 'Fecha',
    accessor: 'formattedInitDate'
  },
  {
    Header: 'ISIN',
    accessor: 'guaranteeType'
  },
  {
    Header: 'Monto',
    accessor: 'amount'
  },
  {
    Header: 'Haircut',
    accessor: 'haircut'
  },
  {
    Header: 'Colateral disponible',
    accessor: 'collateral'
  },
  {
    Header: 'Colateral a retirar',
    accessor: 'collateral_withdraw'
  }
]

export const WithdrawGuaranteesTable = () => {
  const { guarantees } = useSelector((state) => state.custodian)
  return (
    <FilteringTableWithInput
      columns={columns}
      data={guarantees.selectPortfolio ? guarantees.selectPortfolio.guarantees : []}
    />
  )
}
