import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, Input } from 'components/ui'
import { createUserService } from 'services/colibri.service'
import { onGetOrganizationUsers } from 'app/features/organization.slice'
import { usersAdapter } from 'adapters/core/users.adapter'
import { createAuthProviderUser } from 'services/user/user.services'
import { onOpenModal } from 'app/features/ui.slice'

export const UserDetailCreateCard = ({ setCardIsOpen }) => {
  const { input } = useSelector((state) => state.organization)
  const { entity } = input
  const [values, setValues] = useState({ name: '', email: '' })
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleChange = (ev) => {
    setValues({ ...values, [ev.target.name]: ev.target.value })
  }

  const handleCreateUser = async () => {
    try {
      setIsLoading(true)
      const dataToSend = { ...values, provider_id: 1, organizationId: entity.id }
      const { status } = await createAuthProviderUser(dataToSend)
      if (status === 200) {
        const { data } = await createUserService(dataToSend)
        const users = usersAdapter(data.data)
        dispatch(onGetOrganizationUsers(users))
        dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
        setIsLoading(false)
        setCardIsOpen(false)
      }
    } catch (err) {
      setIsLoading(false)
      dispatch(onOpenModal({ message: err?.response.data.message ?? 'Error: fallo al crear usuario.', type: 'ERROR' }))
    }
  }

  return (
    <Card className="mt-8">
      <div className="mb-8">
        <h5 className="mb-2">Nombre de usuario</h5>
        <Input
          placeholder="Ingrese nombre completo de usuario"
          type="text"
          name="name"
          disabled={isLoading}
          onChange={(e) => handleChange(e)}
          value={values.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Correo electrónico</h5>
        <Input
          placeholder="Ingrese correo electrónico de usuario"
          type="text"
          name="email"
          disabled={isLoading}
          onChange={(e) => handleChange(e)}
          value={values.email}
        />
      </div>
      <Button
        variant="solid"
        className="mb-0"
        disabled={values.email.length === 0 || values.name.length === 0 || isLoading}
        onClick={() => handleCreateUser()}
      >
        Crear usuario
      </Button>
    </Card>
  )
}

UserDetailCreateCard.propTypes = {
  setCardIsOpen: PropTypes.func.isRequired
}
