/* eslint-disable multiline-ternary */
import PropTypes from 'prop-types'
import Td from 'components/ui/Table/Td'
import { Button } from 'components/ui'
import { useNavigate } from 'react-router-dom'

export const ButtonsRow = ({ cell, column, row, index }) => {
  const navigate = useNavigate()
  return (
    <Td {...cell.getCellProps()}>
      {index === column.cells.length - 2 ? (
        <Button onClick={() => navigate(`/colibri/detail/organization/${column.original.key}`)}>
          Editar organización
        </Button>
      ) : (
        cell.render('Cell')
      )}
      {index === column.cells.length - 1 && (
        <Button onClick={() => navigate(`/colibri/permissions/${column.original.key}`)}>Editar permisos</Button>
      )}
    </Td>
  )
}

ButtonsRow.propTypes = {
  cell: PropTypes.any.isRequired,
  column: PropTypes.any.isRequired,
  row: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
}
