import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card } from 'components/ui'
import { onResetSelectedPortfolio, onSearchSelectedPortfolio } from 'app/features/organization.slice'
import portfolioDataTableHeaders from 'features/organizations/json/portfolioDataTableHeaders.json'
import { SelectDataTable } from 'components/dataTable'
import { PortfolioDetailCreateCard } from '../../../../features/organizations/components/PortfolioDetailCreateCard'
import { PortfolioDetailEditCard } from '../../../../features/organizations/components/PortfolioDetailEditCard'

export const PortfolioDetailCard = () => {
  const { portfolios, input } = useSelector((state) => state.organization)
  const { portfolio, entity } = input
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()

  const [cardIsOpen, setCardIsOpen] = useState(false)

  const onSelectItem = (e, row) => {
    dispatch(onSearchSelectedPortfolio(row.original.key))
    setCardIsOpen(false)
  }

  const onOpenCard = () => {
    dispatch(onResetSelectedPortfolio())
    setCardIsOpen(!cardIsOpen)
  }

  return (
    <Card className="mb-8">
      <div className="mb-8">
        <h4 className="mb-2">Portafolios</h4>
        <SelectDataTable
          columns={portfolioDataTableHeaders}
          data={portfolios}
          onSelectItem={onSelectItem}
        />
      </div>
      {portfolio.selectedPortfolio !== null && <PortfolioDetailEditCard />}
      {entity.type && entity.type.key.includes('bank') && (
        <div className="flex items-center justify-end">
          <Button
            className="mb-0"
            onClick={onOpenCard}
            disabled={!modulePermissions.hasPostPermission}
          >
            Crear portafolio
          </Button>
        </div>
      )}

      {entity.type && entity.type.key.includes('fund') && (
        <div className="flex items-center justify-end">
          <Button
            className="mb-0"
            onClick={() => setCardIsOpen(!cardIsOpen)}
            disabled={!modulePermissions.hasPostPermission}
          >
            Crear portafolio
          </Button>
        </div>
      )}

      {cardIsOpen && <PortfolioDetailCreateCard setCardIsOpen={setCardIsOpen} />}
    </Card>
  )
}
