/* eslint-disable no-unneeded-ternary */
import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  guarantee: {
    selectedValuator: null,
    valuatorList: []
  },
  contract: {
    selectedValuator: null,
    valuatorList: []
  }
}

export const revertAll = createAction('REVERT_ALL')

export const valuatorSlice = createSlice({
  name: 'valuator',
  initialState,
  reducers: {
    onSetSelectedGuaranteeValuator: (state, { payload }) => {
      state.guarantee.selectedValuator = payload
    },
    onSetSelectedContractValuator: (state, { payload }) => {
      state.contract.selectedValuator = payload
    },
    onSetGuaranteeValuatorList: (state, { payload }) => {
      state.guarantee.valuatorList = payload
    },
    onSetContractValuatorList: (state, { payload }) => {
      state.contract.valuatorList = payload
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onSetSelectedGuaranteeValuator,
  onSetSelectedContractValuator,
  onSetGuaranteeValuatorList,
  onSetContractValuatorList
} = valuatorSlice.actions
