import { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { AuthLayout } from 'layout/authentication'
import { Button, FormContainer, FormItem, Input } from 'components/ui'
import { forgotPasswordValidator } from 'helpers/validators'
import { recoveryPasswordService } from 'services/auth.service'
import { onOpenModal } from 'app/features/ui.slice'
import { useDispatch } from 'react-redux'

const initialValues = { email: '' }
export const ForgotPasswordView = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      const { data } = await recoveryPasswordService(values)
      setIsLoading(false)
      dispatch(onOpenModal({ message: data.message, type: 'SUCCESS' }))
    } catch (err) {
      setIsLoading(false)
      dispatch(onOpenModal({ message: err.response.data.message, type: 'SUCCESS' }))
    }
  }
  return (
    <AuthLayout>
      <div className="mb-8 max-w-md w-full">
        <h3 className="mb-1">MarketXM</h3>
      </div>
      <div className="max-w-md w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={forgotPasswordValidator}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting)
          }}
        >
          {({ touched, errors }) => (
            <Form>
              <FormContainer>
                <FormItem
                  label="Ingresa tu correo electrónico"
                  invalid={errors.email && touched.email}
                  errorMessage={errors.email}
                >
                  <Field
                    autoComplete="off"
                    name="email"
                    placeholder="Correo electrónico"
                    component={Input}
                  />
                </FormItem>

                <Button
                  block
                  loading={isLoading}
                  variant="solid"
                  type="submit"
                >
                  {isLoading ? 'Validando...' : 'Restablecer contraseña'}
                </Button>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  )
}
