import { useState } from 'react'
import {
  onChangeContractCurrency,
  onChangeContractCustodianA,
  onChangeContractCustodianB,
  onChangeContractDate,
  onChangeContractIndependentAmount,
  onChangeContractInitialMargin,
  onChangeContractMinTransferAmount,
  onChangeContractThreshold,
  onChangeContractValuator,
  onChangePartA,
  onChangePartB,
  onSelectEntityB,
  onSelectEntityA,
  onResetCreateContract,
  onChangeEndContractDate
} from 'app/features/contractSlice'
import { createBankContract } from 'services/bank.service'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useCreateContract = () => {
  const { contract } = useSelector((state) => state.contract)

  const { partA, partB, partContract } = contract
  const [btnLoading, setBtnLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangeEntityA = (value) => {
    dispatch(onSelectEntityA(value.key))
  }

  const handlePortfolioA = (value) => {
    dispatch(onChangePartA(value.NIT))
  }
  const handleChangeEntityB = (value) => {
    dispatch(onSelectEntityB(value.key))
  }

  const handlePortfolioB = (value) => {
    dispatch(onChangePartB(value.NIT))
  }

  const onDatePickerChange = (date) => {
    dispatch(onChangeContractDate(new Date(date)))
  }

  const onChangeEndingContractDate = (date) => {
    dispatch(onChangeEndContractDate(new Date(date)))
  }
  const handleSelectValuator = (value) => {
    dispatch(onChangeContractValuator(value.key))
  }
  const handleSelectCustodianA = (value) => {
    dispatch(onChangeContractCustodianA(value.key))
  }
  const handleSelectCustodianB = (value) => {
    dispatch(onChangeContractCustodianB(value.key))
  }
  const handleSelectCurrency = (value) => {
    dispatch(onChangeContractCurrency(value.key))
  }
  const handleChangeInitialMargin = ({ target }) => {
    if (target.value >= 0) {
      dispatch(onChangeContractInitialMargin(target.value))
    }
    if (target.value > 100) {
      dispatch(onChangeContractInitialMargin(100))
    }
  }
  const handleChangeIndependentAmount = ({ target }) => {
    if (target.value >= 0) {
      dispatch(onChangeContractIndependentAmount(target.value))
    }
    if (target.value > 100) {
      dispatch(onChangeContractIndependentAmount(100))
    }
  }
  const handleChangeThreshold = ({ target }) => {
    if (target.value >= 0) {
      dispatch(onChangeContractThreshold(target.value))
    }
  }
  const handleChangeMinTransferAmount = ({ target }) => {
    if (target.value >= 0) {
      dispatch(onChangeContractMinTransferAmount(target.value))
    }
  }

  const openModal = (message) => {
    setMessage(message)
    setModalOpen(true)
  }
  const onCloseModal = () => {
    setModalOpen(false)
    dispatch(onResetCreateContract())
    navigate('/bank/contracts')
  }

  const createContract = async (e) => {
    setBtnLoading(true)
    const { data, status } = await createBankContract(partA, partB, partContract)
    if (status === 200) {
      openModal(data.message)
    } else {
      openModal(data.message)
    }
    setBtnLoading(false)
  }

  return {
    handleChangeEntityA,
    handlePortfolioA,
    handleChangeEntityB,
    handlePortfolioB,
    onDatePickerChange,
    onChangeEndingContractDate,
    handleSelectValuator,
    handleSelectCustodianA,
    handleSelectCustodianB,
    handleSelectCurrency,
    handleChangeInitialMargin,
    handleChangeIndependentAmount,
    handleChangeThreshold,
    handleChangeMinTransferAmount,
    createContract,
    btnLoading,
    modalOpen,
    onCloseModal,
    message
  }
}
