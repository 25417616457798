import { useEffect, Fragment, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Button } from 'components/ui'
import { onOpenModal } from 'app/features/ui.slice'
import { onAuthLogin } from 'app/features/authorizationSlice'

const ENV = process.env.REACT_APP_ENV
const API_ENDPOINT = process.env.REACT_APP_API_URL
const API_DEV_ENDPOINT = process.env.REACT_APP_API_URL_DEV
const BASE_URL = ENV === 'develop' ? API_DEV_ENDPOINT : API_ENDPOINT

let currentOTPIndex = 0
export const OTPInput = ({ setStep }) => {
  const { token } = useSelector((state) => state.authorization)
  const { refreshToken } = token
  const dispatch = useDispatch()
  const inputRef = useRef()
  const [otp, setOtp] = useState(new Array(6).fill(''))
  const [activeOtpIndex, setActiveOtpIndex] = useState(0)

  const handleOnChange = ({ target }) => {
    const { value } = target
    const newOTP = [...otp]
    newOTP[currentOTPIndex] = value.substring(value.length - 1)
    if (!value) {
      setActiveOtpIndex(currentOTPIndex - 1)
    } else {
      setActiveOtpIndex(currentOTPIndex + 1)
    }
    setOtp(newOTP)
  }

  const handleOnKeyDown = ({ key }, index) => {
    currentOTPIndex = index
    if (key === 'Backspace') setActiveOtpIndex(currentOTPIndex - 1)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [activeOtpIndex])

  const handleActivateOTP = async () => {
    const headers = { Authorization: refreshToken }
    try {
      const togetherOTP = otp.join('')
      const { data } = await axios.post(String(`${BASE_URL}/api/auth/validate-otp`), { token: togetherOTP }, { headers })
      const refreshTokenExpTime = new Date(new Date().getTime() + 10 * 60 * 1000) // 10 Minutes
      Cookies.set('refreshToken', refreshToken, { expires: refreshTokenExpTime })
      dispatch(onAuthLogin(data.response.user))
    } catch (err) {
      dispatch(onOpenModal({ message: err?.response.data.message ?? 'Fallo al activar OTP', type: 'ERROR' }))
    }
  }

  return (
    <div>
      <div className="w-full my-8 flex justify-between items-center space-x-2">
        {otp.map((_, index) => (
          <Fragment key={index}>
            <input
              ref={index === activeOtpIndex ? inputRef : null}
              type="number"
              className="w-12 h-12 border rounded-md bg-transparent outline-none text-center text-lg font-semibold border-gray-800 focus:border-gray-600 transition"
              onChange={(e) => handleOnChange(e, index)}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              value={otp[index]}
            />
            {index === otp.length - 1 ? null : <span className="w-2 py-0.5 bg-gray-700" />}
          </Fragment>
        ))}
      </div>
      <Button
        block
        variant="solid"
        onClick={handleActivateOTP}
      >
        Validar
      </Button>
    </div>
  )
}

OTPInput.propTypes = {
  setStep: PropTypes.func
}
