/* eslint-disable multiline-ternary */
import { SelectSkeleton } from 'components/skeleton/SelectSkeleton'
import { Card, Input } from 'components/ui'
import { useSelector } from 'react-redux'

export const FundPartBCard = () => {
  const { contract } = useSelector((state) => state.contract)
  const { partB, loading } = contract

  return (
    <Card className="mb-8">
      <h3 className="mb-4">Parte B</h3>
      <div className="mb-8">
        <h4 className="mb-2">Entidad</h4>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partB.entity.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h4 className="mb-2">Portafolio</h4>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partB.portfolio.label}
          />
        )}
      </div>

      <div className="mb-8">
        <h4 className="mb-2">LEI</h4>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partB.portfolio.LEI}
          />
        )}
      </div>

      <div>
        <h4 className="mb-2">NIT</h4>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <Input
            placeholder="Entidad"
            type="text"
            disabled={true}
            value={partB.portfolio.NIT}
          />
        )}
      </div>
    </Card>
  )
}
