/* eslint-disable multiline-ternary */
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Input } from 'components/ui'
import Td from 'components/ui/Table/Td'
import { parseCurrency } from 'helpers/parseCurrency'
import { onChangeWithdrawnCollateral } from 'app/features/custodianSlice'

export const TableFile = ({ cell, column, row, index }) => {
  const { guarantees } = useSelector((state) => state.custodian)
  const { modulePermissions } = useSelector((state) => state.permission)
  const { loadingRequest } = guarantees
  const dispatch = useDispatch()
  const handleInputChange = (e) => {
    const availableAmount = parseCurrency(column.cells[column.cells.length - 2].value)
    if (e.target.value !== '') {
      const targetToCurrency = parseFloat(e.target.value.replace(/[^0-9.-]+/g, '')).toLocaleString('en-US')
      const valueToWithdrawn = targetToCurrency.replaceAll(',', '')
      if (valueToWithdrawn >= availableAmount) {
        const value = availableAmount.toLocaleString('en-US')
        dispatch(onChangeWithdrawnCollateral({ key: cell.row.original.key, withdrawCollateral: value, row }))
      } else {
        dispatch(onChangeWithdrawnCollateral({ key: cell.row.original.key, withdrawCollateral: targetToCurrency, row }))
      }
    } else {
      dispatch(onChangeWithdrawnCollateral({ key: cell.row.original.key, withdrawCollateral: '', row }))
    }
  }

  return (
    <Td {...cell.getCellProps()}>
      {index === column.cells.length - 1 ? (
        <Input
          placeholder="Ingrese valor a retirar"
          type="text"
          disabled={loadingRequest || !modulePermissions.hasPutPermission}
          onChange={(e) => handleInputChange(e)}
          onWheel={(e) => e.target.blur()}
          value={guarantees.selectPortfolio ? guarantees.selectPortfolio.guarantees[row].withdrawCollateral : 0}
        />
      ) : (
        cell.render('Cell')
      )}
    </Td>
  )
}

TableFile.propTypes = {
  cell: PropTypes.any.isRequired,
  column: PropTypes.any.isRequired,
  row: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
}
