import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Input, Select } from 'components/ui'
import { DropDataTable } from 'components/dataTable/DropDataTable'
import dataTableAddPortfolio from 'features/organizations/json/dataTableAddPortfolio.json'
import {
  onChangePortfolioStatus,
  onChangePortfolioLEI,
  onChangePortfolioNIT,
  onChangePortfolioName,
  onAddLocalPortfolio,
  onRemoveLocalPortfolio
} from 'app/features/organization.slice'

export const CreatePortfolioCard = () => {
  const { portfolios, input, statusTypes } = useSelector((state) => state.organization)
  const { portfolio } = input
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()

  const handleCreatePortfolio = () => {
    const obj = {
      name: portfolio.name,
      NIT: portfolio.NIT,
      LEI: portfolio.LEI,
      status: portfolio.status
    }
    const leiExist = portfolios.find((el) => el.LEI === portfolio.LEI)
    const nitExist = portfolios.find((el) => el.NIT === portfolio.NIT)
    if (!leiExist && !nitExist) {
      dispatch(onAddLocalPortfolio(obj))
    }
  }

  const onRemovePortfolioFromDataTable = (e, selectedFlatRows) => {
    const totalPortfolios = [...portfolios]
    for (let index = selectedFlatRows.length - 1; index >= 0; index--) {
      totalPortfolios.splice(selectedFlatRows[index].index, 1)
    }
    dispatch(onRemoveLocalPortfolio(totalPortfolios))
  }

  return (
    <Card className="mb-8">
      <h4 className="mb-8">Agregar portafolio</h4>
      <div className="mb-8">
        <h5 className="mb-2">Nombre de portafolio</h5>
        <Input
          placeholder="Ingrese nombre de portafolio"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangePortfolioName(target.value))}
          value={portfolio.name}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">NIT</h5>
        <Input
          placeholder="Ingrese NIT de portafolio"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangePortfolioNIT(target.value))}
          value={portfolio.NIT}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">LEI</h5>
        <Input
          placeholder="Ingrese LEI de portafolio"
          type="text"
          disabled={!modulePermissions.hasPostPermission}
          onChange={({ target }) => dispatch(onChangePortfolioLEI(target.value))}
          value={portfolio.LEI}
        />
      </div>
      <div className="mb-8">
        <h5 className="mb-2">Estado de portafolio</h5>
        <Select
          placeholder="Seleccione el estado del portafolio"
          options={statusTypes}
          isDisabled={!modulePermissions.hasPostPermission}
          onChange={(value) => dispatch(onChangePortfolioStatus(value.key))}
          value={portfolio.status}
        />
      </div>
      <Button
        variant="solid"
        className="mb-8"
        disabled={
          portfolio.LEI === null ||
          portfolio.NIT === null ||
          portfolio.name === null ||
          portfolio.status === null ||
          !modulePermissions.hasPostPermission
        }
        onClick={handleCreatePortfolio}
      >
        Añadir portafolio
      </Button>
      <DropDataTable
        columns={dataTableAddPortfolio}
        data={portfolios}
        onDropSelectedItems={onRemovePortfolioFromDataTable}
      />
    </Card>
  )
}
