import { formatToCurrency } from 'helpers/formatToCurrency'

export const guaranteeTypesAdapter = (data) => {
  return data.map((guarantee, i) => {
    const formattedAmount = formatToCurrency(
      guarantee.Amount,
      'es-CO',
      guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
    )
    const formattedCollateral = formatToCurrency(
      guarantee.Collateral,
      'es-CO',
      guarantee.Guarante_Type === 'USD' ? 'USD' : 'COP'
    )
    const formattedInitDate = `${new Date(guarantee.Init_Date).toLocaleDateString()} - ${new Date(
      guarantee.Init_Date
    ).toLocaleTimeString()}`
    const formattedUpdateDate = `${new Date(guarantee.Update_Date).toLocaleDateString()} - ${new Date(
      guarantee.Update_Date
    ).toLocaleTimeString()}`
    const formattedHaircut = `${guarantee.Haircut} %`

    return {
      amount: guarantee.Amount,
      collateral: guarantee.Collateral,
      guaranteeType: guarantee.Guarante_Type,
      guaranteeName: guarantee.Guarantee_Name,
      guaranteeTypeId: guarantee.Guarantes_Type_Id,
      haircut: guarantee.Haircut,
      ISIN: guarantee.ISIN,
      initDate: guarantee.Init_Date,
      transactionType: guarantee.Transaction_Type,
      updateDate: guarantee.Update_Date,
      valuationAmount: guarantee.Valuation_Amount,
      formattedAmount,
      formattedCollateral,
      formattedInitDate,
      formattedUpdateDate,
      formattedHaircut
    }
  })
}
