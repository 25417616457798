import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, Select } from 'components/ui'
import { WithdrawGuaranteesTable } from 'features/custodian/withdrawGuarantees/WithdrawGuaranteesTable'
import { useWithdrawnGuarantee } from 'features/custodian/withdrawGuarantees/hooks/useWithdrawnGuarantee'
import {
  onCalculateTotalCollateral,
  onFinishGuaranteesLoading,
  onGetContracts,
  onGetGuaranteeEntitiesData,
  onSelectGuaranteeEntity,
  onSelectGuaranteePortfolio,
  onStartGuaranteesLoading
} from 'app/features/custodianSlice'
import { formatGuaranteedEntities } from 'adapters/guaranteeEntities'
import { custodianGuaranteesDataService } from 'services/custodian.service'
import { PrivateLayout } from 'layout/private'

export const CollateralWithdrawnView = () => {
  const { user } = useSelector((state) => state.authorization)
  const { guarantees } = useSelector((state) => state.custodian)
  const { entities, selectEntity, selectPortfolio, loadingRequest } = guarantees
  const { modulePermissions } = useSelector((state) => state.permission)
  const dispatch = useDispatch()
  const { startWithdrawnGuarantees, dialogIsOpen, dialogMessage, onDialogOk } = useWithdrawnGuarantee()

  useEffect(() => {
    const getGuaranteesData = async () => {
      dispatch(onStartGuaranteesLoading())
      const { status, data } = await custodianGuaranteesDataService(user.organizationKey)
      if (status === 200) {
        const formattedEntities = formatGuaranteedEntities(data.guarantees, data.contracts)
        dispatch(onGetContracts(data.contracts))
        dispatch(onGetGuaranteeEntitiesData(formattedEntities))
        dispatch(onFinishGuaranteesLoading())
      }
    }
    getGuaranteesData()
  }, [])

  const handleChangeEntity = (value) => {
    dispatch(onSelectGuaranteeEntity(value.key))
  }

  const handleChangePortfolio = (value) => {
    dispatch(onSelectGuaranteePortfolio(value.NIT))
    dispatch(onCalculateTotalCollateral())
  }

  return (
    <PrivateLayout title="Retirar colaterales">
      <div className="mb-8">
        <h4 className="mb-2">Entidad</h4>
        <p className="mb-2">Seleccione la entidad correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          isDisabled={!modulePermissions.hasPutPermission}
          options={entities}
          value={selectEntity}
          onChange={handleChangeEntity}
        />
      </div>

      <div className="mb-8">
        <h4 className="mb-2">Portafolio</h4>
        <p className="mb-2">Seleccione el portafolio correspondiente:</p>
        <Select
          placeholder="Seleccione por favor"
          options={selectEntity ? selectEntity.portfolios : ''}
          isDisabled={!modulePermissions.hasPutPermission}
          value={selectPortfolio}
          onChange={handleChangePortfolio}
        />
      </div>

      <WithdrawGuaranteesTable />

      <Button
        onClick={startWithdrawnGuarantees}
        className="mb-8 mt-8"
        disabled={loadingRequest || !modulePermissions.hasPutPermission}
        variant="solid"
      >
        Retirar
      </Button>
      <Dialog
        isOpen={dialogIsOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{dialogMessage}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </PrivateLayout>
  )
}
