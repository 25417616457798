export const SelectSkeleton = () => {
  return (
    <div
      role="status"
      className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
    >
      <div className="w-full">
        <div className="h-10 bg-gray-200 rounded-xl dark:bg-gray-700 mt-2"></div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  )
}
