import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { EntityDetailCard, PortfolioDetailCard } from 'components/features/organization/detail'
import { useOrganization } from 'hooks'
import { UserDetailCard } from 'features/organizations/components'

export const OrganizationDetailView = () => {
  const { key } = useParams()
  const { input } = useSelector((state) => state.organization)
  const { entity } = input
  const { getUserOrganizationDetail } = useOrganization()

  useEffect(() => {
    getUserOrganizationDetail(key)
  }, [key])

  return (
    <PrivateLayout title="Detalle de organización">
      <EntityDetailCard />
      <UserDetailCard />
      {entity?.type?.key === 'bank' && <PortfolioDetailCard />}
      {entity?.type?.key === 'fund' && <PortfolioDetailCard />}
    </PrivateLayout>
  )
}
