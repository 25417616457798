/* eslint-disable camelcase */
import { authBaseURL } from 'interceptors/auth.interceptor'

export const authLoginService = async (email, password, provider_id) => {
  return await authBaseURL.post('/api/auth/login', { email, password, provider_id })
}
export const authValidate = async () => {
  return await authBaseURL.get('/api/auth/validate')
}
export const authLogoutService = async (uuid) => {
  return await authBaseURL.post('/api/auth/logout', { uuid })
}
export const recoveryPasswordService = async (values) => {
  return await authBaseURL.post('/api/user/recovery', { ...values })
}
export const resetPasswordService = async (user) => {
  return await authBaseURL.post('/api/user/change-password', user)
}
