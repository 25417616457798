import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, Dialog, Input, Select } from 'components/ui'
import { useAssignCollateral } from './hook/useAssignCollateral'

export const AddIsinCard = () => {
  const { modulePermissions } = useSelector((state) => state.permission)
  const [isin, setIsin] = useState('')
  const [currency, setCurrency] = useState(null)

  const handleChangeISIN = ({ target }) => {
    setIsin(target.value.trim().toUpperCase())
  }

  const { addNewISIN, assignLoading, onDialogOk, dialogMessage, dialogIsOpen } = useAssignCollateral()

  return (
    <>
      <Card>
        <div className="mb-4">
          <h5 className="mb-2">ISIN</h5>
          <Input
            placeholder="Ingrese ISIN..."
            type="text"
            disabled={assignLoading || !modulePermissions.hasPostPermission}
            onChange={handleChangeISIN}
            value={isin}
          />
        </div>

        <div className="mb-4">
          <h5 className="mb-2">¿Es moneda?</h5>
          <Select
            placeholder="Seleccione un valor"
            options={[
              { value: 0, key: 'yes', label: 'Si', divisible: true },
              { value: 1, key: 'no', label: 'No', divisible: false }
            ]}
            isDisabled={assignLoading || !modulePermissions.hasPostPermission}
            onChange={(value) => setCurrency(value)}
            value={currency}
          />
        </div>
        <Button
          variant="solid"
          onClick={() => addNewISIN({ isin, currency })}
          disabled={isin.length === 0 || !currency || assignLoading || !modulePermissions.hasPostPermission}
          loading={assignLoading}
        >
          Añadir ISIN
        </Button>
      </Card>

      <Dialog
        isOpen={dialogIsOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{dialogMessage}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onDialogOk}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </>
  )
}
