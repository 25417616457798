import { useDispatch, useSelector } from 'react-redux'
import {
  onChangeEndValuationDate,
  onChangeInitValuationDate,
  onFinishValuationsLoading,
  onGetValuations,
  onStartValuationsLoading
} from 'app/features/ui.slice'
import { getFullyCollateralValuationsHistoryByPortfolioNIT } from 'services/collateral.service'
import { guaranteeTypesAdapter } from 'adapters'

export const useCalculateValuations = () => {
  const { dashboard } = useSelector((state) => state.ui)
  const { valuations, selectedPortfolio } = dashboard
  const dispatch = useDispatch()

  const onChangeInitialValuationsDate = (date) => {
    const currentDate = new Date()
    if (date <= currentDate) {
      if (date <= new Date(valuations.endDate)) {
        dispatch(onChangeInitValuationDate(new Date(date)))
      }
    } else {
      dispatch(onChangeInitValuationDate(new Date(currentDate)))
    }
  }

  const onChangeFinishValuationsDate = (date) => {
    const currentDate = new Date()
    if (date <= currentDate) {
      if (date >= new Date(valuations.initDate)) {
        dispatch(onChangeEndValuationDate(new Date(date)))
      }
    } else {
      dispatch(onChangeEndValuationDate(new Date(currentDate)))
    }
  }

  const handleSendChartRequest = async () => {
    dispatch(onStartValuationsLoading())
    const { data } = await getFullyCollateralValuationsHistoryByPortfolioNIT(
      selectedPortfolio.NIT,
      valuations.initDate,
      valuations.endDate
    )
    const formattedReceivedCollaterals = guaranteeTypesAdapter(data.response.filteredReceivedCollateral)
    const formattedDeliveredCollaterals = guaranteeTypesAdapter(data.response.filteredDeliveredCollateral)
    dispatch(
      onGetValuations({
        receivedCollateral: formattedReceivedCollaterals,
        deliveredCollateral: formattedDeliveredCollaterals,
        totalReceivedCollateral: data.response.totalCollateralsReceived,
        totalDeliveredCollateral: data.response.totalCollateralsDelivered,
        totalCollateralsAmount: data.response.totalCollateralsToMarginCall
      })
    )
    dispatch(onFinishValuationsLoading())
  }

  return { onChangeInitialValuationsDate, onChangeFinishValuationsDate, handleSendChartRequest }
}
