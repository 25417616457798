import { configureStore } from '@reduxjs/toolkit'

import { commonSlice } from './features/commonSlice'
import { layoutSlice } from './features/layoutSlice'
import { themeSlice } from './features/themeSlice'
import { custodianSlice } from './features/custodianSlice'
import { fundSlice } from './features/fundSlice'
import { contractSlice } from './features/contractSlice'
import { dashboardSlice } from './features/dashboard.slice'
import { collateralSlice } from './features/collateral.slice'
import { organizationSlice } from './features/organization.slice'
import { uiSlice } from './features/ui.slice'
import { valuatorSlice } from './features/valuator.slice'
import { logsSlice } from './features/logs.slice'
import { permissionSlice } from './features/permissions-slice'
import { organizationUsersSlice } from './features/organizationUsers.slice'

import { authorizationSlice } from './features/authorizationSlice'
import { moduleSlice } from './features/module-slice'

export const store = configureStore({
  reducer: {
    authorization: authorizationSlice.reducer,
    module: moduleSlice.reducer,
    common: commonSlice.reducer,
    layout: layoutSlice.reducer,
    theme: themeSlice.reducer,
    fund: fundSlice.reducer,
    custodian: custodianSlice.reducer,
    dashboard: dashboardSlice.reducer,
    contract: contractSlice.reducer,
    collateral: collateralSlice.reducer,
    organization: organizationSlice.reducer,
    ui: uiSlice.reducer,
    valuator: valuatorSlice.reducer,
    logs: logsSlice.reducer,
    permission: permissionSlice.reducer,
    organizationUsers: organizationUsersSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
