import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'components/ui'
import { SelectDataTable } from 'components/dataTable'
import organizationUsersDataTable from 'data/organization/organizationUsersDataTable.json'
import { onHandleSelectedUser } from 'app/features/organizationUsers.slice'
import { SelectedUserContainer } from 'components/features/organizationUsers'

export const SelectedOrganizationContainer = () => {
  const { organization, users } = useSelector((state) => state.organizationUsers)
  const { selectedOrganization } = organization
  const { data, selected } = users
  const dispatch = useDispatch()

  return (
    <Card className="mb-16">
      <h5 className="mb-2">Organización: {selectedOrganization?.name}</h5>
      <SelectDataTable
        columns={organizationUsersDataTable}
        data={data}
        onSelectItem={(e, row) => dispatch(onHandleSelectedUser(data[row.index].id))}
      />
      {selected?.user && <SelectedUserContainer />}
    </Card>
  )
}
