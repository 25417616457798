import { PrivateLayout } from 'layout/private'
import { PortfolioRelevantInformation, VariationMargin } from 'features/dashboard/components'
export const DashboardView = () => {
  return (
    <PrivateLayout title="Dashboard">
      <PortfolioRelevantInformation />
      <VariationMargin />
    </PrivateLayout>
  )
}
