import { backendBaseURL } from '../interceptors/backend.interceptor'

export const custodianDashboardDataService = async () => {
  return await backendBaseURL.get('/api/collaterals/custodian/dashboard')
}
export const custodianGuaranteesDataService = async (organizationId) => {
  return await backendBaseURL.get('/api/collaterals/custodian/withdrawn-data')
}
export const getAssignedContractsService = async (organizationKey) => {
  return await backendBaseURL.get('/api/contracts/custodian-assigned')
}
export const custodianAssignGuarantees = async (data, nit) => {
  return await backendBaseURL.post('/api/collaterals/assign', { data, nit })
}
export const custodianWithdrawnGuarantees = async (data) => {
  return await backendBaseURL.put('/api/collaterals/withdrawn', { data })
}

// ---- Deprecated ----
export const custodianAssociatedOrganizations = async () => {
  return await backendBaseURL.get('/api/v2/custodian/associated-organizations')
}

export const custodianAssociatedOrganizationPortfolios = async (organizationKey) => {
  return await backendBaseURL.get(`/api/contracts/custodian/portfolio/${organizationKey}`)
}

export const getPortfolioAssignedCollaterals = async (nit) => {
  return await backendBaseURL.get(`/api/v2/custodian/collaterals/portfolio/${nit}`)
}
