import { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { SideNavigation } from 'layout/private'
import Header from 'components/template/Header'
import SidePanel from 'components/template/SidePanel'
import UserDropdown from 'components/template/UserDropdown'
import { Button, Dialog } from 'components/ui'
import { onCloseModal } from 'app/features/ui.slice'
import { onCheckModulePermissions } from 'app/features/permissions-slice'

const HeaderActionsEnd = () => {
  return (
    <>
      <SidePanel />
      <UserDropdown hoverable={false} />
    </>
  )
}

const PrivateLayout = ({ children, title }) => {
  const { modal, modules } = useSelector((state) => state.ui)
  const { data } = modules
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    const checkPermission = (permissions) => {
      const getPermission = permissions.find((permission) => permission.method === 'GET')
      return !!getPermission
    }
    const checkPathPermissions = (path, permissions) => {
      if (pathname.includes(path)) {
        dispatch(onCheckModulePermissions(permissions))
        if (!checkPermission(permissions)) {
          navigate('/')
        }
      }
    }
    if (pathname !== '/') {
      let pathExists = false
      data.forEach((element) => {
        if (pathname.includes(element.parent.path) || element.child.some((child) => pathname.includes(child.path))) {
          pathExists = true
        }
      })
      if (!pathExists) {
        navigate('/')
      } else {
        data.forEach((element) => {
          if (element.parent.path) {
            checkPathPermissions(element.parent.path, element.parent.permissions)
          }
          element.child.forEach((child) => {
            checkPathPermissions(child.path, child.permissions)
          })
        })
      }
    } else {
      if (data.length > 0) {
        const firstParentPath = data[0].parent.path
        const firstChildPath = data[0].child[0]?.path
        navigate(firstParentPath !== null ? firstParentPath : firstChildPath)
      }
    }
  }, [pathname])

  return (
    <div className="app-layout-modern flex flex-auto flex-col">
      <div className="flex flex-auto min-w-0">
        <SideNavigation />
        <div className="flex flex-col flex-auto min-h-screen min-w-0 relative w-full bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700">
          <Header
            className="border-b border-gray-200 dark:border-gray-700"
            headerEnd={<HeaderActionsEnd />}
          />
          <div className="px-8 min-h-screen">
            <h2 className="mb-8 mt-8">{title}</h2>
            <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
            <Dialog
              isOpen={modal.open}
              closable={false}
            >
              <h5 className="mb-4">{modal.type === 'ERROR' ? 'Error' : 'Éxito'}</h5>
              <p>{modal.message}</p>
              <div className="text-right mt-6">
                <Button
                  variant="solid"
                  onClick={() => dispatch(onCloseModal())}
                >
                  Aceptar
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateLayout

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}
