import { backendBaseURL } from 'interceptors/backend.interceptor'

export const getRolesService = async () => {
  return await backendBaseURL.get('/api/organization/roles')
}
export const getPermissionsService = async () => {
  return await backendBaseURL.get('/api/organization/permissions')
}
export const getTypesService = async () => {
  return await backendBaseURL.get('/api/organization/types')
}
export const getAssignedRolePermissionsData = async (key) => {
  return await backendBaseURL.get(`/api/organization/data-permissions/${key}`)
}
export const getAssignedOrganizationRolePermissionsData = async (key, roleId) => {
  const params = { key, roleId }
  return await backendBaseURL.get('/api/organization/assigned/data-permissions', { params })
}

export const storeOrganizationRolePermissions = async (data, roleId, organizationId) => {
  return await backendBaseURL.post('/api/organization/assign-permissions', { data, roleId, organizationId })
}

export const getAllOrganizations = async () => {
  return await backendBaseURL.get('/api/organization/all')
}
export const getOrganizationUsers = async (key) => {
  return await backendBaseURL.get(`/api/organization/users/${key}`)
}

export const updateUserService = async (dataToSave) => {
  return await backendBaseURL.put('/api/organization/update/user', dataToSave)
}

// Modules
export const getCreateModuleData = async () => {
  return await backendBaseURL.get('/api/organization/data/modules')
}
export const createModuleService = async (moduleData) => {
  return await backendBaseURL.post('/api/organization/create/module', moduleData)
}
