import { forwardRef } from 'react'
import classNames from 'classnames'
import { useTabs } from './context'

// eslint-disable-next-line react/display-name
const TabList = forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { className, children, ...rest } = props

  const { variant } = useTabs()

  const tabListClass = classNames('tab-list', `tab-list-${variant}`, className)

  return (
    <div
      role="tablist"
      className={tabListClass}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  )
})

export default TabList
