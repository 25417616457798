import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrivateLayout } from 'layout/private'
import { Button, Dialog } from 'components/ui'
import { PartACard, PartBCard, PartContractCard } from 'features/bank/contracts'
import { onFinishContractLoading, onGetRelatedData, onSelectEntityA, onStartContractLoading } from 'app/features/contractSlice'
import { useCreateContract } from 'hooks/useCreateContract'
import { portfolioAdapter, currencyAdapter, valuatorAdapter, entityAdapter, custodianAdapter } from 'adapters/core'
import { getBankDataLinked } from 'services/bank.service'

export const AddContractView = () => {
  const { user } = useSelector((state) => state.authorization)
  const { organizationId, organization, organizationKey, organizationType } = user
  const { modulePermissions } = useSelector((state) => state.permission)

  const { contract } = useSelector((state) => state.contract)
  const { partA, partB, partContract } = contract

  const dispatch = useDispatch()

  const { createContract, btnLoading, modalOpen, onCloseModal, message } = useCreateContract()

  useEffect(() => {
    const getDataLinked = async () => {
      dispatch(onStartContractLoading())
      const { data, status } = await getBankDataLinked(organizationId)
      if (status === 200) {
        const portfolios = portfolioAdapter(data.response.portfolios)
        const currencies = currencyAdapter(data.response.currencies)
        const valuators = valuatorAdapter(data.response.valuators)
        const custodians = custodianAdapter(data.response.custodians)
        const funds = entityAdapter(data.response.funds)
        dispatch(onGetRelatedData({ portfolios, currencies, valuators, custodians, funds }))
        dispatch(
          onSelectEntityA({
            value: organizationId,
            label: organization,
            key: organizationKey,
            type: organizationType
          })
        )
        dispatch(onFinishContractLoading())
      }
    }
    getDataLinked()
  }, [])

  return (
    <PrivateLayout title="Añadir nuevo contrato">
      <PartACard />
      <PartBCard />
      <PartContractCard />
      <Button
        className="mb-8"
        variant="solid"
        disabled={
          !(
            partA.entity &&
            partA.portfolio &&
            partB.entity &&
            partB.portfolio &&
            partContract.date &&
            partContract.valuator &&
            partContract.custodianA &&
            partContract.custodianB &&
            partContract.currency &&
            partContract.initialMargin &&
            partContract.independentAmount &&
            partContract.threshold &&
            partContract.minTransferAmount
          ) || !modulePermissions.hasPostPermission
        }
        loading={btnLoading}
        onClick={createContract}
      >
        Agregar
      </Button>

      <Dialog
        isOpen={modalOpen}
        closable={false}
      >
        <h5 className="mb-4">Resultado</h5>
        <p>{message}</p>
        <div className="text-right mt-6">
          <Button
            variant="solid"
            onClick={onCloseModal}
          >
            Aceptar
          </Button>
        </div>
      </Dialog>
    </PrivateLayout>
  )
}
