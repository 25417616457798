import { useState } from 'react'
import { PrivateLayout } from 'layout/private'
import { Tabs } from 'components/ui'
import { TabsList } from 'components/v2/TabContainer'
import { ContractValuationsTable } from 'features/valuator/dashboard/ContractValuationsTable'
import { GuaranteeValuationsTable } from 'features/valuator/dashboard/GuaranteeValuationsTable'

const ValuatorDashboard = () => {
  const [currentTab, setCurrentTab] = useState('contract_valuation')

  const onChangeTab = (tabValue) => {
    setCurrentTab(tabValue)
  }

  const arr = [
    { value: 'contract_valuation', label: 'Valoraciones de derivados' },
    { value: 'guarantee_valuation', label: 'Valoraciones de colaterales' }
  ]
  return (
    <PrivateLayout title="Dashboard">
      <Tabs
        defaultValue="contract_valuation"
        value={currentTab}
        onChange={onChangeTab}
      >
        <TabsList tabs={arr} />
        <ContractValuationsTable currentTab={currentTab} />
        <GuaranteeValuationsTable currentTab={currentTab} />
      </Tabs>
    </PrivateLayout>
  )
}

export default ValuatorDashboard
