/* eslint-disable no-unneeded-ternary */
import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  contract: {
    loading: true,
    partA: { entity: null, portfolio: null },
    partB: { entity: null, portfolio: null },
    partContract: {
      contractId: null,
      date: new Date(),
      valuator: null,
      custodianA: null,
      custodianB: null,
      currency: null,
      initialMargin: null,
      independentAmount: null,
      threshold: null,
      minTransferAmount: null,
      approved: false,
      state: ''
    }
  }
}

export const revertAll = createAction('REVERT_ALL')

export const fundSlice = createSlice({
  name: 'fund',
  initialState,
  reducers: {
    onStartDashboardFundLoading: (state) => {
      state.dashboard.loading = true
    },
    onFinishDashboardFundLoading: (state) => {
      state.dashboard.loading = false
    },
    onGetDashboardFundData: (state, { payload }) => {
      state.dashboard.data = payload.data
      state.dashboard.totalGuaranties = payload.totalGuaranties
    },
    onClearDashboardFundData: (state) => {
      state.dashboard.data = []
      state.dashboard.totalGuaranties = 0
      state.dashboard.loading = true
    },

    onStartFundContractLoading: (state) => {
      state.contract.loading = true
    },
    onFinishFundContractLoading: (state) => {
      state.contract.loading = false
    },
    onGetFundContractData: (state, { payload }) => {
      state.contract.partA.entity = payload.entityA
      state.contract.partA.portfolio = payload.portfolioA
      state.contract.partB.entity = payload.entityB
      state.contract.partB.portfolio = payload.portfolioB
      state.contract.partContract.date = payload.date
      state.contract.partContract.contractId = payload.contractId
      state.contract.partContract.valuator = payload.valuator
      state.contract.partContract.custodianA = payload.custodianA
      state.contract.partContract.custodianB = payload.custodianB
      state.contract.partContract.currency = payload.currency
      state.contract.partContract.initialMargin = payload.initialMargin
      state.contract.partContract.independentAmount = payload.independentAmount
      state.contract.partContract.threshold = payload.threshold
      state.contract.partContract.minTransferAmount = payload.minTransferAmount
      state.contract.partContract.approved = payload.approved === 'false' ? false : true
    }
  },
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState)
})

export const {
  onStartDashboardFundLoading,
  onFinishDashboardFundLoading,
  onGetDashboardFundData,
  onClearDashboardFundData,
  onGetFundContractData,
  onStartFundContractLoading,
  onFinishFundContractLoading
} = fundSlice.actions
