import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilteringDetailTable from 'marketxm/views/Components/FilteringDetailTable'
import logsTableHeaders from 'features/colibri/dashboard/json/logsTableHeaders.json'
import {
  onGetLogsData,
  onAddLimit,
  onSetLoading,
  onSetLoading2,
  onSetFilter
} from 'app/features/logs.slice'
import { getLogsService } from 'services/logs.service'
import { PrivateLayout } from 'layout/private'

const ColibriLogView = () => {
  const { history, limit, loading, loading2, filter } = useSelector((state) => state.logs)

  const dispatch = useDispatch()

  useEffect(() => {
    const getLogsData = async () => {
      const { status, data } = await getLogsService(limit)
      if (status === 200) {
        dispatch(onSetLoading(false))
        dispatch(onGetLogsData(data.data))
      }
    }
    getLogsData()
  }, [])

  const onAddData = async () => {
    dispatch(onSetLoading2(true))
    const { status, data } = await getLogsService(limit + 30, filter)
    if (status === 200) {
      dispatch(onGetLogsData(data.data))
      dispatch(onAddLimit())
      dispatch(onSetLoading2(false))
    }
  }

  const onFilterData = async (filter) => {
    dispatch(onSetLoading2(true))
    dispatch(onSetFilter(filter))
    const { status, data } = await getLogsService(limit, filter)
    if (status === 200) {
      dispatch(onGetLogsData(data.data))
      dispatch(onSetLoading2(false))
    }
  }

  return (
    <PrivateLayout title="Historial de logs">
      <FilteringDetailTable
        columns={logsTableHeaders}
        data={history}
        loading={loading}
        loading2={loading2}
        onClick={onAddData}
        onFilter={onFilterData}
      />
    </PrivateLayout>
  )
}

export default ColibriLogView
